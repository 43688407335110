// Contact reducer actions
export function Contact(
  state = {
    isFetching: false,
  },
  action
) {
  const { id, payload, type } = action;
  // Action types
  switch (type) {
    case "REQUEST_CONTACT_DETAIL":
      return Object.assign({}, state, {
        isFetching: true,
      });
    case "RECEIVE_CONTACT_DETAIL": {
      return {
        ...state,
        [id]: Object.assign({}, state[id], {
          ...state,
          isFetching: false,
          data: [action.payload],
          currentPage: payload.currentPage,
          totalCount: payload.totalCount,
          pageSize: payload.pageSize,
          [payload.currentPage]: payload.data,
          sortList: Object.assign({}, state[id].sortList, {
            [payload.currentPage]: payload.sort,
          }),
          sortDirList: Object.assign({}, state[id].sortDirList, {
            [payload.currentPage]: payload.sortDir,
          }),
          sort: payload.sort,
          sortDir: payload.sortDir,
        }),
      };
    }
    case "FETCH_CONTACT_DETAIL_FAIL": {
      return { ...state, isFetching: false };
    }
    default:
      return state;
  }
}
