import React from "react";
import LabelComponent from "../../../components/Label";
import Text from "../../../components/Text";
import Email from "../../../components/Email";
import Select from "../../../components/Select";
import Zipcode from "../../../components/Zipcode";
import Phone from "../../../components/Phone";
import { PhoneType } from "../../../constants/PhoneType";
import TextArea from "../../../components/TextArea";
import MultiSelect from "../../../components/MultiSelectCreatable";

const ContactInformation = (props) => {
  const {
    disabled,
    onChange,
    countryList,
    stateList,
    handleCountryChange,
    handlePhone1Type,
    handlePhone2Type,
    selectedStateValue,
    handleStateChange,
    handleZipCodevalue,
    selectedCountryValue,
    phone1Type,
    phone2Type,
    tagList,
    tagOptions,
  } = props;

  // input label
  const FieldLabelStyle = {
    fontWeight: 700,
  };

  const handlestateschange = (data) => {
    handleStateChange(data);
  };
  return (
    <>
      <div className="p-3 contactInformation-section">
        <LabelComponent className="contact-information">
          {" "}
          Contact Information{" "}
        </LabelComponent>
        <div className="mt-3">
          {/* First Name */}
          <Text
            name="firstName"
            label="First Name"
            onChange={onChange}
            labelStyle={FieldLabelStyle}
            disabled={disabled}
            required
          />

          {/* Middle Name */}
          <Text
            name="middleName"
            label="Middle Name"
            onChange={onChange}
            labelStyle={FieldLabelStyle}
            disabled={disabled}
          />

          {/* Last Name */}
          <Text
            name="lastName"
            label="Last Name"
            onChange={onChange}
            labelStyle={FieldLabelStyle}
            disabled={disabled}
            required
          />
          <Text
            label="Title"
            name="title"
            labelStyle={FieldLabelStyle}
            onChange={onChange}
            disabled={disabled}
          />

          <Email
            name="email"
            label="Email"
            placeholder="Enter Email"
            required
            emailStyle={FieldLabelStyle}
            onChange={onChange}
            disabled={disabled}
          />

          {/* Contact Tags */}

          <MultiSelect
            label="Tags"
            name="tags"
            placeholder="Tags"
            // closeMenuOnSelect={false}
            options={tagOptions}
            labelStyle={FieldLabelStyle}
            onChange={onChange}
            disabled={disabled}
          />
          <LabelComponent>Phone Number 1</LabelComponent>
          <div className="row">
            <Select
              name="phone1Type"
              className="col-6  text-nowrap"
              options={PhoneType}
              labelStyle={FieldLabelStyle}
              onInputChange={handlePhone1Type}
              isSearchable={true}
              placeholder="Select Type"
              disabled={disabled}
              value={phone1Type?.label}
            />

            <Phone
              placeholder="Phone Number 1"
              name="phone1"
              className="col-6"
              onKeyDown={onChange}
              disabled={disabled}
              phoneStyle={FieldLabelStyle}
            />
          </div>
          <LabelComponent>Phone Number 2</LabelComponent>
          <div className="row">
            <Select
              name="phone2Type"
              className="col-6  text-nowrap"
              options={PhoneType}
              labelStyle={FieldLabelStyle}
              onInputChange={handlePhone2Type}
              isSearchable={true}
              placeholder="Select Type"
              disabled={disabled}
              value={phone2Type?.label}
            />

            <Phone
              placeholder="Phone Number 2"
              name="phone2"
              className="col-6"
              onKeyDown={onChange}
              disabled={disabled}
              phoneStyle={FieldLabelStyle}
            />
          </div>
          <Text
            label="Company Name"
            name="companyName"
            labelStyle={FieldLabelStyle}
            onChange={onChange}
            disabled={disabled}
          />

          <Text
            label="Address 1"
            name="address1"
            onChange={onChange}
            labelStyle={FieldLabelStyle}
            disabled={disabled}
          />

          <Text
            label="Address 2"
            name="address2"
            onChange={onChange}
            labelStyle={FieldLabelStyle}
            disabled={disabled}
          />

          <Text
            label="City"
            name="city"
            onChange={onChange}
            labelStyle={FieldLabelStyle}
            disabled={disabled}
          />

          <Zipcode
            label="Zip code"
            name="postalCode"
            labelStyle={FieldLabelStyle}
            disabled={disabled}
            onChange={handleZipCodevalue}
          />

          <Select
            name="state"
            label="State"
            options={stateList}
            labelStyle={FieldLabelStyle}
            onInputChange={(e) => handlestateschange(e)}
            defaultValue={
              disabled ? selectedStateValue.label : selectedStateValue
            }
            isSearchable={true}
            placeholder="State"
            disabled={disabled}
            value={selectedStateValue ? selectedStateValue?.label : ""}
          />
          <Select
            name="country"
            label="Country"
            onInputChange={handleCountryChange}
            options={countryList}
            labelStyle={FieldLabelStyle}
            isSearchable={true}
            placeholder="Country"
            disabled={disabled}
            value={selectedCountryValue?.label}
          />

          <TextArea
            label="Notes"
            name="notes"
            onChange={onChange}
            disabled={disabled}
          />
        </div>
      </div>
    </>
  );
};

export default ContactInformation;
