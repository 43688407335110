import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, FormFeedback } from "reactstrap";
import Label from "../Label";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Styles
import "./Style.scss";

// proptype list
const propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onChange: PropTypes.func,
  width: PropTypes.string,
  selected: PropTypes.string,
  minDate: PropTypes.object,
  format: PropTypes.string,
};

// default props
const defaultProps = {
  dateFormat: "dd MMM, yyyy",
  wrapperClassName: 100,
};

class DateInput extends React.Component {
  // Validate in Date field
  validate(value) {
    // props defining
    const { label, placeholder, required, selected } = this.props;

    // defining error message
    let errorMessage;
    // defining input label
    let inputLabel = label || placeholder;

    // defining the error message when the field is null
    if (!selected && required && !value) {
      errorMessage = inputLabel ? `${inputLabel} is required` : "Required";
    } else {
      errorMessage = null;
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, setFieldValue } }) {

    const {
      name,
      id,
      label,
      placeholder,
      format,
      required,
      onChange,
      selected,
      minDate,
      width,
      disabled,
      className,
    } = this.props;

    // defining the error message
    let errorMessage = touched[name] && errors[name] ? errors[name] : null;

    // error message validation
    errorMessage =
      typeof errorMessage != "object" && !selected ? errorMessage : null;

    // defining the input Id
    const inputId = id || name;
    let fieldValue = moment(field.value).format("MM-DD-YYYY");

    return (
      <FormGroup key={id} style={{ marginBottom: 22, position: "relative" }}>
        {label && (
          <Label id={inputId} required={required}>
            {label}
          </Label>
        )}

        {disabled ? (
          <div className={`outline-field-style px-1 py-2 ${!fieldValue && "text-secondary"}`}>
            {fieldValue ? fieldValue : <span className="invisible">{(placeholder || `Enter ${label}`)}</span>}
          </div> 
        ) : (
          <DatePicker
            name={name}
            id={inputId}
            className={` ${className? className: "form-control"} ${errorMessage ? "is-invalid" : ""}`}
            onChange={
              onChange
                ? onChange
                : value => {
                  setFieldValue(name, moment(value).format("MM-DD-YYYY"));
                }
            }
            dateFormat={format}
            minDate={minDate}
            selected={
              selected ? selected : field.value ? new Date(field.value) : null
            }
            placeholderText={placeholder || label}
            wrapperClassName={`w-${width}`}
            showMonthDropdown
            disabled={disabled}
          />
        )}
        {/* error message display */}
        {errorMessage && (
          <FormFeedback
            style={{ position: "absolute", marginTop: 1, display: "block" }}
          >
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    const { name, id, readOnly, className, date } = this.props;

    return (
      <>
        {readOnly ? (
          <>
            <Label className={className}>{date}</Label>
          </>
        ) : (
          <>
            <Field
              id={id || name}
              validate={this.validate.bind(this)}
              name={name}
              render={this.renderInput.bind(this)}
            />
          </>
        )}
      </>
    );
  }
}

DateInput.propTypes = propTypes;
DateInput.defaultProps = defaultProps;

export default DateInput;