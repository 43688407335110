import React from "react";
import NumberFormat from "react-number-format";


//Styles
import "./Style.scss";

const NumberField = (props) => {
  // Text field props
  const { placeHolder, className, value, isDisabled, defaultValue, onChange, maxLength } = props;
  return (
    <NumberFormat
      defaultValue={defaultValue}
      value={value}
      placeholder={placeHolder}
      className={`${className} number-field`}
      onChange={onChange}
      maxLength={maxLength} 
      allowNegative={false}
      disabled={isDisabled}
    />
  );
};

export default NumberField;
