/* eslint-disable no-unneeded-ternary */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

//Assets
import leftArrowIcon from "../../assets/icons/leftArrow.png";

//Components
import Form from "../../components/Form";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import Phone from "../../components/Phone";
import Email from "../../components/Email";
import LabelComponent from "../../components/Label";
import Breadcrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";

// Constants
import * as Constants from "../../constants";

//lib
import Spinner from "../../components/Spinner";
import String from "../../lib/String";
import PhoneNumber from "../../lib/PhoneNumber";
//API
import ContactAPI from "../../api/ContactAPI";
import countryApi from "../../api/Country";
import Zipcode from "../../components/Zipcode";
import ArrayList from "../../lib/ArrayList";
import MultiSelect from "../../components/MultiSelectCreatable";
import tag from "../../api/TagAPI";

const AddContact = () => {
  // dispatch action and trigger state changes in the redux store
  const navigate = useNavigate();

  // state
  const [phone1Type, setPhone1Type] = useState("");
  const [phone2Type, setPhone2Type] = useState("");
  const [countryList, setCountryList] = useState("");
  const [stateList, setStateList] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("USA");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedState, setSelectedState] = useState(null);
  const [isDisable, setIsDisable] = useState(false);
  const [tagList, setTagList] = useState([]);
  const isNewCommunication = window.location.pathname.split("/")[2] == "add";

  // create contact details with the data submitted by the Add model form.
  const createContactData = (data) => {
    ContactAPI.createContact(toArray(data), (success) => {
      if (success) {
        navigate("/contacts");
      }
    });
  };

  useEffect(() => {
    getCountryList();
    getTag();
  }, []);

  useEffect(() => {
    getStateList();
  }, [countryList]);

  useEffect(() => {
    getStateList();
  }, [selectedCountry]);

  const getTag = () => {
    tag.getContact((tags) => {
      if (tags && tags.length > 0) {
        setTagList(tags);
      }
    });
  };

  //getcountry
  const getCountryList = () => {
    try {
      let countryList = new Array();
      setIsLoading(false);
      countryApi.getCountryList((response) => {
        setIsLoading(false);
        if (response) {
          for (const property in response) {
            countryList.push({ label: response[property], value: property });
          }
        }
        setCountryList(countryList);
      });
    } catch (err) {
      console.log(err);
    }
  };

  //getState
  const getStateList = () => {
    try {
      let stateList = new Array();
      countryApi.getStateList(selectedCountry, (response) => {
        if (response) {
          for (const property in response) {
            stateList.push({
              label: response[property],
              value: response[property],
            });
          }
        } else {
          stateList.push({
            label: "",
            value: "",
          });
        }
        setStateList(stateList);
      });
    } catch (err) {
      console.log(err);
    }
  };
  // to validate submitted values
  const toArray = (value) => {
    const data = {};
    let tags = [];

    data.title = String.Get(value.title);
    data.firstName = String.Get(value.firstName);
    data.lastName = String.Get(value.lastName);
    data.middleName = String.Get(value.middleName);
    data.email = String.Get(value.email);
    data.address1 = String.Get(value.address1);
    data.address2 = String.Get(value.address2);
    data.city = String.Get(value.city);
    //forming tags array
    if (value.tags && value.tags.length > 0) {
      value.tags.forEach((data) => {
        tags.push(data.value);
      });
    }

    if (isNewCommunication) {
      data.tags = tags;
    }

    data.tags = tags;
    if (selectedState) {
      data.state = String.Get(selectedState);
    } else {
      data.state = "";
    }

    if (value.country.value) {
      data.country = value.country.value ? value.country.value : "";
    } else if (selectedCountry) {
      data.country = selectedCountry;
    } else {
      data.country = "USA";
    }

    data.postalCode = String.Get(value.postalCode);

    data.phone1 = PhoneNumber.Get(value.phone1);
    if (phone1Type) {
      data.phone1Type = phone1Type.value;
    }
    data.phone2 = PhoneNumber.Get(value.phone2);
    if (phone2Type) {
      data.phone2Type = phone2Type.value;
    }
    data.notes = String.Get(value.notes);
    data.companyName = String.Get(value.companyName);
    return data;
  };

  const handleCountryChange = (data) => {
    if (data && data.value) {
      setSelectedCountry(data.value.value);
    }
    setSelectedState(null);
  };

  // Handle state change
  const handleStateChange = (data) => {
    if (data && data.value) {
      setSelectedState(data.value);
    }
  };

  // Breadcrumb list
  const breadcrumbList = [
    { label: "Contact", link: "/contacts" },
    {
      label: "New Contact",
      link: "",
    },
  ];

  // Render state/country fields
  const renderCountryStateField = () => (
    <>
      <Form
        enableReinitialize
        initialValues={{
          state:
            ArrayList.isNotEmpty(stateList) &&
            stateList.find((state) => state.value == selectedState?.value),
          country:
            countryList && countryList
              ? countryList.find(
                  (countryList) => countryList.value == selectedCountry
                )
              : "",
        }}>
        {/* State field */}
        <Select
          id="state"
          name="state"
          label="State/Province"
          options={stateList}
          handleChange={handleStateChange}
        />

        {/* Country field */}
        <Select
          id="country"
          name="country"
          label="Country/Region"
          onInputChange={handleCountryChange}
          options={countryList}
        />
      </Form>
    </>
  );

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={breadcrumbList} />

      {isLoading ? (
        <Spinner />
      ) : (
        <Form
          initialValues={{
            title: "",
            firstName: "",
            middleName: "",
            lastName: "",
            email: "",
            address1: "",
            city: "",
            state: selectedState?.value,
            country: selectedCountry || "",
            postalCode: "",
            tags: "",
            phone1: "",
            phone2: "",
            notes: "",
            companyName: "",
            phone1Type: "",
            phone2Type: "",
          }}
          onSubmit={(value) => {
            if (createContactData(value)) {
              setIsDisable(true);
            } else {
              setIsDisable(false);
            }
          }}>
          {/* div to display the add contact form in larger screens at particular width */}
          <div className="col-lg-4">
            {/* Tittle */}
            {/* div to align the Title Section */}
            <div className="d-flex justify-content-between align-items-center">
              <PageTitle
                label={"New Contact"}
                icon={leftArrowIcon}
                link={`/contacts`}
              />
              <SaveButton
                className="h-50 border-0 mb-2"
                isClicked={isDisable}
              />
            </div>
            {/* Form fields */}
            {/* Div to Assigning border and space alignment for the form section */}
            <div className="border pt-3 px-2">
              {/* First Name */}
              <Text name="firstName" label="First Name" required />

              {/* Middle Name */}
              <Text name="middleName" label="Middle Name" />

              {/* Last Name */}
              <Text name="lastName" label="Last Name" required />

              {/* Company Name */}
              <Text name="companyName" label="Company Name" />

              {/* Title */}
              <Text name="title" label="Title" />

              {/* Email */}
              <Email name="email" label="Email" required />

              {/* Tag */}
              <MultiSelect
                label="Tags"
                name="tags"
                placeholder="Tags"
                closeMenuOnSelect={false}
                options={tagList}
              />

              {/* Phone Number 1 Type */}
              <LabelComponent>Phone Number 1</LabelComponent>
              {/* This div is to align the phone-1 type's dropdown and input field in a straight line */}
              <div className="row">
                <Select
                  id="phone1Type"
                  name="phone1Type"
                  placeholder="Select Type"
                  className="col-4 text-nowrap"
                  options={Constants.PhoneType.PhoneType}
                  handleChange={(e) => setPhone1Type(e)}
                />

                {/* Phone Number 1 */}
                <Phone
                  name="phone1"
                  placeholder="Enter Phone Number"
                  className="col-8"
                />
              </div>

              {/* Phone Number 2 Type */}
              <LabelComponent>Phone Number 2</LabelComponent>
              {/* This div is to align the phone-2 type's dropdown and input field in a straight line */}
              <div className="row">
                <Select
                  id="phone2Type"
                  name="phone2Type"
                  options={Constants.PhoneType.PhoneType}
                  placeholder="Select Type"
                  className="col-4 text-nowrap"
                  handleChange={(e) => setPhone2Type(e)}
                />

                {/* Phone Number 2 */}
                <Phone
                  name="phone2"
                  placeholder="Enter Phone Number"
                  className="col-8"
                />
              </div>

              {/* Address1 */}
              <Text
                label="Address 1"
                name="address1"
                placeholder="Enter Street Name, Area Name., etc."
              />
              <Text
                label="Address 2"
                name="address2"
                placeholder="Enter Street Name, Area Name., etc"
              />

              {/* City */}
              <Text label="City" name="city" />

              {/* Zip code */}
              <Zipcode label="Zip code" name="postalCode" />

              {/* Country/State */}
              {renderCountryStateField()}

              {/* Notes */}
              <TextArea label="Notes" name="notes" />
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state.table;
}

export default connect(mapStateToProps)(AddContact);
