import React from "react";

//components
import PageTitle from "../../../components/PageTitle";
import Button from "../../../components/Button";

// Assets
import closeIcon from "../../../assets/icons/icon-cross.svg";
import messageIcon from "../../../assets/img/message.png";
import {
  MinusIcon,
  ContentExistIcon,
  AddSucessIcon,
} from "../../../assets/icons/index";
import Spinner from "../../../components/Spinner";
import NoRecordsFound from "../../../components/NoRecordsFound";

//lib
import ArrayList from "../../../lib/ArrayList";

const buttonStyle = {
  backgroundColor: "#C4C4C4",
  width: "65px",
  height: "30px",
  type: "submit",
};

const DragContent = ({
  contentList,
  onDragSave,
  contentOnDrag,
  disabled,
  removeContent,
  iconHandler,
  isLoading,
}) => {
  return (
    <div className="mx-3">
      {/* Page title */}
      <PageTitle
        label="Drag Content"
        buttonLabel="Done"
        buttonStyle={buttonStyle}
        icon={closeIcon}
        iconHandler={iconHandler}
      />

      <span className="mx-1 drag-content-title">
        Drag a content to add in the campaign
      </span>

      <div
        className="DragContent"
        style={{
          overflowY: `${contentList?.length > 7 ? "scroll" : "hidden"}`,
        }}>
        {isLoading ? (
          <Spinner />
        ) : ArrayList.isEmpty(contentList) ? (
          <NoRecordsFound message="No Records Found" hideCard />
        ) : (
          contentList &&
          contentList.length > 0 &&
          contentList.map((data) => {
            return (
              <>
                <div
                  className="cursor-pointer dragContent d-flex container-fluid align-items-center justify-content-between mt-2"
                  draggable
                  onDragOver={() => contentOnDrag(data)}>
                  <div className="col-6 d-flex align-items-center dragContent-section">
                    <div className="dragContent-section-data">
                      {data.isExist && (
                        <span
                          className="cursor-pointer"
                          onClick={() => removeContent && removeContent(data)}>
                          <MinusIcon />
                        </span>
                      )}

                      {!data.isExist && <ContentExistIcon />}

                      <span className="mx-2">
                        <img
                          src={messageIcon}
                          className="dragContent-section-img"
                        />
                      </span>
                    </div>

                    <div className="mobile-avatar-view mb-3 ml-2 content-title-text">
                      {(data.subject || data.title || data.email) && (
                        <div className="dragContent-datasubject">
                          {data.subject && (
                            <div className="dragContent-data-subject fw-bold text-color-secondary fw-600">
                              {data.subject}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>

                  {data.isExist && (
                    <span className="d-flex align-items-center text-color-green">
                      <span
                        className="mx-1"
                        style={{
                          fontWeight: 600,
                          fontSize: "7px",
                          lineHeight: "20px",
                        }}>
                        Added{" "}
                      </span>
                      <AddSucessIcon />
                    </span>
                  )}
                </div>
              </>
            );
          })
        )}
      </div>

      {/* {contentList && contentList.length > 0 && (
        <Button
          className="w-100 text-white bg-secondary border-light"
          label="Done"
          suceesIcon={closeIcon}
          disabled={disabled}
          onClick={onDragSave}
        />
      )} */}
    </div>
  );
};

export default DragContent;
