import React from "react";
import {  useNavigate } from "react-router-dom";

//Components
import Form from "../../components/Form";
import Button from "../../components/Button";
import Email from "../../components/Email";
import PageTitle from "../../components/PageTitle";
import Toast from "../../components/Toast";
import LinkTo from "../../components/LinkTo";

//assets
import ForgetPasswordIcon from "../../assets/icons/resetPasswordIcon.svg";

// Api
import UserApi from "../../api/User";

// Lib
import String from "../../lib/String";

const ResetPassword = () => {
  const navigate = useNavigate();


  const toArray = (value) => {
    let data = {};
    data.email = String.Trim(value.email);
    return data;
  };
  // eslint-disable-next-line no-import-assign
  const submit = async (value) => {
    try {
      UserApi.resetPassword(toArray(value), (response) => {
        if (response && response == true) {
          Toast.success("Email Sent Successfully");
          navigate("/forget-password-done");
        }else {
          Toast.error(response.message ? "Email Address does not exists" : response.message);
        }
      });
      
    } catch (error) {
      if (error) {
        console.log(err);
      }
    }
  };

  return (
    <div className=" d-flex justify-content-center align-items-center vh-100">
      <div className="basic-login-form mb-3">
        <div className="mb-4">
          {/* PageTitle */}
          <PageTitle
            label="Reset Password"
            description=" Enter your email address to receive a password reset link"
          />
        </div>

        {/* Form validation */}
        <Form
          initialValues={{
            email: "",
          }}
          onSubmit={(values) => {
            submit(values);
          }}>
          {/* Email Field */}
          <Email
            name="email"
            label="Email Address"
            placeholder="Enter your email"
            required
          />

          {/* Reset Password Button */}
          <Button
            className="w-100 mt-2"
            type="submit"
            label="Request Reset Password"
            icon={ForgetPasswordIcon}
          />

          {/* Have a Bowtie Account? Log In here */}
          <div className="mt-2 text-color-secondary">
            <span className="">Have a Bowtie Account?</span>
            {/* Login redirect link */}
            <LinkTo url="/" className="mx-1 hover-link"
              text = "Log In here"
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
