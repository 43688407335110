import React from "react";

//Styles
import "./Style.scss";

const Message = (props) => {
  // Message props
  const { label, className, textColor, fontWeight } =
    props;

  return (
    <>
      <div
        style={{
          color: textColor ? textColor : "",
          fontWeight: fontWeight ? fontWeight : "",
        }}
        className={className}>
        {label}
      </div>
    </>
  );
};

export default Message;
