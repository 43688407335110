import React from "react";
import { useNavigate } from "react-router-dom";
import { Card } from "reactstrap";

// Components
import Spinner from "../../../components/Spinner";
import NoRecordsFound from "../../../components/NoRecordsFound";
import Avatar from "../../../components/Avatar";

// Assets
import { arrowRightBlue } from "../../../assets/icons/index";
//helpers
import { GetDisplayName } from "../../../helpers/Contact";

import ArrayList  from "../../../lib/ArrayList";

const ContactList = ({ contactList, SelectContact,checkedValue, isLoading, isSearching }) => {
  // Navigate
  const navigate = useNavigate();

  return (
    <div className="w-150 mx-1">
      <div className="bg-white-smoke">
        <div className="row p-1 fw-bold">
          <div className="col-7 col-lg-5 col-sm-5 col-md-5 contact-name">
              Name
          </div>
          <div className="d-sm-block d-none col-2 col-lg-3 col-md-3 col-sm-3">
              Company
          </div>

          <div className="d-sm-block d-none col-3 col-lg-4 col-md-4 col-sm-4">
              Phone Number
          </div>
        </div>
      </div>
      {isLoading ? (
        <Spinner />
      ) : isSearching && ArrayList.isEmpty(contactList) ? (
        <NoRecordsFound message="No Contacts Found " hideCard />
      ) : ArrayList.isEmpty(contactList) ? (
        <NoRecordsFound message="No contacts added yet" hideCard />
      ) : (
        contactList &&
        contactList.length > 0 &&
        contactList.map((data) => {
          let phone1 = data?.contact?.phone1 === "(___) ___ - ____" ? "" : data?.contact?.phone1?.slice(0, 16);
          return (
            <>
              <Card
                className="contact-card mt-2 cursor-pointer" 
                onClick={() => {
                  navigate(`/contact/detail/${data?.contact?.contactId}`);
                }}>
                <div className="row p-2">
                  <div className="col-10 col-lg-5 col-md-5 col-sm-5">
                    <div className="row" >
                      <div
                        className="mt-1 col-1 col-lg-1 col-sm-1 col-md-1 contactlist-checkbox align-self-center"
                        onClick={(event) => {
                          event.stopPropagation();
                        }}>
                        <input
                          id={data?.contact?.contactId}
                          type="checkbox"
                          onClick={() => {
                            SelectContact(data);
                          }}
                          checked={checkedValue?.includes(data?.contact?.contactId)}
                        />
                      </div>
                      <div className="col-2 col-lg-2 col-md-2 col-sm-2 contactlist-avatar">
                        <Avatar
                          firstName={data?.contact?.firstName}
                          lastName={data?.contact?.lastName}
                          borderRadius={3}
                          email={data?.contact?.email}
                        />
                      </div>
                      <div className="col-8 col-lg-8 col-sm-8 col-md-8">
                        <div>
                          {GetDisplayName(data?.contact?.firstName, data?.contact?.lastName)}
                        </div>
                        <div className="text-color-secondary">
                          {data?.contact?.email}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-sm-block d-none col-sm-3 col-md-3 col-lg-3 bg-muted " style={{alignSelf:"center"}}>
                    {data?.contact?.companyName}
                  </div>
                  <div className="d-sm-block d-none col-sm-3 col-md-3 col-lg-3 bg-muted fw-500 " style={{alignSelf:"center"}}>
                    {phone1}
                  </div>
                  <div className="text-end mt-2 col-2 col-lg-1 col-sm-1 col-md-1">
                    <img
                      src={arrowRightBlue}
                      width={"6.59px"}
                      height={"11.17px"}
                    />
                  </div>
                </div>
              </Card>
            </>
          );
        })
      )}
    </div>
  );
};
export default ContactList;
