import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useNavigate } from "react-router-dom";

//lib
import ArrayList from "../../lib/ArrayList";

// Style
import "./Style.scss";

export default function Breadcrumb(props) {
  const { list, className } = props;

  // Defining navigate
  const navigate = useNavigate();
  return (
    <>
      <div className="breadcrumb-background p-1">
        <div className="container-fluid">
          <nav aria-label="breadcrumb" className={className ? className : ""}>
            <ol className="breadcrumb">
              {ArrayList.isNotEmpty(list) &&
                list.map((data, i) => (
                  <>
                    {i == list.length - 0 ? (
                      <li
                        aria-current="page">
                        <span className=" breadcrumb-list">{data.label}</span>
                      </li>
                    ) : i == list.length - 2 ?
                      (
                        <li
                          aria-current="page">
                          <a
                            className="text-decoration-none breadcrumb-pointer"
                            onClick={() => {
                              navigate(data.link, {
                                state: { activeTab: data?.activeTab },
                              });
                            }}>
                            {data.label}
                          </a>
                          <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                        </li>
                      ) : i == list.length - 3 ? (
                        <li
                          aria-current="page">
                          <a
                            className="text-decoration-none breadcrumb-pointer"
                            onClick={() => {
                              navigate(data.link, {
                                state: { activeTab: data?.activeTab },
                              });
                            }}>
                            {data.label}
                          </a>
                          <FontAwesomeIcon icon={faAngleRight} className="mx-2" />
                        </li>
                      ) :
                        (
                          <li>
                            <a
                              className="text-decoration-none breadcrumb-pointer"
                              onClick={() => {
                                navigate(data.link, {
                                  state: { activeTab: data?.activeTab },
                                });
                              }}>
                              {data.label}
                            </a>
                          </li>
                        )}
                  </>
                ))}
            </ol>
          </nav>
        </div>
      </div>
    </>
  );
}
