import React from "react";
import MyContext from "../../../context/MyContext";
import SideBarItem from "./SideBarItem";

class Sidbar extends React.Component {
  // Render Sidebar list
  renderSideBarList(currentRoute, enable) {
    const textColor = this.props.leftNavigationTextColor
      ? this.props.leftNavigationTextColor
      : "";

    const textHoverColor = this.props.leftNavigationTextHoverColor
      ? this.props.leftNavigationTextHoverColor
      : "";
    const navList = this.props && this.props.navList;
    return (
      navList &&
      navList().map((navigation, key) => (
        <SideBarItem
          id={key}
          key={key}
          navigation={navigation}
          currentRoute={currentRoute}
          enable={enable}
          textColor={textColor}
          textHoverColor={textHoverColor}
        />
      ))
    );
  }

  closeMenuOnBlur(e, ctx) {
    if (e.target.classList.contains("site-sidebar")) return;
    ctx.updateMenuToggled();
  }

  render() {
    const currentRoute = window.location.pathname;
    let leftNavStyle = {};
    if (this.props.leftNavigationBackgroundColor) {
      leftNavStyle = {
        backgroundColor: `${this.props.leftNavigationBackgroundColor}`,
      };
    }
    const enable = !this.props.enable ? false : true;

    return (
      <MyContext.Consumer>
        {(context) => (
          <div
            className={` w-auto sidebar-wrapper ${
              context.menuToggled ? "menu-toggled" : "menu-hidden"
            }`}
            style={{ padding: "0" }}
            onClick={(e) => this.closeMenuOnBlur(e, context)}>
            <nav
              id={"sidebar"}
              className="sidebar-menu-toggle"
              style={leftNavStyle}>
              <ul className="list-unstyled mb-0 pb-3 fw-bold">
                {this.renderSideBarList(currentRoute, enable)}
              </ul>
            </nav>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

export default Sidbar;
