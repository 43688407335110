import React from "react";
import { Eye } from "../../../assets/icons";
import HtmlPreview from "../../../components/HtmlPreview";
import DeleteButton from "../../../components/DeleteButton";
import LabelComponent from "../../../components/Label";

const ContentPreview = (props) => {
  const { content, subject, showEmptyContent, deleteToggle, contentId } = props;

  return (
    <>
      <div className="row">
        {contentId && (
          <div className="d-flex justify-content-end mt-3">
            <DeleteButton
              label="Delete Content"
              onClick={() => deleteToggle()}
              bgColor={"#FFFFFF"}
              textColor="#0078D4"
            />
          </div>
        )}

        <div className="">
          {(subject || content) ? (
            <div>
              <div className="p-2 mt-3">
                <div className="mt-3">
                  <div>
                    {" "}
                    <LabelComponent className="contentPreview-section">
                      Title
                    </LabelComponent>
                  </div>
                  <div>
                    <div className="contentPreview-subject">{subject}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="p-2 mt-3 row">
                  <div>
                    <LabelComponent className="contentPreview-body">
                      Body
                    </LabelComponent>
                  </div>
                  {content ? (
                    <div className="position-relative vh-80">
                      <HtmlPreview content={content} />
                    </div>
                  ) : (
                    <div>{/* No contnet to show */}</div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div className="contentPreview-eye">
              {showEmptyContent && (
                <>
                  <Eye />
                  <div className="contentPreview-upload">
                    <LabelComponent className="text-color-secondary">
                      Upload the content file to review
                    </LabelComponent>
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ContentPreview;
