import React, { useEffect, useState } from "react";

//components
import PageTitle from "../../components/PageTitle";
import SectionTitle from "../../components/SectionTitle";
import SelectedTags from "../../components/SelectedTags";
import Image from "../../components/Image";
import LabelComponent from "../../components/Label";

//Assets
import DownloadIcon from "../../assets/img/document.png";

const EditContent = ({ contentDetail, editContentHandler }) => {
  const [downloadLink, SetDownloadLink] = useState("");

  useEffect(() => {
    makeFile();
  }, [contentDetail?.content]);

  // creating a html file from string
  const makeFile = () => {
    let array = [contentDetail?.messageContent];

    const data = new Blob(array, { type: "text/html" });

    if (downloadLink !== "") window.URL.revokeObjectURL(downloadLink);

    SetDownloadLink(window.URL.createObjectURL(data));
  };

  return (
    <>
      {/* PageTitle */}
      <PageTitle
        label={contentDetail?.subject}
        buttonLabel="Edit Content"
        buttonWidth="max-content"
        targetUrl="/contents"
        textTruncate="text-truncate"
        buttonHandler={editContentHandler}
        editIcon
      />

      {/* Description */}
      <LabelComponent fontWeight="300">
        {contentDetail?.description}
      </LabelComponent>

      {/* Tags */}
      <SectionTitle label="Tags" />
      <SelectedTags list={contentDetail?.tags} />

      {/* HTML File */}
      {contentDetail?.messageContent.includes("</head>") ? (
        <>
          <SectionTitle label="HTML File" />
          <div className="d-flex align-items-center gap-2">
            <Image
              icon={DownloadIcon}
              className="contentDetail-img"
              alt="download file"
            />
            <div className="content-title-text">
              <a
                rel="noreferrer"
                target="_blank"
                download={contentDetail?.subject}
                className="text-decoration-none color-true-blue"
                href={downloadLink}>
                {contentDetail?.subject}
              </a>
            </div>
          </div>
        </>
      ):""}
    </>
  );
};

export default EditContent;
