import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, FormFeedback } from "reactstrap";
import ReactSelect from "react-select";
import Label from "../Label";
import Hint from "../Hint";
import Text from "../Text";

//Styles
import "./Style.scss";

class Select extends React.Component {
  constructor(props) {
    super(props);
    // assigning the field values
    this.setFieldValue = "";
    this.fieldValue = "";
  }

  // Validate the select field
  validate(value) {
    // select props
    const { label, placeholder, required, error } = this.props;

    // declaring the error message
    let errorMessage;
    // defining the input label
    const inputLabel = label || placeholder;
    // Assigning the error value to the errorMessageLabel
    const errorMessageLabel = error;

    // Assigning the error message to display when field is empty.
    if (!value && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }

  // component did update
  componentDidUpdate(prevProps) {
    if (
      prevProps.defaultValue &&
      prevProps.defaultValue !== this.props.defaultValue &&
      this.props.defaultValue !== this.fieldValue
    ) {
      this.setFieldValue(this.props.name, this.props.defaultValue);
    }
  }

  renderInput({ field, form: { touched, errors, setFieldValue, values } }) {
    // select props
    const {
      name,
      id,
      label,
      placeholder,
      options,
      isLoading,
      onOpen,
      onSearch,
      onInputChange,
      onRender,
      isDisabled,
      required,
      fontBolded,
      smallNotificationMessage,
      fullWidth,
      isClearable,
      defaultValue,
      isSearchable,
      handleOnChangeSubmit,
      textColor,
      width,
      marignBottom,
      color,
      onKeyDown,
      autoFocus,
      readOnly,
      hintText,
      className,
      labelStyle,
      disabled,
      value,
      menuPosition,
    } = this.props;

    // defining the error message
    const errorMessage = touched[name] && errors[name] ? errors[name] : null;
    // defining the input Id
    const inputId = id || name;

    // set field value
    this.setFieldValue = setFieldValue;
    if (defaultValue && field && field.value) {
      this.fieldValue = field.value;
    }

    // declaring the placeholder text
    let placeholderText;
    if (placeholder) {
      placeholderText = placeholder;
    } else if (label) {
      placeholderText = `Select ${label}`;
    }

    // declaring the style for text color
    const style = {};
    if (textColor) {
      style.color = `${textColor}`;
    }

    const fieldSelect = (
      <FormGroup
        id={id || label}
        style={{
          position: "relative",
          width: fullWidth ? "100%" : width || "",
        }}
        className={`${className} ${marignBottom ? "mb-0" : ""}`}>
        {label && (
          <Label
            id={inputId}
            required={required}
            className={`${fontBolded ? "fw-bold" : ""}`}
            style={labelStyle}>
            {label}
          </Label>
        )}

        {disabled ? (
          <Text
            name={name}
            placeholder={placeholder}
            outline
            disabled={disabled}
            value={value}
          />
        ) : (
          <ReactSelect
            inputId={id}
            onFocus={autoFocus}
            name={name}
            isDisabled={isDisabled}
            defaultValue={
              field.value && field.value.value !== null ? field.value : null
            }
            value={
              field.value && field.value.value !== null
                ? field.value
                : defaultValue || null
            }
            classNamePrefix="select"
            isClearable={isClearable || false}
            isSearchable={isSearchable || true}
            components={
              readOnly && {
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }
            }
            color={color}
            menuPlacement={ menuPosition ||"auto"}
            options={options}
            isLoading={isLoading}
            onMenuOpen={() => onOpen && onOpen()}
            placeholder={placeholderText}
            onKeyDown={onKeyDown}
            onChange={(value) => {
              setFieldValue(name, value);
              if (onInputChange) {
                values[name] = value;
                onInputChange({ setFieldValue, values, value });
              }
              this.props.handleChange && this.props.handleChange(value);
              if (handleOnChangeSubmit) {
                values[name] = value;
                handleOnChangeSubmit(values, name, value);
              }
            }}
            onBlur={() => field.onBlur({ target: { name } })}
            onInputChange={(value) => onSearch && onSearch(value)}
            styles={{
              valueContainer: (provided) => {
                const paddingTop = "4px";
                const paddingBottom = "4px";
                const fontSize = "14px";
                return Object.assign({}, provided, {
                  paddingTop,
                  paddingBottom,
                  fontSize,
                });
              },
              control: (provided, state) => {
                let boxShadow;
                let background;
                let borderColor = errorMessage
                  ? "#f86c6b !important"
                  : provided.borderColor;
                if (state.isFocused) {
                  boxShadow = errorMessage
                    ? "0 0 0 0.2rem rgba(248, 108, 107, 0.25)"
                    : "0 0 0 0.2rem rgba(32, 168, 216, 0.25)";
                  borderColor = errorMessage
                    ? borderColor
                    : "#8ad4ee !important";
                } else {
                  background = errorMessage ? background : "#f3f3f4 !important";
                  borderColor = errorMessage ? borderColor : "white !important";
                }
                return Object.assign({}, provided, {
                  borderColor,
                  boxShadow,
                  background,
                });
              },
            }}
          />
        )}

        {/* small notification message display */}
        {smallNotificationMessage && (
          <span className="d-block h7">{smallNotificationMessage}</span>
        )}

        {/* hint text display */}
        {hintText && (
          <Hint
            hintText={hintText}
            style={{ position: "absolute", marginTop: "6px" }}
          />
        )}

        {/* error message display */}
        {errorMessage && (
          <FormFeedback className="form-feedback">{errorMessage}</FormFeedback>
        )}
      </FormGroup>
    );

    if (!onRender) {
      return fieldSelect;
    }

    return onRender(values, (err, render) => (render ? fieldSelect : null));
  }

  render() {
    // Select props
    const { name, id } = this.props;

    return (
      <Field
        id={id || name}
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isLoading: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  onOpen: PropTypes.func,
  onSearch: PropTypes.func,
  onInputChange: PropTypes.func,
};

export default Select;
