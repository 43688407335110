import React, { useEffect, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import { Link } from "react-router-dom";

import { arrowRightWhite, arrowRightBlue } from "../../../assets/icons/index";

// Hook
function useHover() {
  // To set the value in a state using useState.
  const [value, setValue] = useState(false);
  // To define the UseRef Hook
  const ref = useRef(null);
  // Set value while hovers on the SideBar
  const handleMouseOver = () => setValue(true);
  // Set value while hovers out from the SideBar
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}

// get icons based on activity
const getNavIcon = (currentRoute, navigation, isHovered) => {
  if (currentRoute === navigation.url) {
    return navigation.hoverIcon;
  } else if (isHovered) {
    return navigation.hoverIcon;
  } else {
    return navigation.icon;
  }
};

const SideBarItem = (props) => {
  const {
    id,
    index,
    navigation,
    currentRoute,
    textColor,
    textHoverColor,
    enable,
  } = props;
  // Set text color for left navigation when not hovers on it.
  const leftNavTextStyle = textColor ? { color: textColor } : {};
  // Set text color for left navigation when hovers on it.
  const leftNavTextHoverStyle = textHoverColor ? { color: textHoverColor } : {};
  // To define the useHover function when hover on the left navbar
  const [hoverRef, isHovered] = useHover();
  return (
    <li
      id={`nav-item-${id}`}
      key={index}
      className={[
        `${
          currentRoute === navigation.url
            ? `active`
            : currentRoute === navigation.addPageurl
              ? `active`
              : currentRoute.startsWith(navigation.editPageurl)
                ? `active`
                : currentRoute.startsWith(navigation.detailsPageurl)
                  ? `active`
                  : ""
        }`,
        `${enable ? "" : navigation.name === "Dashboard" ? "" : "disabled"}`,
      ].join(" ")}
      ref={hoverRef}>
      <Link to={navigation.url}>
        <div className="float-right w-100 d-flex">
          <SVG
            src={getNavIcon(currentRoute, navigation, isHovered)}
            style={
              currentRoute === navigation.url
                ? {}
                : currentRoute === navigation.addPageurl
                  ? {}
                  : currentRoute.startsWith(navigation.editPageurl)
                    ? {}
                    : currentRoute.startsWith(navigation.detailsPageurl)
                      ? {}
                      : isHovered
                        ? leftNavTextHoverStyle
                        : leftNavTextStyle
            }
          />
          <div className="d-flex justify-content-between w-100">
            <span
              className="desktop-only"
              style={
                currentRoute === navigation.url
                  ? {}
                  : currentRoute === navigation.addPageurl
                    ? {}
                    : currentRoute.startsWith(navigation.editPageurl)
                      ? {}
                      : currentRoute.startsWith(navigation.detailsPageurl)
                        ? {}
                        : isHovered
                          ? leftNavTextHoverStyle
                          : leftNavTextStyle
              }>
              {navigation.name}
            </span>
            {currentRoute === navigation.url ||
        currentRoute === navigation.addPageurl ||
        currentRoute === navigation.editPageurl ||
        currentRoute === navigation.detailsPageurl ||
        "" ||
        isHovered ? (
                <div className="nav-right-arrow">
                  {navigation.name && (
                    <img
                      src={arrowRightBlue}
                      alt="arrow-right"
                      style={{ height: "18px" }}
                    />
                  )}
                </div>
              ) : (
                <div className="nav-right-arrow">
                  {navigation.name && (
                    <img
                      src={arrowRightWhite}
                      alt="arrow-right"
                      style={{ height: "18px" }}
                    />
                  )}
                </div>
              )}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default SideBarItem;
