import React from "react";
import { InputGroup, InputGroupText, Input } from "reactstrap";

import searchIcon from "../../assets/img/search.png";

//Styles
import "./Style.scss";

const Search = (props) => {
  const { classnames, placeholder, onChange, value, width, id, pageName, borderClassName } = props;

  return (
    <>
      <div>
        <InputGroup
          className={`${classnames} align-items-stretch mr-4 `}
          style={{ width: width || "100%" }}>
          <InputGroupText id={id} addontype="prepend" className={pageName}>
            <InputGroupText className="p-1 justify-content-center">
              
              <img className="icon search-icon" src={searchIcon} alt="searchIcon" />
              
            </InputGroupText> 
          </InputGroupText>
          <Input
            id={id}
            placeholder={placeholder}
            onChange={onChange}
            // value prop: can be used if want a full control over input field value
            {...((value || value === "") && { value })}
            className={borderClassName}
          />
        </InputGroup>
      </div>
    </>
  );
};

export default Search;
