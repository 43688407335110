import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

//Styles
import "./Style.scss";

class CancelButton extends React.Component {
  render() {
    // Defining props
    const { onClick, className, name, label } = this.props;

    return (
      <Button
        id={name || "Cancel"}
        outline
        onClick={onClick}
        type="button"
        className={`secondary-button  cancel-button m-1 ${className}`}
      >
        <span>{label|| "Cancel"}</span>
      </Button>
    );
  }
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default CancelButton;
