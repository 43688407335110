import React from "react";
import { ReactComponent as VectorFileUpload } from "../../assets/icons/icon-file-upload.svg";
import PropTypes from "prop-types";
import LabelComponent from "../Label";

// Style
import "./Style.scss";

// Proptypes
const propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
};

class FileSelectButton extends React.Component {
  render() {
    const {label, onChange, className, accept } = this.props;

    return (
      <>
        <input
          name="file"
          type="file"
          id="file"
          onChange={(e) => {
            onChange(e);
          } }
          className="form-control d-none"
          accept={accept} />
          
        <span className="d-block ">
          <label htmlFor="file" className={`profile-img-sm mb-0 ${className}`}>
            {/* <Button type="button" label={label} icon={VectorFileUpload} className="upload-button border-0" /> */}
            <LabelComponent id="file" className="upload-button border-0">
              <VectorFileUpload className="upload-button-svg" /> {label}
            </LabelComponent>
          </label>
        </span>
      </>
    );
  }
}

FileSelectButton.propTypes = propTypes;


export default FileSelectButton;