import React from "react";
import Button from "../../components/Button";

//Styles
import "./Style.scss";

const SubmitButton = (props) => {
  // Submit Button props
  const { label, icon, onClick,className,disabled } = props;

  return <Button label={label} className={className} icon={icon} onClick={onClick} type="submit" disabled={disabled} />;
};

export default SubmitButton;
