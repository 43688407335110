import React from "react";
import MyContext from "../../context/MyContext";
//Styles
import "./Style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faClose } from "@fortawesome/free-solid-svg-icons";

const MobileHamburgerMenu = () => (
  <MyContext.Consumer>
    {(context) => (
      <>
        <button
          className={`text-white hamburger-btn btn btn-plain mt-1`}
          onClick={() => context.updateMenuToggled()}>
          {!context.menuToggled && <FontAwesomeIcon icon={faBars} />}
          {context.menuToggled && <FontAwesomeIcon icon={faClose} />}
        </button>
      </>
    )}
  </MyContext.Consumer>
);

export default MobileHamburgerMenu;
