import React from "react";

//Styles
import "./Style.scss";

const RadioButton = (props) => {
  const { id, name, value, handleChange,checked, size, label } = props;
  return (
    <div className="d-flex gap-2">
      <input
        id={id}
        name={name}
        className={`${size}`}
        type="radio"
        value={value}
        onChange={handleChange}
        checked={checked}
      />
      <div>{label}</div>
    </div>
  );
};

export default RadioButton;
