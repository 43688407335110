import React from "react";

import ArrayList  from "../../lib/ArrayList";

//Styles
import "./Style.scss";

const Tags = ({ title, onClick, list, tagSelected }) => {


  let convert = list.toLocaleString().split(",");

  let uniqueTags = [...new Set(convert)];

  // Remove the empty string
  const filteredList = uniqueTags.filter((element) =>{
    return element.trim()  !== '';
  });

  return (
    <div className="px-1 bg-white-smoke row gx-1">
      {/* showing the section only when the campaign data is there */}

      {ArrayList.isNotEmpty(list) ? (
        <span className="row my-2 float-left">
          {title}
        </span>
      ) : (
        ""
      )}
      <span className="d-flex flex-wrap ">
        {ArrayList.isNotEmpty(filteredList)
          ? filteredList.map((tag, i) => (
            <span className="me-1 py-2 ">
              <span
                className="tag-style small"
                id={tag}
                style={{
                  color: `${tagSelected.has(i) ? "#FFFFFF" : " #707B96"}`,
                  background: `${tagSelected.has(i) ? "#1b3165" : ""}`,
                }}
                onClick={(e) => onClick(e, i)}>
                {tag}
              </span>
            </span>
          ))
          : ""}
      </span>
    </div>
  );
};

export default Tags;
