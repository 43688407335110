import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Layouts
import PublicLayout from "./layout/PublicLayout";
import DefaultLayout from "./layout/DefaultLayout";

// Signup page
import SignUp from "./views/login/SignUp";
import SignUpDone from "./views/login/SignUpDone";

// Log in page
import Login from "./views/login";

// Reset password
import ResetPassword from "./views/user/ResetPassword";
import ResetPasswordDone from "./views/user/ResetPasswordDone";
import PasswordChange from "./views/user/PasswordChange";
import PasswordChangeDone from "./views/user/PasswordChangeDone";

// Dashboard
import Dashboard from "./views/dashboard";
import Profile from "./views/Profile";

// Contact
import ContactListPage from "./views/contact/ContactListPage";
import ContactAddPage from "./views/contact/ContactAddPage";
import ContactDetailPage from "./views/contact/ContactDetailPage";

// Campaign
import CampaignListPage from "./views/campaign/CampaignListPage";
import CampaignDetailPage from "./views/campaign/CampaignDetailPage";
import CampaignEditPage from "./views/campaign/CampaignAddEditPage";

// Content
import ContentListPage from "./views/content/ContentListPage";
import ContentDetailPage from "./views/content/ContentAddPage";

// User
import UserAddPage from "./views/user/UserAddPage";
import UserEditPage from "./views/user/UserEditPage";

// Setting
import Setting from "./views/setting";

// Redux store
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Context
import MyProvider from "./context/MyProvider";

function App(props) {
  return (
    <div>
      <MyProvider>
        <Router history={props.history}>
          <Routes>
            {/* Main Route */}
            <Route
              path="/"
              element={
                <PublicLayout
                  exact
                  name="Login"
                  path="/login"
                  component={Login}
                />
              }
            />

            {/* Signup Route */}
            <Route
              path="/sign-up"
              element={
                <PublicLayout
                  exact
                  name="Sign-up"
                  path="/sign-up"
                  component={SignUp}
                />
              }
            />

            {/* Signup Success Route */}
            <Route
              path="/sign-up-done"
              element={
                <PublicLayout
                  exact
                  name="Sign-up-done"
                  path="/sign-up-done"
                  component={SignUpDone}
                />
              }
            />

            {/* Login Route */}
            <Route
              path="/login"
              element={
                <PublicLayout
                  exact
                  name="Login"
                  path="/login"
                  component={Login}
                />
              }
            />

            {/* Forgot Password Route */}
            <Route
              path="/forget-password"
              element={
                <PublicLayout
                  exact
                  name="ForgetPassword"
                  path="/public/reset-password"
                  component={ResetPassword}
                />
              }
            />

            {/* Forgot Password Success Route */}
            <Route
              path="/forget-password-done"
              element={
                <PublicLayout
                  exact
                  name="ForgetPasswordDone"
                  path="/forget-password-done"
                  component={ResetPasswordDone}
                />
              }
            />
            {/* Reset Password Route */}
            <Route
              path="/reset-password"
              element={
                <PublicLayout
                  exact
                  name="ResetPassword"
                  path="/reset-password"
                  component={PasswordChange}
                />
              }
            />

            {/* Reset Password Success Route */}
            <Route
              path="reset-password-done"
              element={
                <PublicLayout
                  exact
                  name="ResetPasswordDone"
                  path="/reset-password-done"
                  component={PasswordChangeDone}
                />
              }
            />

            {/* Dashboard Route */}
            <Route
              path="/dashboard"
              element={
                <DefaultLayout
                  exact
                  name="dashboard"
                  path="/dashboard"
                  component={Dashboard}
                />
              }
            />

            <Route
              path="/profile"
              element={
                <DefaultLayout
                  exact
                  name="Profile Setting"
                  path="/profile"
                  component={Profile}
                />
              }
            />

            {/* Contacts Route */}
            <Route
              path="/contacts"
              element={
                <DefaultLayout
                  exact
                  name="Contacts"
                  path="/contacts"
                  component={ContactListPage}
                />
              }
            />

            {/* Contact Add Route */}
            <Route
              path="contact/add"
              element={
                <DefaultLayout
                  exact
                  name="Contacts"
                  path="/contact/add"
                  component={ContactAddPage}
                />
              }
            />

            {/* Contact Detail Route */}
            <Route
              path="/contact/detail/:id"
              element={
                <DefaultLayout
                  exact
                  name="Contacts"
                  path="/contact/detail"
                  component={ContactDetailPage}
                />
              }
            />

            {/* Campign Route */}
            <Route
              path="/campaign"
              element={
                <DefaultLayout
                  exact
                  name="Campaign"
                  path="/campaign"
                  component={CampaignListPage}
                />
              }
            />

            {/* Campaign Add Route */}
            <Route
              path="/campaign/add"
              element={
                <DefaultLayout
                  exact
                  name="Campaign"
                  path="/campaign/add"
                  component={CampaignEditPage}
                />
              }
            />

            {/* Campign Detail Route */}
            <Route
              path="/campaign/:id"
              element={
                <DefaultLayout
                  exact
                  name="Campaign"
                  path="/campaign/:id"
                  component={CampaignDetailPage}
                />
              }
            />

            {/* Campaign Edit Route */}
            <Route
              path="/campaign/edit/:id"
              element={
                <DefaultLayout
                  exact
                  name="edit"
                  path="/campaign/edit/:id"
                  component={CampaignEditPage}
                />
              }
            />

            {/* Contents List Page Route */}
            <Route
              path="/contents"
              element={
                <DefaultLayout
                  exact
                  name="contents"
                  path="/contents"
                  component={ContentListPage}
                />
              }
            />

            {/* Content Add Route */}
            <Route
              path="/content/add"
              element={
                <DefaultLayout
                  exact
                  name="Communication"
                  path="/content/add"
                  component={ContentDetailPage}
                />
              }
            />

            {/* Content Detail Route */}
            <Route
              path="/content/:id"
              element={
                <DefaultLayout
                  exact
                  name="Communication"
                  path="/content/:id"
                  component={ContentDetailPage}
                />
              }
            />

            {/* <Route
              path="/user"
              element={
                <DefaultLayout
                  exact
                  name="Users"
                  path="/user"
                  component={User}
                />
              }
            /> */}

            {/* User Add Route */}
            <Route
              path="/user/add"
              element={
                <DefaultLayout
                  exact
                  name="Users"
                  path="/user/add"
                  component={UserAddPage}
                />
              }
            />

            {/* User Edit Route */}
            <Route
              path="/user/edit/:id"
              element={
                <DefaultLayout
                  exact
                  name="User"
                  path="/user/:id"
                  component={UserEditPage}
                />
              }
            />

            {/* Settings Route */}
            <Route
              path="/setting"
              element={
                <DefaultLayout
                  exact
                  name="Profile Setting"
                  path="/setting"
                  component={Setting}
                />
              }
            />
          </Routes>
        </Router>
      </MyProvider>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    settings: state.settings,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
