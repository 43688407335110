import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

//Assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

//Styles
import "./Style.scss";

class DeleteButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.state = {
      isHovering: false,
      isMobile: document.body.offsetWidth < 500,
    };
  }

  // Defined a function in state when hovers on the button
  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }

  // To set value for the function when hovers on the button
  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  render() {
    // defining props
    const {
      label,
      onClick,
      loading,
      className,
      id,
      bgColor,
      textColor,
    } = this.props;

    return (
      <Button
        id={id || label}
        className={`px-2 delete-button ${className}`}
        disabled={loading}
        onClick={onClick}
        type={"button"}
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
        style={{
          background: bgColor ? bgColor : "#0078D4",
          color: textColor ? textColor : "#FFF",
        }}>
        <span className="mx-1">
          <FontAwesomeIcon icon={faTrash} />
        </span>
        <span id={id + "Item"}>{loading ? "Deleting..." : label || ""}</span>
      </Button>
    );
  }
}

DeleteButton.propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  loading: PropTypes.bool,
};

export default DeleteButton;
