import React from 'react';
import Breadcrumb from '../../components/Breadcrumb';
import PageHeader from '../../components/PageHeader';
import Sidebar from './Sidebar';

const Setting = () => {

  // Breadcrumb list
  const bradcrumbList = [{ label: "Settings", link: "/setting" }];

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={bradcrumbList} />

      {/* Title */}
      <PageHeader title={"Settings"} />

      {/* Sidebar items */}
      <div className="">
        <Sidebar />
      </div>
    </>
  );
};

export default Setting;