import React from "react";
import { TabContent, TabPane } from "reactstrap";

//tabs
import UserManagement from "../user/UserListPage";
import SystemSetting from "./SystemSetting";


const TabContentSection = (props) => {
  const { activeTab, SYSTEM_SETTING, USER_MANAGEMENT } = props;
  return (
    <>
      <TabContent activeTab={activeTab}>
        <TabPane tabId={SYSTEM_SETTING}>
          <SystemSetting />
        </TabPane>

        <TabPane tabId={USER_MANAGEMENT}>
          <UserManagement />
        </TabPane>
      </TabContent>
    </>
  );
};

export default TabContentSection;