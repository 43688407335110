import React, { useEffect, useState } from "react";

import { isLoggedIn } from "../../common/Helper";

//Components
import Search from "../../components/Search";
import PageTitle from "../../components/PageTitle";
import ContentCard from "./components/ContentCard";
import Breadcrumb from "../../components/Breadcrumb";
import PageHeader from "../../components/PageHeader";
import ContentPreview from "./components/ContentPreview";
import Spinner from "../../components/Spinner";
import ContentDetails from "./ContentDetailPage";
import DeleteModal from "../../components/ConfirmationModal";
import NoRecordsFound from "../../components/NoRecordsFound";
import EditContent from "./ContentEditPage";
import Tags from "../../components/Tags";

// Lib
import ArrayList from "../../lib/ArrayList";
// API
import API from "../../api/Content";

// Constants
import { Content } from "../../constants/Campaign";

const Communications = () => {
  // content data fetched from api
  const [contents, setContent] = useState([]);
  const [tagsList, setTagsList] = useState([]);

  // to handle seelcted tags
  const [tagSelected, setTagSelected] = useState(new Map());
  const [tags, setTags] = useState([]);

  const [viewContent, setViewContent] = useState("");
  const [viewSubject, setViewSubject] = useState("");
  const [showContentView, setShowContentView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [search, setSearch] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  //data of the selected content
  const [contentDetail, setContentDetail] = useState("");

  //delete modal function
  const [modalState, setModalState] = useState(false);

  // toggle edit content detail
  const [editContent, setEditContent] = useState(false);

  // uploaded file name
  const [fileName, setFileName] = useState("");

  const [elementId, setElementId] = useState("");

  const [color, setColor] = useState("");

  const [ tagList , setTagList ] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");
  const [isDisable , setIsDisable] = useState(false);

  //verify user
  useEffect(() => {
    isLoggedIn();
    getDetails();
  }, []);

  useEffect(() => {
    getFilterdList();
    uniqueTags();
  }, [tags.length, tagsList.length]);

  // comparing two arrays if value matches return true else false
  function checkAvailability(arr, val) {
    return arr.some(function (arrVal) {
      return ArrayList.compare(val, arrVal);
    });
  }

  //Find Duplicates in the array
  function checkDuplicate(array, newData) {
    //getting campaing ids form the array
    let contentId = array?.map((data) => {
      return data?.contentId;
    });
    //campaign id of new Data
    let newContentId = newData?.contentId;

    //Checking for duplicates
    if (contentId.includes(newContentId)) {
      return true;
    } else {
      return false;
    }
  }

  // To get the filtered content list based on selected tag
  const getFilterdList = () => {
    let tempList = [];
    for (let i = 0; i < tagsList?.length; i++) {
      for (let j = 0; j < tags.length; j++) {
        if (checkAvailability(tagsList[i]?.tags, tags[j])) {
          if (!checkDuplicate(tempList, tagsList[i])) {
            tempList.push(tagsList[i]);
          }
        }
      }
    }
    setContent(tempList);

    //If no tag was selected
    if (tempList.length < 1) {
      setContent(tagsList);
    }
  };
  const contentList = [{ label: "Contents" }];

  //Handle search function
  const handleSearch = async function (e) {
    let searchTerm = e.target.value.trim();
    setSearchTerm(searchTerm);
    setSearch(true);
    API.searchCommunicationList(searchTerm, (res) => {
      if (res.length) {
        setContent(res);
        setTagsList(res);
      } else {
        setTagsList("");
        setContent("");
      }
      if (searchTerm) {
        setSearch(true);
      } else {
        setSearch(false);
      }
    });
  };

  const getDetails = async () => {
    setIsLoading(true);
    await API.getContentList((res) => {
      const data = res;
      const sortedData = data.sort((a,b) => handleSort(a,b));
      setContent(sortedData);
      setTagsList(res);
      setIsLoading(false);
    });
  };

  // Content view data
  const getPreViewContent = (element) => {
    let onClickElementId = element.contentId;
    setElementId(onClickElementId);
    setContentDetail(element);
    setViewContent(element.messageContent);
    setViewSubject(element.subject);
    setFileName(element.subject);
  };

  //handletags click
  const handleTags = (e, i) => {
    let tag = e?.target?.id;
    let tempTags = tags;

    // pushing selected tags to array
    if (tempTags.indexOf(tag) > -1) {
      let index = tempTags.indexOf(tag);
      tempTags.splice(index, 1);
    } else {
      tempTags.push(tag);
    }
    setTags(tempTags);

    // setting key/value pair
    if (tagSelected.has(i)) {
      tagSelected.delete(i);
      for (let [key, value] of tagSelected) {
        setTagSelected((map) => new Map(map.set(key, value)));
        tagSelected.set(key, value);
      }
    } else {
      setTagSelected((map) => new Map(map.set(i, tag)));
    }

    if (tags.length == 0) {
      setContent(tagsList);
    }
  };

  //content delete function
  const handleDelete = (communicationId) => {
    setIsDisable(true);
    API.deleteCommunication(communicationId, (res) => {
      if (contentDetail?.contentId == res?.data?.contentId) {
        API.searchCommunicationList(searchTerm, (res) => {
          if (res.length) {
            setContent(res);
            setTagsList(res);
          } else {
            setTagsList("");
            setContent("");
          }
          if (searchTerm) {
            setSearch(true);
          } else {
            setSearch(false);
          }
        });
        setIsOpen(false);
        setShowContentView(false);
        setEditContent(false);
        toggle();
      }
      toggle();
      setIsDisable(false);

    });
  };

  //Toggle function for delete modal
  const toggle = () => {
    setModalState(!modalState);
  };

  // toggle edit content detail
  const editContentHandler = () => {
    setEditContent(true);
    setIsOpen(false);
    setShowContentView(true);
  };
  // set content
  let fileReader;
  const handleFileRead = () => {
    const content = fileReader.result;
    setViewContent(content);
  };

  // file upload content
  const handleFileChosen = (e) => {
    e.preventDefault();
    let files = e.target && e.target.files[0];
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(files);
    setViewSubject(files.name);
    setFileName(files.name);
    setShowContentView(true);
    e.target.value = null;
  };

  // remove uploaded file
  const handleFileRemove = (element) => {
    setShowContentView("");
    setFileName(element?.subject);
    setViewContent(element?.content);
    setViewSubject(element?.subject);
  };

  //Width of the two section(edit/content preview)
  let editSection = isOpen ? 3 : 0;
  let ContentPreviewSection = showContentView ? 5 : 8;
  let editContentDetails = editContent ? 3 : 0;

  // Filter tags and return unique
  function uniqueTags() {
    let tags = new Set();
    for (let data of tagsList) {
      Array.isArray(data.tags) &&
        data.tags.forEach((tag) => {
          tags.add(tag);
        });
    }
    let array = Array.from(tags);

    setTagList(array);

    return array;
  }
  
  //sort the contentlist
  const handleSort = (a, b) => {
   
    let sortA = a?.subject.toLowerCase();
    let sortB = b?.subject.toLowerCase();

    if (sortA <  sortB) {
      return -1;
    }
    if (sortA >  sortB) {
      return 1;
    }
    return 0;
  };

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={contentList} />

      {/* Header */}
      <PageHeader title="Select a content to view" />
      <div className="bg-white">
        <div className="row">
          <div className="col-lg-4 border px-0">
            {/* Page title */}
            <div className="mx-3">
              <PageTitle
                label="Content List"
                buttonLabel="Add new content"
                targetUrl="/content/add"
                tags
              />
            </div>

            {/* Search */}
            <Search
              classnames="page-search"
              placeholder="Find in content"
              onChange={handleSearch}
            />

            {/* Tags */}
            <div className="d-flex bg-white-smoke">
              <div className="mx-3 mb-4">
                <Tags
                  title="Select a tag or tags to show the content you need"
                  list={tagList}
                  onClick={handleTags}
                  tagSelected={tagSelected}
                />
              </div>
            </div>

            {/* Content List */}
            <div>
              <div
                style={{
                  overflowY: `${contents?.length > 6 ? "scroll" : "hidden"}`,
                  height: "100vh",
                }}>
                {contents && !isLoading && contents.length ? (
                  contents.map((item) => {
                    return (
                      <>
                        {item?.status != Content.DELETED && (
                          <div
                            className="mt-3 mx-3"
                            style={{
                              backgroundColor:
                                item.communicationId == elementId ? color : "",
                            }}>
                            <ContentCard
                              id={item.contentId}
                              firstName={item.subject}
                              fontBolded
                              mailIcon
                              tags={item.tags}
                              item={item}
                              getPreViewContent={getPreViewContent}
                              isLoading={isLoading}
                              isOpen={isOpen}
                              setIsOpen={setIsOpen}
                              setShowContentView={setShowContentView}
                              setEditContent={setEditContent}
                              setColor={setColor}
                              elementId={elementId}
                            />
                          </div>
                        )}
                      </>
                    );
                  })
                ) : isLoading ? (
                  <Spinner
                    spinnerStyle={{ position: "relative", marginLeft: "45%" }}
                  />
                ) : search ? (
                  <NoRecordsFound message="No Contents Found" hideCard />
                ) : isLoading ? (
                  isLoading
                ) : (
                  <NoRecordsFound message="No content added yet" hideCard />
                )}
              </div>
            </div>
          </div>

          {/* Edit content */}
          {isOpen && !editContent && (
            <div className={`col-lg-${editSection} border`}>
              <ContentDetails
                contentDetail={contentDetail}
                editContentHandler={editContentHandler}
              />
            </div>
          )}

          {/* content Detail */}
          {!isOpen && editContent && (
            <div className={`col-lg-${editContentDetails} border`}>
              <EditContent
                tags={tags}
                fileName={fileName}
                subject={viewSubject}
                setViewSubject={setViewSubject}
                contentDetail={contentDetail}
                setModalState={setModalState}
                handleFileChosen={(e) => handleFileChosen(e)}
                handleFileRemove={(e) => handleFileRemove(e)}
                setShowContentView={setShowContentView}
                setViewContent={setViewContent}
                getDetails={getDetails}
                setIsOpen={setIsOpen}
                setEditContent={setEditContent}
                setContentDetail={setContentDetail}
              />
            </div>
          )}

          {/* SideBar */}
          {showContentView && (
            <div className={`col-lg-${ContentPreviewSection} border`}>
              <ContentPreview
                content={viewContent}
                subject={viewSubject}
                deleteToggle={toggle}
                contentId={contentDetail?.contentId}
              />
            </div>
          )}
        </div>
        <DeleteModal
          isOpen={modalState}
          toggle={toggle}
          title="Delete Content"
          primaryButtonText="Delete content"
          secondaryButtonText="Cancel"
          description="Would you like to delete this content?"
          onClickNo={toggle}
          onClickYes={() => handleDelete(contentDetail?.contentId)}
          disabled={isDisable}
        />
      </div>
    </>
  );
};

export default Communications;
