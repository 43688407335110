import * as apiUrl from "../helpers/apiUrl";
import api from "../lib/Api";
import toast from "../components/Toast";
import * as cookie from "../lib/Cookie";

//lib
import {HTTP_STATUS} from "../lib/http";

class Setting {
  // Get Customer setting
  async getSetting(callback) {
    try {
      const customerId = cookie.get("customerId");

      let url = apiUrl.customerSetting(customerId);
      await api.get(`${url}`, "", (res) => {
        return callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Save Customer setting
  async saveSetting(access_token, data, callback) {
    try {
      const customerId = cookie.get("customerId");

      let url = apiUrl.customerSetting(customerId);
      await api.patch(`${url}`, data, (result,res) => {
        
        if (res == HTTP_STATUS.RESPONSE_SUCCESS) {
          toast.success("Privacy Settings Saved Successfully");
          return callback(result);
        }else if (res >= HTTP_STATUS.RESPONSE_ERROR) {
          toast.error(result?.message);
        }

      });
    } catch (err) {
      console.log(err);
    }
  }
}
const setting = new Setting();
export default setting;
