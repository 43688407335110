
// Contact icons
import contactIcon from "../assets/icons/icon-contact.svg";
import contactHoverIcon from "../assets/icons/icon-contactBlue.svg";

// Campaign icons
import campaignIcon from "../assets/icons/icon-campaign.svg";
import campaignHoverIcon from "../assets/icons/campaign.svg";
// Content icons
import contentIcon from "../assets/icons/icon-content.svg";
import contentHoverIcon from "../assets/icons/content.svg";

//setting icon
import settingIcon from "../assets/icons/icon-setting.svg";
import settingHoverIcon from "../assets/icons/icon-setting.svg";

/**
 * Get Account Navigation list
 */
export function navList() {
  let navList = [];

  // Dashboard link
  // navList = navList.concat({
  //   // name: "Dashboard",
  //   url: "/dashboard",
  //   icon1: dashboardIcon,
  // });

  // Contacts link
  navList = navList.concat({
    name: "Contacts",
    url: "/contacts",
    icon: contactIcon,
    hoverIcon: contactHoverIcon,
    editPageurl: "/contact/detail",
    addPageurl: "/contact/add",
  });

  // Campaigns link
  navList = navList.concat({
    name: "Campaigns",
    url: "/campaign",
    icon: campaignIcon,
    hoverIcon: campaignHoverIcon,
    editPageurl: "/campaign",
    addPageurl: "/campaign/add",
  });

  // Content link
  navList = navList.concat({
    name: "Content",
    url: "/contents",
    icon: contentIcon,
    hoverIcon: contentHoverIcon,
    editPageurl: "/content/",
    addPageurl: "/content/add",
  });

  // Settings link
  navList = navList.concat({
    name: "Settings",
    url: "/setting",
    icon: settingIcon,
    hoverIcon: settingHoverIcon,
    addPageurl: "/user/add",
    editPageurl: "/user/edit/"
  });

  return navList;
}
