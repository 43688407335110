import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import Label from "../Label";
import Hint from "../Hint";
import ArrayList from "../../lib/ArrayList";

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    // To set values in state
    this.state = {
      inputValue: "",
    };

    // Text area reference
    this.textareaRef = React.createRef();
  }

  // Validate Text area field
  validate(value) {
    // Text area field props
    const { label, placeholder, required, error } = this.props;

    // Defining error message
    let errorMessage;
    // Defining input label
    const inputLabel = label || placeholder;
    // Assigning the error value to error message label
    const errorMessageLabel = error;

    // Defining the error validation message when field is required
    if ((!value || !value.trim()) && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors } }) {
    // Text area props
    const {
      name,
      id,
      label,
      placeholder,
      required,
      rows,
      onChange,
      defaultValue,
      maxLength,
      showCharCount,
      disabled,
      className,
      hintText,
      labelStyle,
    } = this.props;

    // Defining the min height of the field
    const fieldMinHeight = rows ? rows * 23 : 92;
    // Defining the error message
    const errorMessage = touched[name] && errors[name] ? errors[name] : null;
    // Defining the input Id
    const inputId = id || name;

    // Length of input characters
    const countInputChars = () => {
      if (this.state.inputValue) {
        return this.state.inputValue.length;
      }
      return 0;
    };

    // To set the input value
    const setInputValue = (e) => {
      const { value } = e.target;
      this.setState({
        inputValue: value,
      });
    };

    let fieldValue = ArrayList.isArray(field.value)
      ? field.value.toString()
      : field.value;
    if (field?.value?.label) {
      fieldValue = field?.value?.label;
    }

    return (
      <FormGroup style={{ position: "relative" }} className={`${className}`}>
        {label && (
          <Label
            className="fw-700"
            id={inputId}
            style={labelStyle}
            required={required}>
            {label}
          </Label>
        )}
        {disabled ? (
          <div
            className={`outline-field-style px-1 py-2 ${
              !fieldValue && "text-secondary"
            }`}>
            {fieldValue ? (
              fieldValue
            ) : (
              <span className="invisible">
                {placeholder || `Enter ${label}`}
              </span>
            )}
          </div>
        ) : (
          <Input
            id={inputId}
            className="tag-input"
            {...field}
            type="textarea"
            placeholder={placeholder || `Enter ${label}`}
            invalid={!!errorMessage}
            rows={rows}
            onKeyUp={(e) => {
              setInputValue(e);
              onChange && onChange(e);
            }}
            defaultValue={defaultValue}
            style={{
              background: "#F3F3F4",
              borderRadius: "5px",
              minHeight: fieldMinHeight,
            }}
            maxLength={maxLength && maxLength}
            ref={this.textareaRef}
            disabled={disabled}
          />
        )}

        {/* Showing the character count message */}
        {showCharCount && maxLength && (
          <span className="char-count d-block text-inline-grayed h7">
            {`${countInputChars()}/${maxLength && maxLength} Characters`}
          </span>
        )}

        {/* Displaying the hint message */}
        {hintText && (
          <div style={{ position: "absolute", marginTop: "6px" }}>
            <Hint hintText={hintText} />
          </div>
        )}

        {/* Displaying the error message */}
        {errorMessage && (
          <FormFeedback style={{ position: "absolute", marginTop: 1 }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    // Text area props
    const { name, id } = this.props;

    return (
      <Field
        id={id || name}
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.string,
};

export default TextArea;
