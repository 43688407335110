import React from "react";

//Styles
import "./Style.scss";

const SectionTitle = (props) => {
  const { label, className } = props;

  return (
    <div>
      <div>
        <div className={className ? className : "primary-text mt-2 py-2"}>
          <div className="section-title-label">{label}</div>
        </div>
      </div>
    </div>
  );
};

export default SectionTitle;