import React from 'react';

//styles
import "./Style.scss";  

const PageHeader = ({ title }) => {
  return (
    <div className="d-flex mx-1 page-header-height" >
      <div
        className="fw-700 text fs-5"
      >  
        {title}
      </div>
    </div>
  );
};

export default PageHeader;