import React, { useState } from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

//Assets
import settingIcon from "../../assets/icons/content.svg";
import chevronRight from "../../assets/icons/icon-chevron-right.svg";

//Tab content section
import TabContentSection from "./TabContentSection";
import { useLocation } from "react-router-dom";

//Constants
const USER_MANAGEMENT = "User Management";
const SYSTEM_SETTING = "System Settings";

const Sidebar = () => {
  const { state } = useLocation();
  let currentActiveTab = state?.activeTab;

  const [activeTab, setActiveTab] = useState(
    currentActiveTab || SYSTEM_SETTING
  );

  //toggle tabs
  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <div className="row ">
        <div className="col-lg-3 border py-2 vh-100 setting-section">
          <Nav vertical>
            {/* System Settings */}
            <NavItem className="p-0 mx-0 contact-card">
              <NavLink
                className={classnames(
                  { activeNav: activeTab === SYSTEM_SETTING },
                  { [`p-0 mx-0 w-100`]: true }
                )}
                onClick={() => toggleTab(SYSTEM_SETTING)}>
                <div className="d-flex  justify-content-between">
                  <span className="text-dark p-2">
                    <img src={settingIcon} className="img-15 mx-1" />
                    {SYSTEM_SETTING}
                  </span>

                  <img src={chevronRight} />
                </div>
              </NavLink>
            </NavItem>

            {/* User Management */}
            <NavItem className="p-0 mx-0 contact-card">
              <NavLink
                className={classnames(
                  { activeNav: activeTab === USER_MANAGEMENT },
                  { [`p-0 mx-0 w-100`]: true }
                )}
                onClick={() => toggleTab(USER_MANAGEMENT)}>
                <div className="d-flex justify-content-between">
                  <span className="text-dark p-2">
                    <img src={settingIcon} className="img-15 mx-1" />
                    {USER_MANAGEMENT}
                  </span>

                  <img src={chevronRight} />
                </div>
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <div className="col-lg-9 border">
          {/* TabContent */}
          <TabContentSection
            activeTab={activeTab}
            USER_MANAGEMENT={USER_MANAGEMENT}
            SYSTEM_SETTING={SYSTEM_SETTING}
          />
        </div>
      </div>
    </>
  );
};

export default Sidebar;
