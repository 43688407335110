import React from "react";

// Assets
import emailIcon from "../../../assets/icons/emailIcon.png";
import Image from "../../../components/Image";
import LabelComponent from "../../../components/Label";
import LinkTo from "../../../components/LinkTo";
import SectionTitle from "../../../components/SectionTitle";

// Components
import Spinner from "../../../components/Spinner";

function CampaignCard(props) {
  const {
    title,
    firstName,
    lastName,
    email,
    fontBolded,
    mailIcon,
    tags,
    item,
    getPreViewContent,
    isLoading,
    setIsOpen,
    setShowContentView,
    setEditContent,
    setColor,
    elementId,
  } = props;

  const names = [];
  if (firstName) {
    names.push(firstName);
  }

  if (lastName) {
    names.push(lastName);
  }

  if (email) {
    names.push(email);
  }

  const name = names.join(" ");

  // Set the onclick element Color
  if (elementId) {
    setColor("#ececec");
  } else {
    setColor("");
  }

  return (
    <div className="contact-card">
      {isLoading ? (
        <Spinner />
      ) : (
        <div className="mx-2">
          <div className="p-2 d-flex justify-content-between">
            <div className="contentCard-section d-flex align-items-center">
              {mailIcon && (
                <Image
                  icon={emailIcon}
                  alt="emailIcons"
                  className="contentCard-section-img"
                />
              )}
              <div className="content-details">
                <div className="mobile-avatar-view ml-5 pl-5 content-title-text">
                  {(name || title || email) && (
                    <div className="contentCard-section-title">
                      {(email || name) && (
                        <LabelComponent
                          className={`${
                            fontBolded ? "mt-2 fw-bold" : ""
                          } text-color-secondary content-title-text`}
                          style={{
                            margin: "0 0 2px 9px",
                          }}>
                          {email || name}
                        </LabelComponent>
                      )}
                      {title && (
                        <SectionTitle
                          label={title}
                          className="text-color-secondary"
                        />
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center">
              <LinkTo
                url="#"
                className="text-true-blue"
                onClick={() => {
                  getPreViewContent(item);
                  setIsOpen(true);
                  setShowContentView(true);
                  setEditContent(false);
                }}
                text="View"
              />
            </div>
          </div>
          <div className="d-flex flex-wrap p-2 gap-2">
            {tags && tags.length > 0
              ? tags.map((item) => (
                  <div className="rounded-1 bg-color-primary text-white">
                    <span className="contentCard-section-item">{item}</span>
                  </div>
                ))
              : ""}
          </div>
        </div>
      )}
    </div>
  );
}

export default CampaignCard;
