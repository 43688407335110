export const Content = {
  DELETED : "DELETED"
};

// interval options
export const intervalOptions = [
  {
    label: "1",
    value: "1",
  },
  {
    label: "2",
    value: "2",
  },
  {
    label: "3",
    value: "3",
  },
  {
    label: "4",
    value: "4",
  },
  {
    label: "5",
    value: "5",
  },
  { label: "6", value: "6" },
  {
    label: "7",
    value: "7",
  },
];

// frequency options
export const dripUnitOptions = [
  {
    label: "Days",
    value: "DAYS",
  },
  {
    label: "Months",
    value: "MONTHS",
  },
];


// tag type options
export const tagTypeOptions = [
  {
    label: "Any",
    value: "ANY",
  },
];

// Drip trigger options

export const dripTriggerOptions = [
  {
    label: "From Beginning",
    value: "FROM_BEGINNING"
  },
  {
    label: "From Previous",
    value: "FROM_PREVIOUS"
  }
];

// Program type options
export const programTypeOptions = [
  {
    label: "Flexible",
    value: "FLEXIBLE"
  },
  {
    label: "Fixed",
    value: "FIXED"
  }
];

