import { fetchList } from "../common/ReduxTableAction";
import api from "../lib/Api";

import toast from "../components/Toast";
import { UNAUTHORIZED_ERROR } from "../constants/Common";
import * as apiUrl from "../helpers/apiUrl";
import * as cookie from "../lib/Cookie";
import { COOKIE } from "../constants/Cookie";

// Class
class ContactAPI {
  static receiveContactDetail() {
    return {
      type: "RECEIVE_CONTACT_DETAIL",
    };
  }

  // get contact list from the api
  static getContactDetail(data) {
    return (dispatch) => {
      try {
        dispatch(
          fetchList("contact", apiUrl.customerContact(data.customer_id), data)
        );
        dispatch(this.receiveContactDetail());
      } catch (err) {
        console.log(err); 
      }
    };
  }
  
  // to create new contact from data provided
  static createContact( data, callback) {
    const customer_id = cookie.get(COOKIE.CUSTOMER_ID);
    data = { newContactRequestList: [data] };
    try {
      api.post(
        apiUrl.customerContact(customer_id),
        data,
        async (response) => {
          if ((await response) && response.applicationErrorCode) {
            toast.error(response.message);
          } else if (response && response[0] && response[0].contactId) {
            toast.success("Contact Created Successfully");
            return callback(true);
          } else if (response && response.code === UNAUTHORIZED_ERROR) {
            toast.warn("Access Token Expired!");
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  // update contact detail
  static updateContactDetailRequest() {
    return {
      type: "REQUEST_UPDATE_CONTACT_DETAIL",
    };
  }
  // to update contact detail
  static async updateContact(contact_id, data, callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);
      let url = apiUrl.customerContactUpdate(customerId, contact_id);

      //update contact
      await api.patch(url, data, (res) => {
        callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Delete contact detail
  static deleteContactDetailRequest() {
    return {
      type: "REQUEST_DELETE_CONTACT_DETAIL",
    };
  }

  //Delete Contact
  static deleteContact(contact_id, data, callback) {
    const customerId = cookie.get("customerId");
    try {
      api.delete(
        apiUrl.customerContactDelete(customerId, contact_id),
        data,
        async (response) => {
          callback(response);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  // getContact details
  static async getContactDetails (contactId, callback) {
    const customerId = cookie.get("customerId");

    await api.get(apiUrl.getContact(customerId, contactId), "", (res) => {
      return callback(res);
    });
  }

  //Get contacts list
  static async getContactList (callback) {
    const customerId = cookie.get("customerId");

    await api.get(apiUrl.customerContact(customerId), "", (res) => {
      return callback(res);
    });
  }

  //contact delete
  static async  deleteContactList(contact_id, callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);

      await api.get(
        apiUrl.customerContactDelete(customerId, contact_id),
        "",
        (res) => {
          callback(res);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  //assign campaigns to contact
  static async  assignToContact(contactId, data, callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);

      await api.post(
        apiUrl.assignToCampaign(customerId, contactId),
        data,
        (res) => {
          callback(res);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  //assign campaigns to contact
  static async  getCountryList(callback) {
    try {
      await api.get(apiUrl.country(), "", (res) => {
        callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  //assign campaigns to contact
  static async  getStateList(country, callback) {
    try {
      if (country) {
        await api.get(`${apiUrl.country()}/${country}`, "", (res) => {
          callback(res);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  //search contact

  static async searchContactList(searchTerm, callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);
      api.get(apiUrl.searchContact(customerId, searchTerm), "", (res) => {
        return callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Upload contacts as CSV
  static async uploadCSV(formData, callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);
      api.uploadFile(apiUrl.uploadCSV(customerId), formData, (res) => {
        callback(res);
      });
    } catch(err) {
      console.log(err);
    }
  }


  static async getContactTags(callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);
  
      await api.get(
        apiUrl.getTag(customerId), "",
        (res) => {
          return callback(res);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
}


export default ContactAPI;