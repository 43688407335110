import React from "react";
import { Button as ReactButton } from "reactstrap";
import PropTypes from "prop-types";

//Styles
import "./Style.scss";

// Props Types
const propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  className: PropTypes.string,
  labelColor: PropTypes.string,
  align: PropTypes.string,
  size: PropTypes.string,
  icon: PropTypes.string,
  active: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  hideTextInMobile: PropTypes.string,
  isProgress: PropTypes.string,
  progressLabel: PropTypes.string,
  block: PropTypes.bool,
};

// Default Props
const defaultProps = {
  type: "button",
};

class Button extends React.Component {
  state = {
    color: this.props.color || "",
  };

  // Set color while hovers on the button.
  hoverIn = () => {
    this.setState({
      color: this.props.hoverColor || this.props.color || "",
    });
  };

  // Set color while hovers out from the button.
  hoverOut = () => {
    this.setState({ color: this.props.color || "" });
  };

  render() {
    const {
      id,
      label,
      name,
      type,
      className,
      align,
      size,
      labelColor,
      icon,
      active,
      disabled,
      outline,
      onClick,
      hideTextInMobile,
      block,
      progressLabel,
      isProgress,
    } = this.props;

    return (
      <ReactButton
        id={id || label}
        name={name || label}
        type={type || "button"}
        size={size}
        active={active}
        disabled={disabled}
        outline={outline}
        onClick={onClick}
        block={block}
        className={`primary-button my-1 button float-${align} ${className}`}>
        <img src={icon} />
        {hideTextInMobile && (
          <span className={"d-none d-sm-none d-md-inline-block"}>
            {icon && (
              <i style={icon ? { marginRight: 5 } : {}} className={icon} />
            )}
            {hideTextInMobile}
          </span>
        )}
        {label && (
          <span
            className={
              hideTextInMobile
                ? "d-block d-sm-block d-md-none d-lg-none d-lg-none"
                : ""
            }
            style={{ color: labelColor }}>
            {icon && (
              <i style={icon ? { marginRight: 5 } : {}} className={icon} />
            )}
            {isProgress ? progressLabel : label}
          </span>
        )}
      </ReactButton>
    );
  }
}

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
