import api from "../lib/Api";
import * as apiUrl from "../helpers/apiUrl";

import toast from "../components/Toast";
import { UNAUTHORIZED_ERROR } from "../constants/Common";
import { COOKIE } from "../constants/Cookie";
import * as Cookie from "../lib/Cookie";

class CampaignAPI {
  // get program list from the api
  static async getProgramList(data, callback) {

    let customerId = Cookie.get(COOKIE.CUSTOMER_ID);

    try {
      await api.get(apiUrl.getProgram(customerId), data, (res) => {
        callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // New Program
  static addProgram(data, callback) {
    let customerId = Cookie.get(COOKIE.CUSTOMER_ID);
    try {
      api.post(
        apiUrl.addNewProgram(customerId),
        data,
        async (response) => {
          if ((await response) && response.applicationErrorCode) {
            toast.error(response.message);
          } else if (response && response.campaignId) {
            toast.success("Campaign Created Successfully");
            return callback(true);
          } else if (response && response.code === UNAUTHORIZED_ERROR) {
            toast.warn("Access Token Expired!");
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  // update program detail
  static updateProgram(programId, data, callback) {
    const customerId = Cookie.get(COOKIE.CUSTOMER_ID);
    try {
      api.patch(
        apiUrl.programUpdate(customerId, programId),
        data,
        async (response) => {
          if ((await response) && response.applicationErrorCode) {
            toast.error(response.message);
          } else if (response && response.name) {
            toast.success("Campaign Updated Successfully");
            return callback(true);
          } else if (response && response.code === UNAUTHORIZED_ERROR) {
            toast.warn("Access token expired");
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  // Update campaign content detail
  static async updateContent(programId, data, callback) {
    try {
      const customerId = Cookie.get(COOKIE.CUSTOMER_ID);
      await api.post(apiUrl.campaignContentUpdate(customerId, programId), data, (res) => {
        callback(res);
      });

    } catch(err) {
      console.log(err);
    }
  }

  // get program list from the api
  static async getProgramsList(programId, data, callback) {
    const customerId = Cookie.get(COOKIE.CUSTOMER_ID);
    try {
      await api.get(
        apiUrl.getProgramLists(customerId, programId),
        data,
        (res) => {
          return callback(res);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  //delete program/campaign data
  static async deleteProgram(campaignId, callback) {
    try {
      const customerId = Cookie.get(COOKIE.CUSTOMER_ID);

      await api.get(apiUrl.deleteProgram(customerId, campaignId), "", (res) => {
        return callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  //Assign contact to campaign
  static async assignContact(campaignId, data, callback) {
    try {
      const customerId = Cookie.get(COOKIE.CUSTOMER_ID);

      await api.post(
        apiUrl.assignToContact(customerId, campaignId),
        data,
        (res,status) => {
          callback(res,status);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  //Search campaigns/programs
  static async searchProgram(searchTerm, callback) {
    try {
      const customerId = Cookie.get(COOKIE.CUSTOMER_ID);

      await api.get(
        apiUrl.searchCampaigns(customerId, searchTerm),
        "",
        (res) => {
          callback(res);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

  //Search campaigns/programs
  static async getCampaignTags(callback) {
    try {
      const customerId = Cookie.get(COOKIE.CUSTOMER_ID);
  
      await api.get(
        apiUrl.getTag(customerId),"",
        (res) => {
          console.log();
          return callback(res);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }

}

export default CampaignAPI;
