import React, { useState } from "react";
//Components
import Button from "../../components/Button";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import Password from "../../components/password";
//helper
import { validateStrongPassword } from "../../common/Helper";
//assets
import changePasswordIcon from "../../assets/icons/changePasswordIcon.svg";

const PasswordChange = () => {
  //state
  const [newPasswordMismatchError, setNewPasswordMismatchError] = useState("");
  const [reEnterPaswordMismatchError, setReEnterPaswordMismatchError] =
    useState("");

  // handle form submit values
  const handleSubmit = (values) => {
    if (validateFields(values) === false) {
      return;
    }
  };

  // Validate submitted values from the from
  const validateFields = (values) => {
    try {
      let success = true;
      const currentStep = 1;
      const newPassword = values.newPassword;
      const reEnterPassword = values.reEnterPassword;

      const passwordError = validateStrongPassword(newPassword);

      switch (currentStep) {
        case 1:
          if (passwordError) {
            setNewPasswordMismatchError(passwordError);
            success = false;
          } else if (
            newPassword &&
            reEnterPassword &&
            newPassword !== reEnterPassword
          ) {
            setNewPasswordMismatchError("");
            setReEnterPaswordMismatchError("Confirm password did not match");
            success = false;
          } else {
            setReEnterPaswordMismatchError("");
            setNewPasswordMismatchError("");
          }
          break;
        default:
          break;
      }

      return success;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="vertical-center">
      <div className="client-login-wrapper">
        <PageTitle
          label="Reset Password"
          description="Enter your new password"
          colorHeader=" #707B96"
        />
        <Form
          onSubmit={(values) => {
            handleSubmit(values);
          }}
          initialValues={{
            newPassword: "",
            reEnterPassword: "",
          }}>
          <div className="mt-2">
            <Password
              name="newPassword"
              label="New Password"
              placeholder="Enter your new password"
              required
              error={newPasswordMismatchError || ""}
            />
            <Password
              name="reEnterPassword"
              label="Re-Enter New Password"
              placeholder="Re-Enter your new password"
              required
              error={reEnterPaswordMismatchError || ""}
            />
            <Button
              className="w-100 mt-2"
              label="Reset Password"
              type="submit"
              icon={changePasswordIcon}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default PasswordChange;
