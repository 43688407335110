import { fetchList } from "../common/ReduxTableAction";
import api from "../lib/Api";
import toast from "../components/Toast";
import { UNAUTHORIZED_ERROR } from "../constants/Common";
import * as apiUrl from "../helpers/apiUrl";
import { API_URL } from "../config";
import * as cookie from "../lib/Cookie";
import { COOKIE } from "../constants/Cookie";
export function createCommunication() {
  return {
    type: "CREATE_COMMUNICATION",
  };
}
class Content {
  // New Communication
  addCommunication(data, callback) {
    const customer_id = cookie.get(COOKIE.COOKIE_CUSTOMER_ID);
    try {
      api.post(
        apiUrl.addNewCommunication(customer_id),
        data,
        async (response) => {
          if ((await response) && response.applicationErrorCode) {
            toast.error(response.message);
          } else if (response && response.contentId) {
            toast.success("Content Published Successfully");
            return callback(true);
          } else if (response && response.code === UNAUTHORIZED_ERROR) {
            toast.warn("Access Token Expired!");
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
  //delete communication/content
  deleteCommunication(communicationId, callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);
      api.delete(
        apiUrl.deleteCommunication(customerId, communicationId),
        "",
        (res) => {
          toast.success("Content Deleted Sucessfully");
          callback(res);
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
  updateCommunication(communication_id, data, callback) {
    const customer_id = cookie.get(COOKIE.COOKIE_CUSTOMER_ID);
    try {
      api.patch(
        apiUrl.updateCommunication(customer_id, communication_id),
        data,
        async (response) => {
          if ((await response) && response.applicationErrorCode) {
            toast.error(response.message);
            callback(false);
          } else if (response && response.subject) {
      
            toast.success("Content Updated Successfully");
            callback(response);
          } else if (response && response.code === UNAUTHORIZED_ERROR) {
            toast.warn("Access token expired");
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
  //Get communication details
  fetchCommunicationData() {
    return {
      type: "FETCH_COMMUNICATION_DATA",
    };
  }
  getCommunicationDetail() {
    const customer_id = cookie.get(COOKIE.CUSTOMER_ID);
    return (dispatch) => {
      try {
        dispatch(
          fetchList(
            "communications",
            apiUrl.communicationDetails(customer_id),
            ""
          )
        );
        dispatch(fetchCommunicationData());
      } catch (err) {
        console.log(err);
      }
    };
  }
  //search communication
  searchCommunicationData() {
    return {
      type: "FETCH_COMMUNICATION_DATA_SUCCESS",
    };
  }
  searchCommunicationDetail(tag) {
    const customer_id = cookie.get(COOKIE.CUSTOMER_ID);
    let search = tag;
    return (dispatch) => {
      try {
        if (tag) {
          dispatch(
            fetchList(
              "communications",
              apiUrl.communicationDetails(customer_id),
              "",
              "",
              "",
              { search: search }
            )
          );
        } else {
          dispatch(
            fetchList(
              "communications",
              apiUrl.communicationDetails(customer_id)
            )
          );
        }
        dispatch(searchCommunicationData());
      } catch (err) {
        console.log(err);
      }
    };
  }
  getContentList = async (callback) => {
    const customerId = cookie.get(COOKIE.CUSTOMER_ID);

    const url =
      API_URL + `/api/customer/${customerId}/content?onlyViewValid=false`;

    await api.get(url, "", (res) => {
      return callback(res);
    });
  };
  // to get the communication/content detail on specific communication id
  getCommunicationData = async (communicationId, callback) => {
    const customerId = cookie.get(COOKIE.CUSTOMER_ID);
    await api.get(
      apiUrl.getCommunication(customerId, communicationId),
      "",
      (res) => {
        return callback(res);
      }
    );
  };
  //search communication/content
  searchCommunicationList(searchTerm, callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);
      api.get(apiUrl.searchCommunication(customerId, searchTerm), "", (res) => {
        return callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }
}
const content = new Content();
export default content;
