import moment from "moment";

/**
 * Get Current Year
 */
export function getCurrentYear() {
  return new Date().getFullYear();
}

/**
 * Get Formatted Today dd/mm/yyyy
 */
export function formatDate(date) {
  if (!date) {
    return null;
  }
  if(!moment(date, "YYYY-MM-DDTHH:mm:ssZ").isValid()) {
    return null;
  }

  return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("MM-DD-YYYY");
}

//format data and time for reactstrap date

export function formatDateTime(date) {
  if (!date) {
    return null;
  }
  if(!moment(date).isValid()) {
    return null;
  }

  return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("MM-DD-YYYY");
}

//date us format
export function USFormat(date) {
  if (!date) {
    return null;
  }
  if(!moment(date).isValid()) {
    return null;
  }
  
  return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("YYYY-MM-DDTHH:mm:ss");
}

//Formatted date mm/d/yyyy

export function formatNormalDate(date) {
  if (!date) {
    return null;
  }
  if(!moment(date, "YYYY-MM-DDTHH:mm:ssZ").isValid()) {
    return null;
  }

  return moment(date, "YYYY-MM-DDTHH:mm:ssZ").format("MMM, D, YYYY");
}

//todays date
const todayDate = new Date();

export default todayDate;