import React from "react";

const Image = (props) => {
  const { icon, onClick, className, alt } = props;

  return (
    <img
      src={icon}
      className={className}
      onClick={onClick}
      alt={alt}
    />
  );
};

export default Image;
