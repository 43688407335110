import React, { Component } from "react";
import { Tooltip } from "reactstrap";

//Styles
import "./Style.scss";

class Hint extends Component {
  constructor(props) {
    super(props);
    // defining the state value for tooltipOpen 
    this.state = {
      tooltipOpen: false,
    };

    // To bind the toggle tool tip
    this.toggleTooltip = this.toggleTooltip.bind(this);
  }

  // Toggling the Tool tip
  toggleTooltip = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  render() {
    // Assigning the tooltipOpen value from state
    const { tooltipOpen } = this.state;
    // Hint props
    const { id, hintText, tooltipText, style } = this.props;

    return (
      <>
        <span
          id={`${id}-tooltip`}
          className="text-link" style={style}>
          {hintText}
        </span>
        {tooltipText ? (
          <Tooltip
            id={id || tooltipText}
            placement="left"
            isOpen={tooltipOpen}
            target={`${id}-tooltip`}
            toggle={this.toggleTooltip}>
            {tooltipText}
          </Tooltip>
        ) : (
          ""
        )}
      </>
    );
  }
}

export default Hint;
