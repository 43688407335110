import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Styles
import "./Style.scss";

toast.configure();
const notify = (message, status) => {
  toast[status](message, {
    theme: "colored",
    autoClose: 4994,
  });
};
const Toast = {
  success: (message) => {
    notify(message, "success");
  },
  error: (message) => {
    notify(message, "error");
  },
  warn: (message) => {
    notify(message, "warn");
  },
  info: (message) => {
    notify(message, "info");
  },
};
export default Toast;
