import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import AvatarCard from "../../components/AvatarCard";

// Components
import Breadcrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import Form from "../../components/Form";
import UserForm from "./components/UserForm";
import Spinner from "../../components/Spinner";
import SubmitButton from "../../components/SubmitButton";

// API
import API from "../../api/User";

// Constants
import {
  PermissinonLevelOptions,
  UserStatusOptions,
} from "../../constants/User";

// Lib
import String from "../../lib/String";

// Assets
import { toast } from "react-toastify";
import { GetDisplayName } from "../../helpers/Contact";

const FieldLabelStyle = {
  fontWeight: 700,
};

const EditUser = () => {
  // User detail
  const [detail, setDetail] = useState();
  const [editableField, setEditableField] = useState(false);
  const [enableReinitialize, setEnableReinitialize] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  // User ID
  const { id } = useParams();

  // Breadcrumb list
  const breadcrumbList = [
    { label: "Settings", link: "/setting" },
    {
      label: "User Management",
      link: "/setting",
      activeTab: "User Management",
    },
    {
      label: GetDisplayName(detail?.firstName, detail?.lastName, detail?.email),
    },
  ];

  // To fetch user detail
  function getUserDetail() {
    API.getUserDetail(id, (res) => {
      setDetail(res);
    });
  }

  useEffect(() => {
    getUserDetail();
  }, []);

  // Handle onchange - function executed on input field changes
  const handleOnchange = () => {
    setEnableReinitialize(false);
  };

  // Discard Model
  // Forming data
  const toArray = (value) => {
    let data = {};
    data.firstName = value.firstName;
    data.lastName = value.lastName;
    data.email = value.email;
    data.status = value.status.value;
    data.permissionLevel = value.permissionLevel.value;
    if (value.password) data.password = value.password;
    return data;
  };

  // Update user details

  function updateUser(value) {
    setIsLoading(true);
    API.updateUser(id, toArray(value), (res) => {
      if (Object.keys(res).includes("applicationErrorCode")) {
        toast.error(res?.message);
      } else if (res) {
        getUserDetail();
        setEnableReinitialize(true);
        setEditableField(false);
        toast.success("User Updated Successfully.");
      }
    });
    setIsLoading(false);
  }

  return (
    <>
      {/* BreadCrumb */}
      <Breadcrumb list={breadcrumbList} />

      <div className="row">
        {/* User name with logo */}
        <div className="py-4">
          <AvatarCard
            square
            firstName={detail?.firstName}
            lastName={detail?.lastName}
            email={detail?.email}
            showBackArrow
            fontBolded
            handleOnClick={() => {
              setEditableField(false);
              setEnableReinitialize(true);
              getUserDetail();
            }}
            backUrl={
              editableField ? `/user/edit/${detail.personId}` : "/setting"
            }
            activeTab="User Management"
          />
        </div>

        {/* Form */}
        <div className="col-lg-4 border vh-80 position-relative">
          {/* Form */}
          <div className="mt-3 vh-100 position-relative">
            {isLoading ? (
              <Spinner />
            ) : (
              <Form
                enableReinitialize={enableReinitialize}
                initialValues={{
                  firstName: detail?.firstName,
                  lastName: detail?.lastName,
                  email: detail?.email,
                  password: "",
                  status: !editableField
                    ? String.Capitalise(detail?.status)
                    : UserStatusOptions.find(
                        (status) => status.value == detail?.status
                      ),
                  permissionLevel: !editableField
                    ? String.Capitalise(detail?.permissionLevel)
                    : PermissinonLevelOptions.find(
                        (state) => state.value == detail?.permissionLevel
                      ),
                }}
                onSubmit={(value) => {
                  updateUser(value);
                }}>
                {editableField === true ? (
                  <div className="d-flex justify-content-between">
                    <PageTitle label="User Information" />
                    <div className="mt-2">
                      <SubmitButton label="Save" />
                    </div>
                  </div>
                ) : (
                  <PageTitle
                    label="User Information"
                    buttonLabel="Edit"
                    buttonHandler={() => {
                      editableField === true
                        ? setEditableField(false)
                        : setEditableField(true);
                    }}
                    buttonStyle={{ background: "#0078D4" }}
                    showButton
                    targetUrl
                    editIcon
                  />
                )}
                {/* User Form */}
                <UserForm
                  FieldLabelStyle={FieldLabelStyle}
                  editableField={editableField}
                  handleOnchange={handleOnchange}
                  editPage={true}
                  password
                />
              </Form>
            )}
          </div>
        </div>

        {/* RighSide Section */}
        <div className="col-lg-8 border"></div>
      </div>
    </>
  );
};

export default EditUser;
