import axios from "axios";
import { API_URL } from "../config";
import * as Cookie from "../lib/Cookie";
import { COOKIE } from "../constants/Cookie";

class Api {
  //Get API
  static async get(url, data, callback) {
    await this.getAccessToken(async (token) => {
      fetch(url, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Pragma: "no-cache",
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.status);
          }
          return response.json();
        })
        .then((result) => {
          return callback(result);
        })
        .catch((err) => callback(err));
    });
  }

  //Create API
  static async post(url, data, callback) {
    try {
      await this.getAccessToken(async (token) => {
        axios({
          method: "post",
          json: true,
          url: url,
          headers: {
            "Content-Type": "application/json",
            common: {
              Authorization: `Bearer ${token}`,
            },
          },
          data: JSON.stringify(data),
          transformResponse: [
            (data) => {
              callback(JSON.parse(data));
            },
          ],
        })
          .then(function (response) {
            callback("",response.status);
          })
          .catch(function (error) {
            callback(error,error.response.status);
          });
      });
    } catch (err) {
      console.log(err);
    }
  }

  // upload files
  static async uploadFile(url, data, callback) {
    try {
      await this.getAccessToken(async (token) => {
        axios({
          method: "post",
          json: true,
          url: url,
          headers: {
            "Content-Type": "multipart/form-data",
            common: {
              Authorization: `Bearer ${token}`,
            },
          },
          data: data,
          transformResponse: [
            (data) => {
              callback(JSON.parse(data));
            },
          ],
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  //POST API
  static async postWithoutAccessToken(url, body, callback) {
    try {
      const data = JSON.stringify(body);
      fetch(url, {
        method: "POST",
        body: data,
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((result) => callback(result, null))
        .catch((err) => callback(err));
    } catch (err) {
      console.log(err);
      return callback(err);
    }
  }

  //Update API
  static async patch(url, data, callback) {
    try {
      await this.getAccessToken(async (token) => {
        axios({
          method: "patch",
          json: true,
          url: url,
          headers: {
            "Content-Type": "application/json",
            common: {
              Authorization: `Bearer ${token}`,
            },
          },
          data: JSON.stringify(data),
          transformResponse: [
            (data) => {
              callback(JSON.parse(data));
            },
          ],
        });
      });
    } catch (err) {
      console.log(err);
    }
  }

  //Delete API
  static async delete(url, data, callback) {
    try {
      await this.getAccessToken(async (token) => {
        axios({
          method: "get",
          json: true,
          url: url,
          headers: {
            "Content-Type": "application/json",
            common: {
              Authorization: `Bearer ${token}`,
            },
          },
          
        })
          .then(function (response) {
            callback(response);
          })
          .catch(function (error) {
            callback(error);
          });
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Function updateAuthToken returns access token
  static getAccessToken = async (callback) => {
    try {
      const refresh_token = Cookie.get(COOKIE.REFRESH_TOKEN);

      this.getRefreshToken(refresh_token, (response) => {
        const { accessToken, customerId, refreshToken } = JSON.parse(response);
        // updating cookies from the response
        if (accessToken && customerId && refreshToken) {
          Cookie.set(COOKIE.ACCESS_TOKEN, accessToken);
          Cookie.set(COOKIE.REFRESH_TOKEN, refreshToken);
          Cookie.set(COOKIE.CUSTOMER_ID, customerId);
        }
        return callback(accessToken);
      });
    } catch (err) {
      console.log(err);
    }
  };

  // To get new access token with refresh token
  static async getRefreshToken(refreshToken, callback) {
    try {
      const data = {
        refreshToken: refreshToken,
      };
      const url = `${API_URL}/api/auth/refresh`;
      this.postWithoutAccessToken(url, data, (res) => {
        callback(JSON.stringify(res));
        return JSON.stringify(res);
      });
    } catch (err) {
      console.log(err);
    }
  }
 
}

export default Api;
