import React from "react";
import { BeatLoader } from "react-spinners";

//Styles
import "./Style.scss";

const override = `
  margin: 0;
  position: absolute;
  top: 50%;
  left: 55%;
`;

class Spinner extends React.Component {
  constructor(props) {
    super(props);
    // To set the value in state
    this.state = {
      loading: true,
    };
  }

  render() {
    // Spinner style props
    const { spinnerStyle } = this.props;
    return (
      <BeatLoader
        css={spinnerStyle ? spinnerStyle : override}
        size={this.props.size || 18}
        margin={2}
        color="#BDBDC0"
        loading
      />
    );
  }
}

export default Spinner;
