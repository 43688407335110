import React, { useState } from "react";

// Components
import Message from "../../../components/Message";
import MultiSelect from "../../../components/MultiSelectCreatable";
import PageTitle from "../../../components/PageTitle";
import Text from "../../../components/Text";
import TextArea from "../../../components/TextArea";
import LabelComponent from "../../../components/Label";
import SubmitButton from "../../../components/SubmitButton";
import CancelButton from "../../../components/CancelButton";
import RichText from "../../../components/RichText";
import SectionTitle from "../../../components/SectionTitle";

// Assets
import { Vector, VectorFileUpload, Xcross } from "../../../assets/icons";
import RadioButton from "../../../components/RadioButton";

const ContentForm = (props) => {
  const {
    fileName,
    handleTitle,
    handleFileChosen,
    handleFileRemove,
    pageTitleLabel,
    isTagRequired,
    buttonLabel,
    content,
    handleEditor,
    tagList,
    buttonDisable,
    setEditContent,
    setIsOpen,
    isEditPage,
    label,
  } = props;
  const FieldLabel = { color: "color-dark-gray" };

  const [isChecked, setIsChecked] = useState(false);
  const [showRichText, setShowRichText] = useState(true);

  const handleRadio = (e) => {
    setIsChecked(!isChecked);

    if (e.target.value == "RichText") {
      setShowRichText(true);
    } else {
      setShowRichText(false);
    }
  };
  return (
    <>
      <div className="row color-dark-gray">
        {/* Page title */}
        <div className="bg-white-smoke">
          <div className="d-flex flex-row justify-content-between">
            <PageTitle label={pageTitleLabel} />
            {!isEditPage && (
              <div className="mt-2">
                <SubmitButton label={buttonLabel} disabled={buttonDisable} />
              </div>
            )}
          </div>
        </div>
        <div className="mt-3">
          <Message
            label="Write about the content and add tags to it for easy search"
            textColor={"text-color-secondary"}
          />
        </div>

        <Text
          label="Content title"
          name="subject"
          placeholder="Name your content"
          onChange={(e) => handleTitle(e)}
          required
          labelStyle={FieldLabel}
        />

        <TextArea
          label="Description"
          name="description"
          rows={5.4}
          labelStyle={FieldLabel}
        />

        <MultiSelect
          label="Tags"
          name="tags"
          placeholder="Tags"
          closeMenuOnSelect={false}
          required={isTagRequired ? isTagRequired : false}
          labelStyle={FieldLabel}
          options={tagList}
        />

        <SectionTitle label={label} />

        <div className="d-flex gap-2 align-items-center mb-2">
          <RadioButton
            size="small"
            value="RichText"
            handleChange={handleRadio}
            checked={!isChecked}
            label="Use Editor"
          />

          <RadioButton
            size="small"
            value="Upload"
            handleChange={handleRadio}
            checked={isChecked}
            label="Upload HTML File"
          />
        </div>

        {!showRichText ? (
          <>
            <LabelComponent>Upload HTML file</LabelComponent>
            <div className="m-1 mt-2">
              <Message
                label="Drag or upload the content file from your computer"
                className="text-color-secondary"
              />
            </div>
            <div className="d-block justify-content-between m-1">
              <input
                name="file"
                className="form-control d-none"
                type="file"
                id="file"
                onChange={(e) => {
                  handleFileChosen(e);
                  e.target.value = null;
                }}
                accept=".html, .txt"
              />
              <span className="d-block">
                <label htmlFor="file" className="profile-img-sm mb-0">
                  <span className="btn btn-danger contentForm">
                    <VectorFileUpload /> Upload
                  </span>
                </label>
              </span>

              <Vector />
              <div className="d-flex align-items-center my-1 cursor-pointer">
                <LabelComponent className="text-truncate my-0 fw-400">
                  {fileName}
                </LabelComponent>
                {fileName && (
                  <span
                    className="mx-1"
                    onClick={() => {
                      handleFileRemove();
                    }}>
                    <Xcross />{" "}
                  </span>
                )}
              </div>
            </div>
          </>
        ) : (
          <RichText value={content} handleChange={handleEditor} />
        )}

        {isEditPage && (
          <div className="d-flex">
            <SubmitButton
              label={buttonLabel}
              disabled={buttonDisable}
              className="submit-button"
            />
            <CancelButton
              onClick={() => {
                setEditContent(false);
                setIsOpen(true);
              }}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default ContentForm;
