import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// Components
import Breadcrumb from "../../components/Breadcrumb";
import AssignToCampaign from "./components/AssignToCampaign";
import AvatarCard from "../../components/AvatarCard";
import GeneralInformation from "./components/GeneralInformation";
import ContactInformation from "./components/ContactInformation";
import CorrespondenceHistory from "./components/CorrespondenceHistory";
import ActionButton from "./components/ActionButton";
import TopNav from "./components/ContactDetailTabs";
import Form from "../../components/Form";
import Spinner from "../../components/Spinner";
import Toast from "../../components/Toast";

// API
import ContactAPI from "../../api/ContactAPI";
import campaignAPI from "../../api/Campaign";

// Assets
import saveIcon from "../../assets/img/save-icon.png";

// Lib
import { formatDate } from "../../lib/DateTime";
import PhoneNumber from "../../lib/PhoneNumber";
import String from "../../lib/String";
import SubmitButton from "../../components/SubmitButton";
import DiscardButton from "../../components/DiscardButton";
import { GetDisplayName } from "../../helpers/Contact";
import tag from "../../api/TagAPI";

const ContantDetails = () => {
  // States
  const [modalState, setModalState] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignIds, setCampaignIds] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [tagsList, setTagsList] = useState([]);
  const [isContactEditable, setIsContactEditable] = useState(false);
  const [contactDetail, setContactDetail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSaveButtonEnable, setIsSaveButtonEnable] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [selectedStateValue, setSelectedStateValue] = useState([]);
  const [selectedCountryValue, setSelectedCountryValue] = useState([]);
  const [enableReinitialize, setEnableReinitialize] = useState(false);
  const [phone1Type, setPhone1Type] = useState();
  const [phone2Type, setPhone2Type] = useState();
  const [tagList, setTagList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  // Navigate
  const navigate = useNavigate();

  // ContactId is required for the validation(edit contact, update contact)
  const contactId = window.location.pathname.split("/")[3]
    ? window.location.pathname.split("/")[3]
    : "";

  // The contactDetail array/object is empty, to make api call and fill the array/object
  useEffect(() => {
    getContactDetails();
    getTag();
  }, []);

  useEffect(() => {
    getCountryList();
  }, [contactDetail]);

  useEffect(() => {
    getStateList();
  }, [countryList]);

  useEffect(() => {
    getStateList();
  }, [selectedCountryValue]);

  const getTag = () => {
    tag.getContact((tags) => {
      if (tags && tags.length > 0) {
        setTagsList(tags);
      }
    });
  };
  // Get the Country List
  const getCountryList = () => {
    try {
      let countryList = new Array();
      ContactAPI.getCountryList((response) => {
        if (response) {
          for (const property in response) {
            countryList.push({ label: response[property], value: property });

            if (contactDetail.country == property) {
              setEnableReinitialize(true);
              setSelectedCountryValue({
                label: response[property],
                value: property,
              });
              setEnableReinitialize(false);
            }
          }
        }
        setCountryList(countryList);
      });
    } catch (err) {
      console.log(err);
    }
  };

  // Get the State List
  const getStateList = () => {
    try {
      let stateList = new Array();
      ContactAPI.getStateList(selectedCountryValue.value, (response) => {
        if (response) {
          for (const property in response) {
            stateList.push({
              label: response[property],
              value: response[property],
            });
            if (contactDetail.state == response[property]) {
              setSelectedStateValue({
                label: response[property],
                value: response[property],
              });
            }
          }
        }
        setStateList(stateList);
      });
    } catch (err) {
      console.log(err);
    }
  };

  let tags = [];
  // To get the contact details
  const getContactDetails = () => {
    setIsLoading(true);
    ContactAPI.getContactDetails(contactId, (response) => {
      setIsLoading(false);
      if (response) {
        let contactDetail = response?.contact;
        setContactDetail(contactDetail);
        setSelectedCountryValue(contactDetail ? contactDetail?.country : "USA");
        setSelectedStateValue(contactDetail ? contactDetail?.state : "");
        setPhone1Type({
          label: String.Capitalise(contactDetail?.phone1Type),
          value: contactDetail?.phone1Type,
        });
        setPhone2Type({
          label: String.Capitalise(contactDetail?.phone2Type),
          value: contactDetail?.phone2Type,
        });
        contactDetail?.tags.forEach((tag) => {
          tags.push({ label: tag, value: tag });
        });
        setTagList(tags);
      }
    });
  };

  const handleOnchange = () => {
    setEnableReinitialize(false);
    setIsSaveButtonEnable(false);
  };

  // Contact detail Delete function
  const handleDelete = () => {
    setIsDisabled(true);
    ContactAPI.deleteContactList(contactId, (res) => {
      if (res) {
        Toast.success("Contact deleted successfully");
        navigate("/contacts");
      }
    });
  };

  // Toggle modal state open/close
  const toggle = () => {
    setModalState(!modalState);
    getCampaignList();
  };

  // Get the Campaign List
  const getCampaignList = async () => {
    await campaignAPI.getProgramList("", (res) => {
      setCampaignList(res);
    });
  };

  // To handle checkbox change
  const handleChange = (e) => {
    let selectedValue = e.target.id;

    if (e.target.checked) {
      let id = e.target.id;
      setCampaignIds((data) => [...data, id && id]);
    } else {
      setCampaignIds(() => {
        // Remove the values form the array after uncheck
        let remainingValues =
          campaignIds.length > 0 &&
          campaignIds.filter((id) => id !== selectedValue);
        setCampaignIds(remainingValues);
      });
    }
    setIsChecked(!isChecked);
  };

  // Handle search
  const handleSearch = async (e) => {
    let searchTerm = String.Get(e.target.value);
    await campaignAPI.searchProgram(searchTerm, (res) => {
      setCampaignList(res);
    });
  };

  // The contactDetail array/object is empty, to make api call and fill the array/object
  useEffect(() => {
    getCampaignList();
    if (contactDetail && contactDetail.length === 0) getContactDetails();
  }, []);

  // Breadcrumb list
  const bradcrumbList = [
    { label: "Contacts", link: "/contacts" },
    {
      label: GetDisplayName(
        contactDetail?.firstName,
        contactDetail?.lastName,
        contactDetail?.email
      ),
      link: "",
    },
  ];

  // Update the Contact
  const UpdateContact = (data) => {
    setIsSaveButtonEnable(true);
    if (data) {
      ContactAPI.updateContact(contactId, toArray(data), (response) => {
        if (response?.applicationErrorCode || response?.code) {
          setIsSaveButtonEnable(false);
          toast.error(response.message);
        } else if (response?.contactId === contactId) {
          setEnableReinitialize(true);
          getContactDetails();
          setIsContactEditable(false);
          setIsSaveButtonEnable(false);
          toast.success("Contact Updated Successfully");
        }
      });
    }
  };

  // to validate submitted values
  const toArray = (value) => {
    const data = {};
    let taglist = [];
    data.title = String.Get(value.title);
    data.firstName = String.Get(value.firstName);
    data.lastName = String.Get(value.lastName);
    data.middleName = String.Get(value.middleName);
    data.email = String.Get(value.email);
    data.address1 = String.Get(value.address1);
    data.address2 = String.Get(value.address2);
    data.city = String.Get(value.city);
    data.state = String.Get(value.state.value);

    value.tags.forEach((tag) => {
      taglist.push(tag.value);
    });

    if (value.country.value) {
      data.country = value.country.value ? value.country.value : "";
    } else {
      data.country = "USA";
    }

    data.postalCode = String.Get(value.postalCode);
    data.phone1 = PhoneNumber.Get(value.phone1);
    if (phone1Type.value !== null) {
      data.phone1Type = phone1Type.value;
    }
    data.phone2 = PhoneNumber.Get(value.phone2);

    if (phone2Type.value !== null) {
      data.phone2Type = phone2Type.value;
    }

    data.notes = String.Get(value.notes);
    data.companyName = String.Get(value.companyName);
    return data;
  };

  // Discard Model
  const DiscardModalOpen = () => {
    setPhone1Type("");
    setPhone2Type("");
    setIsContactEditable(false);
    setEnableReinitialize(true);
    getContactDetails();
  };

  // Edit Action
  const EditContact = () => {
    setEnableReinitialize(true);
    setIsContactEditable(true);
    getContactDetails();
  };

  // Handle onchange
  const handleStatechange = (data) => {
    if (data && data.value) {
      setSelectedStateValue(data.value);
    }
    setIsSaveButtonEnable(false);
  };

  //handlephone1 type
  const handlePhone1Type = (data) => {
    setPhone1Type(data.value);
    setIsSaveButtonEnable(false);
  };

  //handlePhone2Type
  const handlePhone2Type = (data) => {
    setPhone2Type(data.value);
    setIsSaveButtonEnable(false);
  };
  // Handle the Country
  const handleCountryChange = (data) => {
    if (data && data.value) {
      setSelectedCountryValue(data.value);
      setSelectedStateValue("");
    }
  };

  const handleZipCodevalue = (data) => {
    if (parseInt(data.target.value)) {
      setIsSaveButtonEnable(false);
    }
  };

  // Discard Campaign
  const discardCampaign = () => {
    toggle();
    setCampaignIds([]);
  };

  let subscriptionData =
    contactDetail?.contactSubscriptionData?.subscriptionMap;
  let subscribedCampaignIds = subscriptionData && Object.keys(subscriptionData);

  return (
    <>
      {/* BreadCrumb */}
      <Breadcrumb list={bradcrumbList} />

      {/* Assign to campaign */}
      <AssignToCampaign
        isOpen={modalState}
        toggle={toggle}
        campaignList={campaignList}
        contactId={contactId}
        handleChange={handleChange}
        handleSearch={handleSearch}
        campaignIds={campaignIds}
        discardCampaign={discardCampaign}
        subscriptionData={subscriptionData}
        subscribedCampaignId={subscribedCampaignIds}
        getContactDetails={getContactDetails}
        setCampaignIds={setCampaignIds}
      />

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {/* Form */}
          {contactDetail && (
            <Form
              enableReinitialize={enableReinitialize}
              initialValues={{
                firstName: contactDetail.firstName,
                middleName: contactDetail.middleName,
                lastName: contactDetail.lastName,
                title: contactDetail.title,
                phone1: contactDetail.phone1,
                phone2: contactDetail.phone2,
                phone1Type: !isContactEditable ? phone1Type?.label : phone1Type,
                phone2Type: !isContactEditable ? phone2Type?.label : phone2Type,
                email: contactDetail.email,
                companyName: contactDetail.companyName,
                address1: contactDetail.address1,
                address2: contactDetail.address2,
                city: contactDetail.city,
                tags: !isContactEditable ? contactDetail.tags : tagList,
                state: !isContactEditable
                  ? contactDetail.state
                  : selectedStateValue,
                postalCode: contactDetail.postalCode,
                country: !isContactEditable
                  ? selectedCountryValue?.label
                  : selectedCountryValue,
                notes: contactDetail.notes,
              }}
              onSubmit={(value) => {
                UpdateContact(value);
              }}>
              {/* Header */}
              <div className="row bg-white-smoke">
                {/* div to provide padding for avatar card */}
                <div className="py-4">
                  <AvatarCard
                    square={true}
                    firstName={contactDetail?.firstName}
                    lastName={contactDetail?.lastName}
                    email={contactDetail?.email}
                    showBackArrow
                    fontBolded
                    handleOnClick={() => {
                      setIsContactEditable(false);
                      getContactDetails();
                    }}
                    backUrl={
                      isContactEditable
                        ? `/contact/detail/${contactDetail.contactId}`
                        : `/contacts`
                    }
                  />
                </div>

                {/* Left side section */}
                {/* div to define col space for left side section */}
                <div className="col-md-4 g-0">
                  {/* General information */}
                  <GeneralInformation
                    dateAdded={formatDate(contactDetail.dateAdded)}
                    lastIntraction={formatDate(contactDetail.lastUpdated)}
                  />

                  {/* Contact Information */}
                  <ContactInformation
                    handleCountryChange={handleCountryChange}
                    countryList={countryList}
                    stateList={stateList}
                    handleStateChange={(e) => handleStatechange(e)}
                    handlePhone1Type={handlePhone1Type}
                    handlePhone2Type={handlePhone2Type}
                    disabled={!isContactEditable}
                    onChange={handleOnchange}
                    selectedStateValue={selectedStateValue}
                    handleZipCodevalue={handleZipCodevalue}
                    selectedCountryValue={selectedCountryValue}
                    phone2Type={phone2Type}
                    phone1Type={phone1Type}
                    tagList={tagList}
                    tagOptions={tagsList}
                  />

                  {/* Correspondence History */}
                  <CorrespondenceHistory
                    data={contactDetail?.lastCommunications}
                  />

                  {/* Button Group */}
                  {isContactEditable && (
                    <div className="d-flex">
                      <SubmitButton
                        label="Save"
                        disabled={isSaveButtonEnable}
                        icon={saveIcon}
                        className="save-button"
                      />
                      <DiscardButton
                        label="Discard"
                        onClick={() => DiscardModalOpen()}
                        className="discard-button"
                      />
                    </div>
                  )}

                  {/* Edit, delete and Assign campaign buttons */}
                  {!isContactEditable && (
                    <ActionButton
                      contactId={contactId}
                      EditContact={EditContact}
                      handleDelete={handleDelete}
                      toggleCampaignModel={toggle}
                      disabled={isDisabled}
                    />
                  )}
                </div>

                {/* div to define col space for Contact Detail Tab section */}
                <div className="col-8 g-0 d-sm-none d-lg-block d-md-block d-none">
                  {/* Tabs sections */}
                  <div className="bg-color-secondary">
                    <div className="col-6">
                      <TopNav />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </>
      )}
    </>
  );
};

function mapStateToProps(state) {
  return state.table;
}

export default connect(mapStateToProps)(ContantDetails);
