import React from "react";
// eslint-disable-next-line no-unused-vars
import _ from "lodash";

const GeneralInformation = ({ dateAdded, lastIntraction }) => {
  return (
    <>
      {/* div to mention the overall bg-color and padding for the content */}
      <div className="p-2 bg-white">
        {/* Page title */}
        <p className="generalInformation-section">
          General Information
        </p>

        {/* div to specify padding for the dated section */}
        <section className="p-2">
          {/* Added dated */}
          {/* div to make the contents in a single line */}
          <div
            className="generalInformation-added d-flex justify-content-between"
          >
            <p className="my-1">Added dated</p>
            <p className="my-1">{dateAdded}</p>
          </div>
          <hr className="p-0 m-0" />

          {/* Last intraction */}
          {/* div to make the contents in a single line */}
          <div
            className="generalInformation-added mt-1 d-flex justify-content-between"
          >
            <p className="my-1">Last intraction</p>
            <p className="my-1">{lastIntraction}</p>
          </div>
          <hr className="p-0 m-0" />
          <hr className="p-0 m-0" />
        </section>
      </div>
    </>
  );
};

export default GeneralInformation;
