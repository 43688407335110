// Cookies
export const COOKIE = {
  CUSTOMER_ID: "customerId",
  ACCESS_TOKEN: "access_token",
  REFRESH_TOKEN: "refresh_token",
  USER_EMAIL: "user_email",
  COOKIE_CUSTOMER_ID: "customerId",
  COOKIE_ACCESS_TOKEN: "access_token",
  COOKIE_REFRESH_TOKEN: "refresh_token",
  COOKIE_USER_EMAIL: "user_email",
};

// Cookies
export const COOKIE_CUSTOMER_ID = "customerId";
export const COOKIE_ACCESS_TOKEN = "access_token";
export const COOKIE_REFRESH_TOKEN = "refresh_token";
export const COOKIE_USER_EMAIL = "user_email";
