import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";

//Styles
import "./Style.scss";

class LabelComponent extends React.Component {
  render() {
    const {
      id,
      children,
      required,
      fontBolded,
      style,
      className,
      fontWeight,
    } = this.props;

    return (
      <Label
        id={id || children}
        for={id}
        className={className ? className : `${fontBolded ? "fw-bold" : ""}`}
        style={
          style
            ? style
            : {
              fontWeight: fontWeight ? fontWeight : "bold",
            }
        }
      >
        {children}
        {required && <span className="text-danger ml-1">*</span>}
      </Label>
    );
  }
}

LabelComponent.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default LabelComponent;
