import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

//components
import Avatar from "../../../components/Avatar";
import NoRecordsFound from "../../../components/NoRecordsFound";
import Search from "../../../components/Search";
import AssignButton from "../../../components/AssignButton";
import Toast from "../../../components/Toast";

//constant
import DiscardButton from "../../../components/DiscardButton";

// API
import ContactAPI from "../../../api/ContactAPI";
import CampaignAPI from "../../../api/Campaign";

const AssignToCampaign = (props) => {
  // Props
  const {
    id,
    isOpen,
    toggle,
    campaignList,
    contactId,
    handleChange,
    handleSearch,
    campaignIds,
    selectedContactList,
    discardCampaign,
    page,
    selectedContactDetail,
    subscriptionData,
    subscribedCampaignId,
    getContactDetails,
    setSelectedContactList,
    setCampaignIds,
    search,
  } = props;
  // States
  const [filteredList, setFilteredList] = useState([]);

  // Defining body
  const body = {
    setByContact: true,
    unsubscribeAll: false,
    userCanModify: true,
    subscriptionMap: {},
  };

  useEffect(() => {
    getFilteredCampaignList();
  }, [isOpen]);

  useEffect(() => {
    getFilteredCampaignList();
  }, [campaignList && campaignList.length]);

  //assign campaign function
  const assignCampaign = async () => {
    if (contactId) {
      campaignIds.forEach((campaignId) => {
        body.subscriptionMap[campaignId] = "SUBSCRIBED";
      });

      ContactAPI.assignToContact(contactId, body, (res) => {
        if (res.contactId == contactId) {
          Toast.success("Campaign assigned to contact successfully");
          getContactDetails();
          toggle();
          setCampaignIds([]);
        }
      });
    }


    // Assigning campaign to contact
    if (
      selectedContactList &&
      selectedContactList.length > 0 &&
      campaignIds &&
      campaignIds.length > 0 
    ) {
      for (let i = 0; i < campaignIds.length > 0; i++) {
        let body = {
          contactIds: selectedContactList,
          subscription: "SUBSCRIBED",
        };
        CampaignAPI.assignContact(
          campaignIds[i],
          body,
          (res, status) => {
            if(i == campaignIds.length - 1) {
              if (status && status == 200) {
                getContactDetails();
                toggle();
              }
            }
          }
        );
      }
      Toast.success("Campaign assigned to contact successfully");
      setSelectedContactList && setSelectedContactList([]);
    }
  };

  
  // To fiter out the SUBSCRIBED campaign from the list
  function filterCampaigns(subscribedCampaignIds) {
    if(subscribedCampaignIds && subscribedCampaignIds.length > 0){
      return  Array.isArray(campaignList)&& campaignList?.filter((list) => {
        return subscribedCampaignIds.indexOf(list.campaign.campaignId) == -1 ;
      });
    }
  }
  
  // Get filtered campaign list
  const getFilteredCampaignList = () => {
    let list = [];
    let subscribeData;
  
    // To get the subscription data form list page
    if (page == "contactList") {
      subscribeData = selectedContactDetail?.campaignSubscriptions;
    } else {
      subscribeData = subscriptionData;
    }

  
    list = filterCampaigns(subscribeData);
    if (selectedContactList?.length > 1) {
      setFilteredList(campaignList);
    } else {
      setFilteredList(list);
    }
  };

  return (
    <>
      <Modal
        id={id}
        isOpen={isOpen}
        toggle={toggle}
        centered
        className="position-relative"
        size="md"
        scrollable={true}
        backdrop="static">
        {/* Header */}
        <ModalHeader className="bg-color-primary">
          <span className="fw-800 text-white">Select a Campaign</span>
        </ModalHeader>

        {/* Body */}
        <ModalBody className="px-0">
          <p className="px-2 fw-400 text-dark-gray">
            Select a campaign of more form the list below
          </p>
          {/* Search programs */}
          <Search
            classnames="page-search gx-0"
            placeholder="Search campaigns"
            onChange={handleSearch}
          />

          {/* Campaign List */}
          {filteredList && filteredList.length > 0 ? (
            filteredList?.map((data) => (
              <div className="assignToCampaign">
                <div
                  className="assignToCampaign-section d-flex mx-3 align-items-center gap-1"
                >
                  <label className="checkbox">
                    <input
                      id={data.campaign?.campaignId}
                      name={
                        data.campaign.firstName +
                        " " +
                        data.campaign.lastName
                      }
                      type="checkbox"
                      value={
                        data.campaign.firstName +
                        " " +
                        data.campaign.lastName
                      }
                      onClick={(e) => handleChange(e)}
                      checked={campaignIds.includes(data.campaign?.campaignId)}

                    />
                    <span className="checkmark"></span>
                  </label>
                  <div
                    className="assignContact-avatar">
                    {/* Showing Campaign avatar */}
                    <Avatar
                      firstName={data.campaign.name}
                      bgPrimary
                      size="sm"
                    />
                  </div>

                  {/* Displaying Campaign Name, Subscriber count and content count */}
                  {data.campaign.name && (
                    <div
                      className="assignToCampaign-data d-flex flex-column "
                    >
                      {/* Campaign Name */}
                      {data.campaign.name && (
                        
                        <div
                          className="assignToCampaign-data-name fw-700"
                        >
                          {data.campaign.name}
                        </div>
                      )}

                      {/* Subscriber count */}
                      {data.contactList && (
                        <span
                          className="assignToCampaign-data-contactList text-color-secondary"
                        >
                          {data.contactList?.length}{" "}
                          {data.contactList?.length > 1
                            ? "Subscribers"
                            : "Subscriber"}
                        </span>
                      )}
                      {/* Content count */}
                      {data.contentList && (
                        <span
                          className="assignToCampaign-data-contactList text-color-secondary"
                        >
                          {data.contentList?.length}{" "}
                          {data.contentList?.length > 1
                            ? "Contents"
                            : "Content"}
                        </span>
                      )}
                    </div>
                  )}
                  {/* </div> */}
                </div>
              </div>
            ))
          ) 
            : search ? (
              <NoRecordsFound message="No campaign found" hideCard />) 
              : (
                <NoRecordsFound message="No campaign added yet" hideCard />
              )  
          }
        </ModalBody>

        <ModalFooter className="row gx-0 p-0">
          <div className="row gx-0 m-0">
            <div className="col-6">
              <AssignButton
                label="Assign"
                fullWidth
                noRadius
                onClick={assignCampaign}
                isChecked={campaignIds.length > 0}
              />
            </div>

            <div className="col-6">
              <DiscardButton noRadius onClick={discardCampaign} />
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AssignToCampaign;
