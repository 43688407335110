import React, { Component } from "react";

//Styles
import "./Style.scss";

export default class Logo extends Component {
  render() {
    // Logo props
    const { src, className, link, altText, label, width } = this.props;
    return (
      <>
        {src === "" || src === undefined ? (
          <a href="/dashboard" className="text-decoration-none h3" >
            {label}
          </a>
        ) : (
          <a href={link}>
            <img
              src={src}
              className={className}
              width={width ? width : "140"}
              alt={altText}
            />
          </a>
        )}
      </>
    );
  }
}
