import React, {useState} from "react";
import classNames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";

//Constant
import { Constants } from "../../../constants/Contact";

import  Engagement  from "../../../assets/icons/engagement.svg";
import OverviewIcon  from "../../../assets/icons/icon-over-view.svg";
import Campaign  from "../../../assets/icons/icon-contact-campaign.svg";


const TopNav = () => {
  const [activeTab, setActiveTab]= useState(Constants.CONTACT_OVER_VIEW_TAB);
    
  const toggle = (tab) => {
    setActiveTab(tab);
  };

  // tab style
  const tabStyle = {
    padding: "1px",
    margin: "1px",
    width: "max-content",
  };
 
  return (
    <>
      <Nav tabs className="admin-tabs row">
        <div className="col-4" >
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === (Constants.CONTACT_OVER_VIEW_TAB),
              })}
              onClick={() => {
                toggle(Constants.CONTACT_OVER_VIEW_TAB);
              }} 
              style={tabStyle}           
            >
              <span className="top-nav-text">
                <img src={OverviewIcon}></img>
                {Constants.CONTACT_OVER_VIEW_TAB}</span>
            </NavLink>
          </NavItem>
        </div>
        <div className="col-4" >
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === (Constants.CONTACT_ENGAGEMENT_TAB)
              })}
              onClick={() => {
                toggle(Constants.CONTACT_ENGAGEMENT_TAB);
              }}
              style={tabStyle}
            >
              <span className="top-nav-text">
                <img src={Engagement}></img>
                {Constants.CONTACT_ENGAGEMENT_TAB}</span>
            </NavLink>
          </NavItem>
        </div>
        <div className="col-4">
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === (Constants.CONTACT_CAMPAIGN_TAB)
              })}
              onClick={() => {
                toggle(Constants.CONTACT_CAMPAIGN_TAB);
              }}
              style={tabStyle}
            >
              <span className="top-nav-text">
                <img src={Campaign}></img>
                {Constants.CONTACT_CAMPAIGN_TAB}</span>
            </NavLink>
          </NavItem>
        </div>
      </Nav>
    </>
  );
};
export default TopNav;
