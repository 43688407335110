//user status
export const UserStatus = {
  Pending: "PENDING",
  Approved: "APPROVED",
  Active: "ACTIVE"
};

// Permission level options
export const PermissinonLevelOptions = [
  {
    label: "Admin",
    value: "ADMIN"
  },
  {
    label: "User",
    value: "USER"
  }
];

export const UserStatusOptions = [
  {
    label: "Pending",
    value: "PENDING"
  },
  {
    label: "Approved",
    value: "APPROVED"
  },
  {
    label: "Active",
    value: "ACTIVE"
  }
];