import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import _ from "lodash";

// Components
import PageTitle from "../../components/PageTitle";
import DeleteModal from "../../components/ConfirmationModal";
import NoRecordsFound from "../../components/NoRecordsFound";
import Search from "../../components/Search";
import Avatar from "../../components/Avatar";
import Spinner from "../../components/Spinner";
import Toast from "../../components/Toast";

// Assets
import { arrowRightBlue } from "../../assets/icons/index";
import { DeleteIcon } from "../../assets/icons";
import { DeleteIconRed } from "../../assets/icons";

// API
import API from "../../api/User";

//Helper
import { GetDisplayName } from "../../helpers/Contact";

//Lib
import String from "../../lib/String";
import { HTTP_STATUS } from "../../lib/http";
import ArrayList from "../../lib/ArrayList";
import LinkTo from "../../components/LinkTo";

const UserManagement = () => {
  const [userData, setUserData] = useState([]);
  const [userIds, setUserIds] = useState([]);
  const [deleteModel, setDeleteModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const navigate = useNavigate();

  // Fetch user data
  const fetchData = async () => {
    setIsLoading(true);
    API.getUserList((res) => {
      setUserData(res);
      setIsLoading(false);
    });
  };

  // Toggle delete model
  const toggle = () => {
    setDeleteModel(!deleteModel);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Handle check box changes
  const handleCheckboxChange = (e) => {
    let selectedUserId = e.target.id;

    // Validating if the checkbox is checked
    if (e.target.checked) {
      setUserIds((data) => [...data, selectedUserId && selectedUserId]);
      setDisabled(false);
    } else {
      setUserIds(() => {
        // Remove the values form the array after uncheck
        let remainingValues =
          userIds.length > 0 && userIds.filter((id) => id !== selectedUserId);
        setUserIds(remainingValues);
        setDisabled(true);
      });
    }
  };

  // Delete user function
  const handleDelete = () => {
    if (userIds && userIds.length > 0) {
      for (let i = 0; i < userIds.length; i++) {
        setDisabled(true);
        API.deleteUser(userIds[i], (res) => {
          let { response } = res;
          if (i == userIds.length - 1) {
            if (res.status === HTTP_STATUS.RESPONSE_SUCCESS) {
              setUserIds([]);
              fetchData();
              toggle();
              Toast.success("User Deleted Successfully");
            } else if (response.status >= HTTP_STATUS.RESPONSE_ERROR) {
              Toast.error(response?.data?.message);
            }
          }
        });
      }
    }
  };

  // Handle search
  const handleSearch = (searchTerm) => {
    API.searchUser(searchTerm, (res) => {
      setUserData(res);
    });
  };

  const debounceFunction = useCallback(_.debounce(handleSearch, 500), []);

  const handleSearchChange = (e) => {
    debounceFunction(String.Get(e.target.value));
  };

  return (
    <>
      {/* PageTitle */}
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center">
            {/* Search */}
            <div className="w-50">
              <Search
                classnames="page-search"
                placeholder="Find in User"
                onChange={handleSearchChange}
                borderClassName={"setting-page"}
                pageName={"setting-page"}
              />
            </div>

            <div className="d-flex justify-content-end">
              <PageTitle
                showButton
                buttonLabel="Add new user"
                targetUrl="/user/add"
              />

              {/* Delete User Link */}
              <div
                className="d-flex mx-1 p-3"
                onClick={(e) =>
                  userIds && userIds?.length > 0 ? toggle(e) : null
                }
                style={{
                  cursor: disabled
                    ? ArrayList.isNotEmpty(userIds)
                      ? "pointer"
                      : "not-allowed"
                    : "pointer",
                }}>
                <LinkTo
                  icon={
                    userIds?.length > 0 ? <DeleteIconRed /> : <DeleteIcon />
                  }
                  className="section-button mt-1"
                />
                <small
                  className={`d-flex mt-2 ${
                    ArrayList.isNotEmpty(userIds)
                      ? "text-true-blue"
                      : "text-dark-gray"
                  }`}>
                  Delete User
                </small>
              </div>
            </div>
          </div>

          {/* Delete Model */}
          <DeleteModal
            title="Delete User"
            primaryButtonText="Delete user"
            secondaryButtonText="Cancel"
            description="Would you like to delete the user?"
            isOpen={deleteModel}
            toggle={toggle}
            onClickNo={toggle}
            onClickYes={handleDelete}
            disabled={disabled}
          />

          {/* Table */}
          <div className="mx-1">
            <div className="table-header">
              <div className="row py-1">
                <div className="col-6 col-lg-5 col-sm-5 col-md-5 user-list-name">
                  Name
                </div>
                <div className="col-2 col-sm-3 col-md-3 col-lg-3">Title</div>
                <div className="col-4 col-sm-4 col-md-4 col-lg-4">
                  Email address
                </div>
              </div>
            </div>

            {userData && userData?.length ? (
              userData.map((data) => (
                <div
                  className="card contact-card mt-2 cursor-pointer"
                  onClick={() => navigate(`/user/edit/${data.userId}`)}>
                  <div className="row p-2 align-items-center cursor-pointer">
                    <div className="col-6 col-lg-5 col-sm-5 col-md-5">
                      <div className="row d-flex align-items-center justify-content-around">
                        <div
                          className="userlist-checkbox col-lg-1 col-sm-1 col-md-1 col-1 "
                          onClick={(event) => event.stopPropagation()}>
                          <input
                            id={data?.userId}
                            type="checkbox"
                            onClick={(e) => handleCheckboxChange(e)}
                            checked={
                              userIds ? userIds.includes(data?.userId) : false
                            }
                          />
                        </div>

                        <div className="userlist-avatar col-lg-2 col-sm-2 col-md-2 col-2">
                          <Avatar
                            square
                            firstName={data?.firstName ? data.firstName : ""}
                            lastName={data?.lastName ? data.lastName : ""}
                          />
                        </div>
                        <div className="col-lg-8 col-sm-8 col-md-8 col-8 userlist-name">
                          {GetDisplayName(data?.firstName, data.lastName)}
                        </div>
                      </div>
                    </div>

                    <div className="col-2 col-lg-3 col-md-3 col-sm-3">
                      {data?.permissionLevel}
                    </div>

                    <div className="col-3 col-lg-3 col-md-3 col-sm-3">
                      {data?.email ? data.email : ""}
                    </div>

                    <div
                      className="col-1 col-lg-1 col-sm-1 col-md-1 d-flex justify-content-end"
                      onClick={() => {}}>
                      <span>
                        <img
                          src={arrowRightBlue}
                          width={"6.59px"}
                          height={"11.17px"}
                        />
                      </span>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <NoRecordsFound message="No Users Found" hideCard />
            )}
          </div>
        </>
      )}
    </>
  );
};

export default UserManagement;
