import React from "react";
import PropTypes from "prop-types";
import { EditIconAlt } from "../../assets/icons/index";
import String from "../../lib/String";

class Avatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  // Resize fucntion

  handleResizedScreen = () => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  };


  componentDidMount() {
    window.addEventListener("resize", this.handleResizedScreen);
  }

  render() {
    const {
      id,
      url,
      email,
      companyName,
      defaultUrl,
      size,
      bgSecondary,
      bgPrimary,
      square,
      customSize,
      imageSize,
      className,
      customStyle,
      allowEdit,
      textColor,
      customCss,
      borderRadius
    } = this.props;
   
    const firstName = this.props.firstName && String.Get(this.props.firstName);
    const lastName = this.props.lastName && String.Get(this.props.lastName);
    
    const screenWidth = this.state.width;

    // Defining the values for the various sizes
    const sizes = {
      // xs: screenWidth > 340 ? 25 : 20,
      sm: screenWidth > 750 ? 35 : screenWidth < 400 ? 30 : 30 ,
      // md: screenWidth > 800 ? 40 : 50,
      // lg: screenWidth > 1000 ? 60 : 90 ,
      customSize: parseInt(imageSize, 10),
    };

    // Defined the dimension
    let dimension;
    // Assigning customSize value to Dimension
    if (customSize) {
      dimension = customSize;
    }
    // Assigning the value for dimensions with respect to sizes's value.
    if (!dimension && size) {
      dimension = sizes[size];
    }
    // Assigning the value for dimension with respect to the size 'sm'.
    if (!dimension) {
      dimension = sizes.sm;
    }
    // Defining the default style
    const defaultStyle = {
      width: dimension,
      height: dimension,
      display: "block",
      borderRadius: borderRadius ? borderRadius : square ? "4px" : "50%",
    };
  

    if (url) {
      // defined the background image and assigning the url values to it.
      let backgroundImage = `url(${url})`;
      // Assigning the values to backgroundImage if the defaultUrl value exists.
      if (defaultUrl) {
        backgroundImage = `${backgroundImage}, url(${defaultUrl})`;
      }
      // Defining the style if url exists
      const style = Object.assign({}, defaultStyle, {
        backgroundSize: "cover",
        backgroundRepeat: "initial",
        backgroundImage,
        backgroundPosition: "center",
      });

      return (
        <>
          <div id={id} style={style} className={"cursor-pointer"} />
          <div>
            {allowEdit === true && (
              <span
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  padding: "10px",
                  borderRadius: "8px",
                  color: textColor || "#ffffff",
                }}
                className="pt-5 cursor-pointer">
                <EditIconAlt style={{ verticalAlign: "bottom" }} />
              </span>
            )}
          </div>
        </>
      );
    }




    if (firstName || lastName || companyName || email) {
      // Defining a constant variable as initial
      const initial = [];
      // Appending the first name into the initial
      if (firstName) {
        initial.push(firstName[0]);
      }
      // Appending the last name into the initial
      if (lastName) {
        initial.push(lastName[0]);
      }
      // Appending the company name into the initial
      if (companyName) {
        initial.push(companyName[0]);
      }
      // Appending the email into the initial without first name, last name and company name
      if (email && !firstName && !lastName && !companyName) {
        initial.push(email[0]);
      }
      // Appending the first name if length of initial is equal to 1
      if (initial.length === 1 && firstName) {
        initial.push(firstName[1]);
      }
      // Appending the last name if length of initial is equal to 1
      if (initial.length === 1 && lastName) {
        initial.push(lastName[1]);
      }
      // Appending the company name if length of initial is equal to 1
      if (initial.length === 1 && companyName) {
        initial.push(companyName[1]);
      }
      // Appending the email if length of initial is equal to 1
      if (initial.length === 1 && email) {
        initial.push(email[1]);
      }

      // Defining the style if either of the first name, last name, company name, email exists.
      const style = Object.assign({}, defaultStyle, {
        lineHeight: `${dimension}px`,
        backgroundColor: bgPrimary ? "#1b3165" : bgSecondary ? "#707B96" : "#093051",
        color: textColor || "#ffffff",
        textTransform: "uppercase",
        textAlign: "center",
        letterSpacing: 1,
      });
      return (
        <>
          <div
            id={id}
            className={`${customStyle} ${className}`}
            style={customCss || style}>
            <div>{initial.join("")}</div>
          </div>
          <div>
            {allowEdit === true ? (
              <span
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  padding: "10px",
                  borderRadius: "8px",
                  color: textColor || "#ffffff",
                }}
                className="pt-5 cursor-pointer">
                <EditIconAlt style={{ verticalAlign: "bottom" }} />
              </span>
            ) : (
              ""
            )}
          </div>
        </>
      );
    }

    // defining the style if defaultUrl is exist
    if (defaultUrl) {
      const style = Object.assign({}, defaultStyle, {
        backgroundSize: "cover",
        backgroundImage: `url(${defaultUrl})`,
        margin: "auto",
      });

      return <div id={id} style={style} />;
    }

    // defining the style
    const style = Object.assign({}, defaultStyle, {
      backgroundSize: "cover",
      backgroundRepeat: "initial",
      backgroundPosition: "center",
      backgroundColor: bgPrimary ? "#1b3165" : bgSecondary ? "#707B96" : "#093051",
    });

    return (
      <div
        id={id}
        className={className}
        style={customCss ? customCss : style}
      />
    );
  }
}

Avatar.propTypes = {
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  defaultUrl: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  square: PropTypes.bool,
  customSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bgColor: PropTypes.string,
};

export default Avatar;
