import PropTypes from "prop-types";
import React from "react";

//Styles
import "./Style.scss";

class Paragraph extends React.Component {
  render() {
    const { text, id, align, className, color} =
      this.props;
    return (
      <div
        id={id}
        className={`d-flex justify-content-${align ? align : "start"} ${className}`}
        style={{
          color: color ? color : "#707B96",
        }}
      >
        {text}
      </div>
    );
  }
}

Paragraph.propTypes = {
  text: PropTypes.string,
  fontSize: PropTypes.string,
};

export default Paragraph;
