import React from "react";
import { Link } from "react-router-dom";
import NoRecordsFound from "../../../components/NoRecordsFound";
import LabelComponent from "../../../components/Label";
const CorrespondenceHistory = ({ data }) => {

  return (
    <div className="p-3 bg-white">
      <LabelComponent className="correspondence-history"> Correspondence History </LabelComponent>
      <div className="mt-2">
        {data && data.length > 0 ? (
          data.map((data) => {
            return (
              <>
                <div className="correspondenceHistory">
                  <div className="correspondenceHistory-section">
                    <div className="row align-items-center p-2">
                      <div className="col-2">
                        <span
                          className="correspondenceHistory-data bg-color-primary text-white"
                        >
                          {data?.name}
                        </span>
                      </div>
                      <div className="col-6 text-truncate">
                        <span className="pl-0 fw-700">
                          {data?.description}
                        </span>
                        <div
                          className="pl-1 text-color-secondary fw-500"
                        >
                          {formateDateWithTime(data?.date)}
                        </div>
                      </div>
                      <div className="col-4">
                        <span className="d-flex justify-content-end">
                          <Link to={`#`}>
                            <div className="small text-true-blue text-decoration-underline">
                            View
                            </div>
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })
        ) : (
          <NoRecordsFound height={"140px"} message="No History Found" />
        )}
      </div>
    </div>
  );
};
export default CorrespondenceHistory;