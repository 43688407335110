import api from "../lib/Api";
import * as apiUrl from "../helpers/apiUrl";

class Country {

  async  getCountryList(callback) {

    
    try {
      await api.get(apiUrl.country(), "", (res) => {
        callback(res);
      });
    } catch (err) {
      console.log(err);
    }

  }
  
  async  getStateList(country, callback) {
    try {
      if (country) {
        await api.get(`${apiUrl.country()}/${country}`, "", (res) => {
          callback(res);
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

}


const country = new Country();

export default country;