import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import Label from "../Label";
import Hint from "../Hint";

//Styles
import "./Style.scss";

class Email extends React.Component {
  // Validate the Email field
  validate(value) {
    // Email props
    const { label, placeholder, required, error, validationMessage } = this.props;
    // Defining errorMessage
    let errorMessage;
    // Assigning values to inputLabel
    const inputLabel = validationMessage || label || placeholder;
    // Assigning error value to errorMessageLabel
    const errorMessageLabel = error;
    // validate the input value is a valid email
    const isValidEmail =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        value.trim()
      );
    
    // Defining the error validation message when Email field is empty
    if ((!value || !value.trim()) && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    } else if (value && !isValidEmail) {
      errorMessage = inputLabel ? `Invalid ${inputLabel}` : "Invalid";
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, setFieldValue } }) {
    // Email props
    const {
      name,
      id,
      label,
      placeholder,
      error,
      onKeyDown,
      onClick,
      required,
      defaultValue,
      disabled,
      onChange,
      fontBolded,
      hintText,
      outline,
      emailStyle,
      borderBottomColor,
      labelColor,
    } = this.props;

    // Defining the errorMessage
    let errorMessage = touched[name] && errors[name] ? errors[name] : null;
    // Assigning the error value to errorMessage if it exists
    if (error) {
      errorMessage = error;
    }
    // Defining the inputId
    const inputId = id || name;

    // Defining the field Style based on outline
    let fieldStyle;
    if (outline) {
      fieldStyle = {
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        backgroundColor: "transparent",
        borderBottom: `1px solid ${ borderBottomColor ? borderBottomColor : "#02235c"}`,
        borderRadius: "0",
      };
    } else {
      fieldStyle = {
        background: "#FFF",
        border: "1px solid #CCCCCC",
        borderRadius: "5px",
        height: "40px",
      };
    }
    let fieldValue = field.value;

    return (
      <FormGroup
        style={{ position: "relative" }}
      >
        {label && (
          <Label
            className={`${fontBolded ? "fw-bold" : ""}`}
            id={inputId}
            labelColor={labelColor}
            style={emailStyle}
            required={required}>
            {label}
          </Label>
        )}
        {disabled ? (
          <div className={`outline-field-style px-1 py-2 ${!fieldValue && "text-secondary"}`}>
            {fieldValue ? fieldValue : <span className="invisible">{(placeholder || `Enter ${label}`)}</span>}
          </div>        
        ) : (
          <Input
            id={inputId}
            {...field}
            type="text"
            placeholder={placeholder || `Enter ${label}`}
            invalid={!!errorMessage}
            defaultValue={defaultValue}
            style={fieldStyle}
            onKeyDown={onKeyDown}
            onClick={onClick}
            maxLength={"50"}
            disabled={disabled}
            onChange={(e) => {
              onChange && onChange(e);
              setFieldValue(name, e.target.value);
            }}
          />
        )}

        {/* hint text display */}
        {hintText && (
          <Hint hintText={hintText} style={{ position: "absolute", marginTop: "2px" }} />
        )}

        {/* error message display */}
        {errorMessage && (
          <FormFeedback
            id={errorMessage}
            style={{
              position: "absolute",
              marginTop: 1,
              marginLeft: 3,
            }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    // Email props
    const { name } = this.props;

    return (
      <Field
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

Email.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

export default Email;
