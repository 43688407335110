import React from "react";

// Components
import Email from "../../components/Email";
import Password from "../../components/password";
import CheckBox from "../../components/CheckBox";
import LinkTo from "../../components/LinkTo";

//Lib
import * as Cookie from "../../lib/Cookie";

import LoginIcon from "../../assets/icons/icon-vector.svg";

//Constant
import { COOKIE } from "../../constants/Cookie";
import Button from "../../components/Button";
import Spinner from "../../components/Spinner";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind.this;
  }

  // if the session storage is set then redirect
  login() {
    const accessToken = Cookie.get(COOKIE.ACCESS_TOKEN);
    if (accessToken) {
      navigate("/contacts");
    } else {
      navigate("/");
    }
  }

  render() {
    return (
      <div className="basic-login-form mt-3">
        {/* Email Field */}
        <Email
          name="email"
          label="Email Address"
          placeholder="Enter your email"
          required
        />
        {/* Password Field */}
        <Password
          name="password"
          label="Password"
          placeholder="Enter your password"
          required
        />

        <div className="d-flex justify-content-between align-items-center text-color-secondary">
          {/* Remember me Checkbox */}
          <CheckBox name="rememberPassword" label="Remember me" />

          {/* forget-password Link */}
          <LinkTo url="/forget-password" className="link hover-link"
            text = " Forgot your password ?"
          />
        </div>

        {/* Login Button */}
        <Button
          className="w-100 mt-4 d-flex justify-content-center align-items-center"
          type="submit"
          label="Log In"
          progressLabel={
            <Spinner size={12} spinnerStyle={{ position: "relative" }} />
          }
          disabled={this.props.isLoading ? true : false}
          isProgress={this.props.isLoading}
          icon={this.props.isLoading ? "" : LoginIcon}
        />

        {/* sign-up Link */}
        <div className="mt-2">
          <span className="text-color-secondary">Don't have a Bowtie account? </span>
          <LinkTo url="/sign-up" className="link mx-1 hover-link"
            text = "Sign Up Here"
          />
        </div>
      </div>
    );
  }
}

export default LoginForm;
