import React from "react";

// Components
import Select from "../../../components/Select";

// Constants
import { PermissinonLevelOptions } from "../../../constants/User";

const PermissionDropdown = ({ name, label,onChange,dropDownPosition, required }) => {

  return (
    <>
      <Select
        name={name}
        label={label}
        options={PermissinonLevelOptions}
        onInputChange={onChange}
        required={required}
        menuPosition={dropDownPosition}
      />
    </>
  );
};

export default PermissionDropdown;