class PhoneNumber {

  static Get (data) {
       
    if(data){
      return data; 
    }else {
      return "";
    }
  }
}
    
export default PhoneNumber;