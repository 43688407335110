import React, { useEffect, useState } from "react";

//components
import Search from "../../components/Search";
import PageTitle from "../../components/PageTitle";

// API
import campaignAPI from "../../api/Campaign";

//Actions
import PageHeader from "../../components/PageHeader";
import Tags from "../../components/Tags";
import Sidebar from "./Sidebar";
import Breadcrumb from "../../components/Breadcrumb";
import NoRecordsFound from "../../components/NoRecordsFound";
import Spinner from "../../components/Spinner";
import toast from "../../components/Toast";

//lib
import ArrayList  from "../../lib/ArrayList";

const Campaign = () => {
  const [campaignList, setCampaignList] = useState();
  const [checkedValue, setCheckedValue] = useState([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [search, setSearch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [searchItem, setSearchItem] = useState("");
  const [disabled, setDisabled] = useState(true);

  // to handle seelcted tags
  const [tagSelected, setTagSelected] = useState(new Map());
  const [tags, setTags] = useState([]);

  //to store the data of the tags
  const [tagsList, setTagsList] = useState([]);

  const [tagsArray, setTagsArray] = useState([]);

  const bradcrumbList = [{ label: "Campaigns" }];

  useEffect(() => {
    getCampaignList();
  }, []);

  useEffect(() => {
    getFilterdList();
  }, [tags.length]);

  useEffect(() => {
    if (ArrayList.isEmpty(tags) ) {
      manualCheckboxHandle();
    }

  }, [campaignList?.length === checkedValue?.length]);

  useEffect(()=>{
    setTagsArray([]);
    getTagList();
  },[tagsList.length]);

  const handleDeleteFunction = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  const getTagList = () => {
    try {
      ArrayList.isNotEmpty(campaignList) &&
      campaignList.map((tag) => {
        setTagsArray((oldArray) => [
          ...oldArray,
          ...tag.campaign.contactTags,
          ...tag.campaign.contentTags          ,
        ]);
      });
    } catch(err){
      console.log(err);
    }
  };

  const getCampaignList = async () => {
    setIsLoading(true);
    await campaignAPI.getProgramList( "", (data) => {
      setCampaignList(data);
      setTagsList(data);
      setIsLoading(false);
    });
  };
  
  // Enable the "select all" check box if all the check box is selected
  const manualCheckboxHandle = () => {
    if ( checkedValue?.length > 0  && campaignList?.length === checkedValue?.length) {
      setIsSelectAllChecked(true);
    }else{
      setIsSelectAllChecked(false);
    }
  };

  //handle check box change
  const handleChange = (selectedCampaignId) => {
    if (selectedCampaignId) {
      //setSelectAll checkbox valuue
      setIsSelectAllChecked(false);
      setDisabled(true);
      //create new variable for checked value
      let selectedCampaignIds = checkedValue;
      //check selectec value already exist or not selected ids
      if (selectedCampaignIds.indexOf(selectedCampaignId) > -1) {
        //get the index
        let index = selectedCampaignIds.indexOf(selectedCampaignId);
        //remove the id from the checked value
        selectedCampaignIds.splice(index, 1);
        //append the removed list using spread operator
        setCheckedValue((selectedCampaignIds) => [...selectedCampaignIds]);
      } else {
        setDisabled(false);
        //appende the selected Id using spred operator
        setCheckedValue((selectedCampaignIds) => [
          ...selectedCampaignIds,
          selectedCampaignId,
        ]);
      }

    }
  };

  //delete all campaign at once
  const selectAllCampaign = async (event) => {
    //setSelectAll checkbox valuue
    setIsSelectAllChecked(!isSelectAllChecked);
    setDisabled(false);
    //create new array
    let campaignIdsArray = [];
    //validate checkbox checked or not
    if (event?.target?.checked) {
      //validate campaign list exist or not
      if (campaignList && campaignList.length > 0) {
        //loop the campaign list
        for (let i = 0; i < campaignList.length; i++) {
          campaignIdsArray.push(campaignList[i].campaign.campaignId);
        }
      }
      setCheckedValue(campaignIdsArray);
    } else {
      // await deleteCampaign();
      setCheckedValue([]);
      setDisabled(true);
    }
  };

  //delete campaign function
  const deleteCampaign = async () => {
    let selectedIds = [...new Set(checkedValue)];

    //delete campaign of selected ids
    for (let i = 0; i < selectedIds?.length; i++) {
      await campaignAPI.deleteProgram(selectedIds[i], (res) => {
        if(i ==  selectedIds.length -1){
          if (res && res.name) {
            toast.success("Campaign Deleted Successfully");
          } else if (res && res.applicationErrorCode) {
            toast.error(res.message);
          } else if (res && res.code === UNAUTHORIZED_ERROR) {
            toast.warn("Access token expired");
          }
          if (selectedIds[i] == res.campaignId) {
            campaignAPI.searchProgram(searchItem, (res) => {
              if (res) {
                setCampaignList(res);
                setTagsList(res);
                tagSelected.clear();
                setTags([]);
              }
              if (searchItem) {
                setSearch(true);
              } else {
                setSearch(false);
              }
            });
          }
        }
      });
    }
    handleDeleteFunction();
    setCheckedValue("");
    setSearch(false);
  };

  // campaign search
  const handleSearch = async function (e) {
    let searchTerm = e.target.value.trim();
    setSearchItem(searchTerm);
    if (!searchItem.length > 0) {
      manualCheckboxHandle();
    }
    setSearch(true);
    campaignAPI.searchProgram(searchTerm, (res) => {
      if (res) {
        setCampaignList(res);
        setTagsList(res);

        if (res?.length ===  checkedValue?.length ) {
          setIsSelectAllChecked(true);
        } 
      }
      if (searchTerm) {
        setSearch(true);
      } else {
        setSearch(false);
      }

      for (let i = 0; i < res.length; i++) {
        for (let i = 0; i < checkedValue.length; i++) {

          if (searchItem.length && res[i]?.campaign?.campaignId !=  checkedValue[i] ) {
            setIsSelectAllChecked(false);
          }
        }
  
      }
    });
  };

  //Handle tags click
  const handleTags = (e, i) => {
    let tag = e.target.id;
    let tempTags = tags;

    // pushing selected tags to array
    if (tempTags.indexOf(tag) > -1) {
      let index = tempTags.indexOf(tag);
      tempTags.splice(index, 1);
    } else {
      tempTags.push(tag);
    }

    setTags(tempTags);

    // setting key/value pair
    if (tagSelected.has(i)) {
      tagSelected.delete(i);
      for (let [key, value] of tagSelected) {
        setTagSelected((map) => new Map(map.set(key, value)));
        tagSelected.set(key, value);
      }
    } else {
      setTagSelected((map) => new Map(map.set(i, tag)));
    }

    //If No tag selected
    if (tags.length == 0) {
      setCampaignList(tagsList);
    }
  };

  // comparing two arrays if value matches return true else false
  function checkAvailability(arr, val) {
    return arr.some(function (arrVal) {
      return val.toLocaleLowerCase() === arrVal.toLocaleLowerCase();
    });
  }

  //Find Duplicates in the array
  function checkDuplicate(array, newData) {
    //getting campaing ids form the array
    let campaignId = array?.map((data) => {
      return data?.campaign?.campaignId;
    });
    //campaign id of new Data
    let newCampaignId = newData?.campaign?.campaignId;

    //Checking for duplicates
    if (campaignId.includes(newCampaignId)) {
      return true;
    } else {
      return false;
    }
  }

  // to get the filtered content list based on selected tag
  const getFilterdList = () => {
    let tempList = [];
    for (let i = 0; i < tagsList?.length; i++) {
      for (let j = 0; j < tags.length; j++) {
        //Form contact and communication array into array
        let array = [
          ...tagsList[i].campaign.contentTags,
          ...tagsList[i].campaign.contactTags,
        ];
        if (checkAvailability(array, tags[j])) {
          if (!checkDuplicate(tempList, tagsList[i])) {
            tempList.push(tagsList[i]);
          }
        }
      }
    }

    setCampaignList(tempList);

    //If no tag was selected
    if (tempList.length < 1) {
      setCampaignList(tagsList);
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={bradcrumbList} />

      {/* Header */}
      <PageHeader title="Select a campaign to view" />

      <div className="bg-white">
        <div className="row">
          <div className="col-lg-4 px-0 border">
            {/* Page title */}
            <div className="mx-3">
              <PageTitle
                label="Campaign List"
                buttonLabel="New Campaign"
                targetUrl="/campaign/add"
                tags
              />
            </div>

            {/* Search */}
            <Search
              classnames="page-search"
              placeholder="Find in campaign"
              pageName={"campaign"}
              onChange={handleSearch}
            />

            {/* Tags */}
            <div className="bg-white-smoke">
              <div className="mx-3 mb-4">
                <Tags
                  title="Select a tag or tags to show the campaign you need"
                  list={tagsArray}
                  onClick={handleTags}
                  tagSelected={tagSelected}
                />
              </div>
            </div>
            {/* Campaign List */}
            {isLoading ? (
              <Spinner
                spinnerStyle={{ position: "relative", marginLeft: "45%" }}
              />
            ) : campaignList && campaignList?.length > 0 ? (
              <Sidebar
                id={"campaign"}
                list={campaignList}
                onChange={handleChange}
                checkedValue={checkedValue}
                disabled={disabled}
                deleteCampaign={deleteCampaign}
                selectAllCampaign={selectAllCampaign}
                isDeleteModalOpen={isDeleteModalOpen}
                handleDeleteFunction={handleDeleteFunction}
                isSelectAllChecked={isSelectAllChecked}
              />
            ) : search ? (
              <NoRecordsFound message="No Campaigns Found" hideCard />
            ) : (
              <NoRecordsFound message="No campaigns added yet" hideCard />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Campaign;
