import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormFeedback } from "reactstrap";

//Styles
import "./Style.scss";


class Checkbox extends React.Component {
  // Validate the checkbox field
  validate(value) {
    // Defining props
    const { label, placeholder, required, error } = this.props;
    // Defining errorMessage
    let errorMessage;
    // defining the inputLabel
    const inputLabel = label || placeholder;
    // Defining the errorMessageLabel
    const errorMessageLabel = error;
    // Assigning the required error message to display when value is not exists.
    if (!value && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }
    return errorMessage;
  }

  // Render the input checkbox field
  renderInput({ field: { value, onChange }, form: { touched, errors } }) {
    // Defined props
    const { name, id, label, className, error } = this.props;
    // Defined errorMessage
    let errorMessage = touched[name] && errors[name] ? errors[name] : null;

    // Defining the error value to the error message when value is not exists.
    if (!value && error) {
      errorMessage = error;
    }
    // Defining inputId
    const inputId = id || name;
    return (
      <div className={`${className} fw-400`}>
        <div className="d-flex gap-1 align-items-center">
          <input
            name={name}
            id={inputId}
            type="checkbox"
            value={value}
            checked={value}
            onChange={onChange}
          />
          <span className="d-block" htmlFor={id}> {label}</span>
        </div>

        {/* error message displaying  */}
        {errorMessage && (
          <FormFeedback className="mt-1">{errorMessage}</FormFeedback>
        )}
      </div>
    );
  }

  // Render the checkbox component
  render() {
    // defining the props while rendering the component.
    const { name, label } = this.props;
    return (
      <Field
        validate={this.validate.bind(this)}
        name={name}
        label={label}
        render={this.renderInput.bind(this)}
      />
    );
  }
}
Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
};
export default Checkbox;
