
class ArrayList {
  
  static isEmpty = (arrayList) =>{
    if(!this.isArray(arrayList) || arrayList && arrayList.length == 0){
      return true;
    } else {
      return false;
    }
  };

  static isNotEmpty = (arrayList) =>{
    return !this.isEmpty(arrayList);
  };

  static isArray =(arrayList) => {
    return Array.isArray(arrayList);
  };

  static isElementExist = (arrayList , element) => {
    if(this.isNotEmpty(arrayList) &&  element){
      return arrayList.indexOf(element) > -1;
    } else {
      return false;
    }
  };

  static compare = (arr1, arr2) => {
    return arr1.toLocaleLowerCase() === arr2.toLocaleLowerCase();
  };
 
}

export default ArrayList;