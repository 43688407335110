import React from "react";
import DeleteModal from "../../components/ConfirmationModal";
import PropTypes from "prop-types";

//Styles
import "./Style.scss";
import { DeleteIcon, DeleteIconRed } from "../../assets/icons";
import LinkTo from "../LinkTo";

const propTypes = {
  onClick: PropTypes.func,
  label: PropTypes.string,
  isDeleteModalOpen: PropTypes.bool,
  handleFunction: PropTypes.func,
  checkedValue: PropTypes.bool,
};
class DeleteLink extends React.Component {
  render() {
    // Delete Link props
    const {
      onClick,
      label,
      isDeleteModalOpen,
      handleFunction,
      checkedValue,
      disabled,
      primaryButtonText,
      secondaryButtonText,
      description,
    } = this.props;

    // Count of checked values
    const count = checkedValue.length;
    return (
      <>
        <div
          className="d-flex mx-1"
          onClick={(e) => {
            if (checkedValue.length > 0) {
              e.preventDefault();
              handleFunction && handleFunction();
            }
          }}>
          <div
            style={{
              cursor: disabled && count == 0 ? "not-allowed" : "pointer",
            }}>
            <LinkTo
              icon={count > 0 ? <DeleteIconRed /> : <DeleteIcon />}
            />
            <span
              className={`section-button p-1 ${
                count > 0 ? "text-true-blue" : "text-dark-gray"
              }`}>
              {label}
            </span>
          </div>
        </div>
        <DeleteModal
          isOpen={isDeleteModalOpen}
          toggle={handleFunction}
          title={label}
          primaryButtonText={primaryButtonText}
          secondaryButtonText={secondaryButtonText}
          description={description}
          onClickNo={handleFunction}
          onClickYes={onClick}
        />
      </>
    );
  }
}
DeleteLink.propTypes = propTypes;

export default DeleteLink;
