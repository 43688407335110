// API
import CampaignAPI from "./Campaign";
import ContactAPI from "./ContactAPI";

class Tag {

  async get(callback){
    try{
      CampaignAPI.getCampaignTags((tags)=> {
        let tagList = new Array();
        //validate list
        if(tags && tags.length > 0){
        //get tag list
          let tagLists = tags.map((data)=> { return data.tag; });
          //filter duplicate
          var filterTagList = new Set(tagLists);
          //push tag
          filterTagList.forEach((tag)=>{
            tagList.push({label: tag, value : tag});
          });
        }
        return callback(tagList);
      });
    } catch(err){
      console.log(err);
      return callback([]);
    }
  }

  async getContact(callback){
    try{
      ContactAPI.getContactTags((tags)=> {
        let tagList = new Array();
        //validate list
        if(tags && tags.length > 0){
        //get tag list
          let tagLists = tags.map((data)=> { return data.tag; });
          //filter duplicate
          var filterTagList = new Set(tagLists);
          //push tag
          filterTagList.forEach((tag)=>{
            tagList.push({label: tag, value : tag});
          });
        }
        return callback(tagList);
      });
    } catch(err){
      console.log(err);
      return callback([]);
    }
  }
    
}
const tag = new Tag();

export default tag;
