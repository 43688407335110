// import Notifications from "react-notification-system-redux";
import api from "../lib/Api";

const REQUEST_TABLE_LIST = "REQUEST_TABLE_LIST";
const RECEIVE_TABLE_LIST = "RECEIVE_TABLE_LIST";
const SET_TABLE_PAGE = "SET_TABLE_PAGE";
const CLEAR_TABLE_LIST = "CLEAR_TABLE_LIST";

/**
 * Request table list
 *
 * @param {*} id
 */
function requestTableList(id) {
  return { type: REQUEST_TABLE_LIST, id };
}
/**
 * Receive table list
 *
 * @param {*} id
 * @param {*} payload
 */
function receiveTableList(id, payload) {
  return { type: RECEIVE_TABLE_LIST, id, payload };
}

/**
 * Clear table list
 *
 * @param {*} id
 */
export function clearTableList(id) {
  return { type: CLEAR_TABLE_LIST, id };
}

/**
 * Set table page
 *
 * @param {*} id
 * @param {*} payload
 */
export function setTablePage(id, payload) {
  return { type: SET_TABLE_PAGE, id, payload };
}

export function receiveUserDetail(id, payload) {
  return {
    type: "RECEIVE_USER_DETAIL",
    id,
    payload,
  };
}

export function receiveContactDetail(id, payload) {
  return {
    type: "RECEIVE_CONTACT_DETAIL",
    id,
    payload,
  };
}

/**
 * Fetch list
 *
 * @param {*} id
 * @param {*} apiUrl
 * @param {*} page
 * @param {*} pageSize
 * @param {*} params
 */
// used to fetch data from api and store in redux store
export function fetchList(id, apiUrl, data, page, pageSize, params = {}) {
  try {
    const queryString = [];
    if (page) {
      queryString.push(`page=${page}`);
    }

    if (pageSize) {
      queryString.push(`pageSize=${pageSize}`);
    }

    Object.keys(params).forEach((param) => {
      queryString.push(`${param}=${params[param]}`);
    });

    // sending query params in api request
    apiUrl = `${apiUrl}?${queryString.join("&")}`;

    return (dispatch) => {
      dispatch(requestTableList(id));
      // get -> fetch api function to get details from the requested url
      return api.get(`${apiUrl}`, data, (res) => {
        const list = res;
        // dispatch - to store the api response in the redux store
        dispatch(receiveTableList(id, list));
      });
    };
  } catch (err) {
    console.log(err);
  }
}
