import api from "../lib/Api";
import * as apiUrl from "../helpers/apiUrl";
import { COOKIE } from "../constants/Cookie";
import * as cookie from "../lib/Cookie";

class User {
  // New User
  addUser(data, callback) {
    try {
      const customerId = cookie.get(COOKIE.CUSTOMER_ID);

      api.post(apiUrl.addNewUser(customerId), data, (res) => {
        callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  updateUser(userId, data, callback) {
    const customerId = cookie.get(COOKIE.CUSTOMER_ID);

    try {
      api.patch(apiUrl.updateUser(customerId, userId), data, (res) => {
        callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Get user list
  getUserList(callback) {
    const customerId = cookie.get(COOKIE.CUSTOMER_ID);

    try {
      api.get(apiUrl.userDetails(customerId), "", (res) => {
        return callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Delete user
  deleteUser(userId, callback) {
    const customerId = cookie.get(COOKIE.CUSTOMER_ID);

    try {
      api.delete(apiUrl.deleteUser(customerId, userId), "", (res) => {
        callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  getUserDetail(userId, callback) {
    const customerId = cookie.get(COOKIE.CUSTOMER_ID);

    try {
      api.get(apiUrl.getUser(customerId, userId), "", (res) => {
        return callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Search User
  searchUser(searchTerm, callback) {
    const customerId = cookie.get(COOKIE.CUSTOMER_ID);

    try {
      api.get(apiUrl.searchUserUrl(customerId, searchTerm), "", (res) => {
        return callback(res);
      });
    } catch (err) {
      console.log(err);
    }
  }

  // Reset password
  resetPassword(data, callback) {
    try {
      api.postWithoutAccessToken(apiUrl.resetPassword(), data, (res) => {
        callback(res);
      });
    } catch(err) {
      console.log(err);
    }
  }
}
const user = new User();
export default user;
