import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import Breadcrumb from "../../components/Breadcrumb";
import DetailCard from "./components/DetailCard";
import SectionTitle from "./components/SectionTitle";
import ContactCard from "../contact/components/ContactCard";
import PageTitle from "../../components/PageTitle";
import TextArea from "../../components/TextArea";
import Form from "../../components/Form";
import Spinner from "../../components/Spinner";
import DragContent from "./components/DragContent";
import NoRecordsFound from "../../components/NoRecordsFound";
import ConfimationModal from "../../components/ConfirmationModal";
import AssignToContact from "./components/AssignContact";
import SaveButton from "../../components/SaveButton";
import Date from "../../components/Date";
import MultiSelect from "../../components/MultiSelectCreatable";
import Text from "../../components/Text";
import SubmitButton from "../../components/SubmitButton";
import Toast from "../../components/Toast";

// Assets
import rightArrowIcon from "../../assets/icons/leftArrow.png";
import assignContactIcon from "../../assets/icons/assign-contact.svg";

// Constants
import { SubscriptionStatus } from "../../constants/SubscriptionStatus";
import { Content } from "../../constants/Campaign";
import * as Constants from "../../constants";

// Api
import ContentAPI from "../../api/Content";
import ContactAPI from "../../api/ContactAPI";
import CampaignAPI from "../../api/Campaign";
import Tag from "../../api/TagAPI";

// Lib 
import ArrayList from "../../lib/ArrayList";
import Select from "../../components/Select";
import * as DateTime from "../../lib/DateTime";
import Number from "../../lib/Number";
import { HTTP_STATUS } from "../../lib/http";

//lib
import { toString } from "../../lib/Helper";

const EditCampaign = () => {
  const [contactList, setContactList] = useState();
  const [campaignDetailObject, setCampaignDetailObject] = useState("");
  const [campaignDetail, setCompaignDetail] = useState("");
  const [programId, setProgramId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isAddNewStep, setAddNewStep] = useState(false);
  const [communicationDetailList, setCommunicationDetailList] = useState("");
  const [onDragContent, setOnDrgContent] = useState("");
  const [campaignCommunicationIds, setContentIds] = useState([]);
  const [selectedCampaignContentList, setSectedCampaignContentList] = useState(
    []
  );
  const [isSaveButtonDisabled, setIsSaveButtonDisable] = useState(true);
  const [isContentSaveDisabled, setIsContentSaveDisabled] = useState(true);
  const [removeableCommunicationIds, setRemovableCommunicationIds] = useState(
    []
  );
  const [addedCommunicationIds, setAddedCommunicationIds] = useState([]);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedContact, setDeletedContact] = useState("");
  const [assignToCampaignModalOpen, setAssignToCampaignModalOpen] =
    useState(false);
  const [contentData, setContentData] = useState("");
  const [contentValues, setContentValues] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [communicationLists, setCommunicationList] = useState([]);
  const [contentTags, setContentTags] = useState([]);
  const [contactTagsList, setContactTags] = useState([]);

  const [newCampaignContact, setNewCampaignContacts] = useState([]);

  const [isDisabled, setIsDisabled] = useState(false);

  const navigate = useNavigate();

  //get program Id from url
  const isCampaignEditPage = window.location.pathname.split("/")[3] ? true : false;

  useEffect(() => {
    getTagListValues();
    getTagLists();
    if (isCampaignEditPage) {
      getProgramDetails();
    }
  }, []);

  useEffect(() => {
    getContentLists();
  }, [campaignDetailObject]);

  useEffect(() => {
    getCommunicationList(true);
  }, [communicationLists]);

  useEffect(() => {
    getContactList();
    getTagValues();
  }, [campaignDetailObject]);

  useEffect(() => {
    getContactList();
  }, [newCampaignContact]);

  useEffect(()=>{
    getContactList();
  },[newCampaignContact]);

  const getTagLists = () => {
    try {
      Tag.get((tags) => {
        if (tags && tags.length > 0) {
          setTagList(tags);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const getContentLists = async () => {
    try {
      let addedContentLists = new Array();

      let unAddedContentLists = new Array();

      //get coomuncation list from campaign details
      let communicationList = campaignDetailObject?.contentList;

      //get campaign communication Ids
      let CampaignContentList = ArrayList.isNotEmpty(communicationList) ? communicationList.map((data) => {
        return data?.contentId;
      }) : [];

      await ContentAPI.getContentList((response) => {
        if (ArrayList.isNotEmpty(response)) {
          for (let i = 0; i < response.length; i++) {

            if (response[i].status != Content.DELETED) {

              if (CampaignContentList.indexOf(response[i].contentId) > -1) {

                addedContentLists.push(response[i]);

              } else {

                unAddedContentLists.push(response[i]);
              }
            }
          }

          let concatedArray = addedContentLists.concat(unAddedContentLists);

          setCommunicationList(concatedArray);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };


  // Get the Communication Details
  const getCommunicationList = async () => {
    try {

      let selectedCommunicationList = new Array();

      //get coomuncation list from campaign details
      let communicationList = campaignDetailObject?.contentList;

      //get campaign communication Ids
      let CampaignContentList = ArrayList.isNotEmpty(communicationList) ? communicationList.map((data) => {
        return data?.contentId;
      }) : [];

      //get campaign communication list
      let campaignContentList = CampaignContentList;

      //validate content list exist or not
      setContentIds(campaignContentList);

      //validate array is empty or not
      if (ArrayList.isNotEmpty(communicationLists)) {

        //loop the campign communication list
        for (let i = 0; i < communicationLists.length; i++) {


          //validate content list exist or notin campign communication
          if (ArrayList.isElementExist(campaignContentList, communicationLists[i].contentId)) {
            //get the cadence values
            let cadenceValues = communicationList.find(({ contentId }) => contentId == communicationLists[i].contentId);


            communicationLists[i].isExist = true;


            //add the cadence value details
            communicationLists[i].unit = {
              time: cadenceValues?.time,
              position: cadenceValues?.position,
              unit: cadenceValues?.unit,
              trigger: cadenceValues?.trigger
            };

            selectedCommunicationList.push(communicationLists[i]);

          } else {
            communicationLists[i].isExist = false;
          }
        }


        //set communication
        setCommunicationDetailList(communicationLists);

        //set campaign selected communication list
        setSectedCampaignContentList(selectedCommunicationList);

        setIsLoading(false);
      } else {
        setIsLoading(false);
      }

    } catch (err) {
      console.log(err);
    }
  };

  const UpdateContentList = (communicationIds, isAddContent) => {
    try {
      let selectedContents = new Array();

      let position = isAddContent ? communicationIds == 1 ? 0 : communicationIds.length : 0;

      if (communicationLists && communicationLists.length > 0) {

        for (let i = 0; i < communicationLists.length; i++) {

          //get the cadence values
          let cadenceValues = campaignDetailObject ? campaignDetailObject?.contentList.find(({ contentId }) => contentId == communicationLists[i].contentId) : [];

          //skip the deleted contents
          if (communicationIds.indexOf(communicationLists[i].contentId) > -1) {

            communicationLists[i].isExist = true;

            selectedContents.push(communicationLists[i]);

            //add the cadence value details
            communicationLists[i].unit = {
              time: cadenceValues && cadenceValues.time,
              position: isAddContent ? cadenceValues && cadenceValues.position || ++position : ++position,
              unit: cadenceValues && cadenceValues.unit,
              trigger: cadenceValues && cadenceValues.trigger
            };
          } else {
            communicationLists[i].isExist = false;
          }
        }

        setSectedCampaignContentList(selectedContents);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Get the Tag List
  const getTagListValues = () => {
    //validate compaign details exist or not
    if (ArrayList.isNotEmpty(campaignDetail.contactTags)) {
      //get new array
      let tagList = new Array();
      //loop and push the tags
      campaignDetail.contactTags.forEach((data) => {
        tagList.push({ value: data, label: data });
      });
    }
  };

  // Get the Contact List
  const getContactList = async () => {
    try {
      let contactList = new Array();
      //get contact list
      await ContactAPI.getContactList((response) => {
        //validate contact list
        if (response && Array.isArray(response) && response.length > 0) {
          //validate campaign detail object
          if (ArrayList.isNotEmpty(campaignDetailObject.contactList)) {
            //loop the contact list
            for (let i = 0; i < response.length; i++) {
              //validate contact exist or not in campain
              if (
                campaignDetailObject.contactList.indexOf(response[i]?.contact?.contactId) >
                -1
              ) {
                contactList.push(response[i].contact);
              }
            }
          } else if (ArrayList.isNotEmpty(newCampaignContact)) {
            for (let i = 0; i < response.length; i++) {
              //validate contact exist or not in campain
              if (
                newCampaignContact.indexOf(response[i]?.contact?.contactId) >
                -1
              ) {
                contactList.push(response[i].contact);
              }
            }
          }
          //set contact list
          setContactList(contactList);
          setIsLoading(false);
        }
      });
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  // Get the Program Details
  const getProgramDetails = async () => {
    try {
      //get compaign Id from Url
      const isCampaignEditPage =
        window.location.pathname.split("/")[3] == "edit";

      //get program Id from url
      const programId =
        !isCampaignEditPage && window.location.pathname.split("/")[3];

      //validate program Id
      if (programId) {
        //setprogram id
        setProgramId(programId);
        //get contact list
        await CampaignAPI.getProgramsList(
          programId,
          "",
          (response) => {
            if (response) {
              setCampaignDetailObject(response);
              if (response && response.campaign) {
                setCompaignDetail(response.campaign);
                setContentData(response.communicationList);
              }
            }
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Assign Contact Model
  const AssingContactModalOpen = (contactAssigned) => {
    setAssignToCampaignModalOpen(!assignToCampaignModalOpen);

    if (contactAssigned) {
      getProgramDetails();
    }
  };

  // Update the Campaign details
  const updateCampaign = (data) => {
    setIsDisabled(true);
    try {
      //contact tags array
      let contactTags = new Array();
      //get payload
      let payLoad = new Object();
      //get tag length
      if (data && data.contactTags && data.contactTags.length > 0) {
        //loop the tags
        for (let i = 0; i < data.contactTags.length; i++) {
          //push contact
          contactTags.push(data.contactTags[i].value);
        }
      }
      //   if(contactTags){
      //     payLoad.contactTags = contactTags;
      //   }

      //test the description
      if (data && data.description) {
        payLoad.description = data.description;
      } else {
        payLoad.description = "";
      }

      payLoad.name = data.name;

      if (startDate) {
        payLoad.startDate = DateTime.USFormat(startDate);
      } else if (data && data.startDate) {
        payLoad.startDate = DateTime.USFormat(data.startDate);
      }

      if (data.dripTime) {
        payLoad.dripTime = parseInt(data.dripTime);
      }

      if (data.dripUnit) {
        payLoad.dripUnit = data.dripUnit.value;
      }

      if (data.dripTrigger) {
        payLoad.dripTrigger = data.dripTrigger.value;
      }

      if (data.programType) {
        payLoad.programType = data.programType.value;
      }

      //update the campaign
      CampaignAPI.updateProgram(
        programId,
        payLoad,
        (response) => {
          if (response) {
            navigate(`/campaign/${programId}`);
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  //Forming data
  const toArray = (value) => {
    let data = {};
    let contentTags = [];
    let contactTags = [];
    let communicationList = new Array();

    //forming communicationTags array
    if (value.contentTags.length > 0) {
      value.contentTags.forEach((data) => {
        contentTags.push(data.value);
      });
    }
    //forming contactTags array
    if (value.contactTags.length > 0) {
      value.contactTags.forEach((data) => {
        contactTags.push(data.value);
      });
    }

    data.name = toString(value.name);
    data.description = value.description;
    data.startDate = DateTime.USFormat(startDate);

    data.contentTags = contentTags;
    data.contactTags = contactTags;
    data.contactTagType = value?.contactTagType?.value;
    data.contentTagType = value?.communicationTagType?.value;


    for (let i = 0; i < selectedCampaignContentList.length; i++) {

      let communicationData = selectedCampaignContentList[i];

      // Filter data based on communication id
      let data = ArrayList.isArray(contentValues) && contentValues?.find(data => data.contentId == communicationData.contentId);

      let contentData = {
        contentId: communicationData.contentId,
        time: data?.time,
        unit: data?.unit,
        trigger: data?.trigger

      };
      communicationList.push(contentData);
    }

    if (communicationList && communicationList.length > 0) {
      data.communicationList = communicationList;
    }

    if(ArrayList.isNotEmpty(newCampaignContact)){
      data.contactList = newCampaignContact;
    }

    return data;
  };

  //create campaign
  const newCampaign = (data) => {
    CampaignAPI.addProgram(toArray(data), (res) => {
      if (res) {
        navigate("/campaign");
      }
    });
  };

  // Handle the Add New step button
  const handleAddNewStep = () => {
    setAddNewStep(!isAddNewStep);
  };


  const updateContent = async (contentList, isDelete) => {
    try {

      if (contentList && contentList.length > 0) {
        //create the body data
        let body = {
          updateList: contentList,
          delete: isDelete
        };
        //update the content
        await CampaignAPI.updateContent(
          programId,
          body,
          (response) => {
            if (response) {
              setAddedCommunicationIds([]);
              setIsContentSaveDisabled(true);
              navigate(`/campaign/${programId}`);
            }
          }
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Drag the Content and save
  const onDragSave = async () => {
    try {

      let updateContentData = new Array();

      let newContentData = new Array();

      if (contentValues && contentValues.length > 0) {
        for (let i = 0; i < contentValues.length; i++) {
          if (ArrayList.isArray(addedCommunicationIds)) {
            if (addedCommunicationIds.indexOf(contentValues[i].contentId) > -1) {
              newContentData.push(contentValues[i]);
            } else {
              updateContentData.push(contentValues[i]);
            }
          }
        }
      }

      //validate compaign Id lists
      if (
        addedCommunicationIds &&
        addedCommunicationIds.length > 0 &&
        programId
      ) {

        let contentList = new Array();

        for (let i = 0; i < addedCommunicationIds.length; i++) {
          // Filter data based on communication id
          let data = ArrayList.isArray(newContentData) && newContentData?.find(data => data.contentId == addedCommunicationIds[i]);

          let contentData = {
            contentId: addedCommunicationIds[i],
            time: data?.time,
            unit: data?.unit,
            trigger: data?.trigger

          };
          contentList.push(contentData);
        }

        await updateContent(contentList, false);
      }

      //update content values
      if (updateContentData && updateContentData.length > 0) {
        //create new updateContent list Array
        let updateContentList = new Array();
        //loop the update content data
        for (let i = 0; i < updateContentData.length; i++) {
          updateContentList.push({
            contentId: updateContentData[i].contentId,
            time: Number.isNumber(updateContentData[i]?.time) ? updateContentData[i]?.time : updateContentData[i]?.oldValues?.time,
            unit: updateContentData[i].unit || updateContentData[i]?.oldValues?.unit,
            trigger: updateContentData[i]?.trigger || updateContentData[i]?.oldValues?.trigger
          });
        }

        await updateContent(updateContentList, false);

      }

      //remove the campaign Ids
      if (removeableCommunicationIds && removeableCommunicationIds.length > 0) {
        let contentRemoveList = new Array();
        for (let i = 0; i < removeableCommunicationIds.length; i++) {
          let contentData = {
            contentId: removeableCommunicationIds[i],
            position: null,
            time: 1,
            unit: "DAYS",
            trigger: "FROM_PREVIOUS"

          };
          contentRemoveList.push(contentData);
        }
        await updateContent(contentRemoveList, true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Contents Ondrag End function
  const contentOnDropEnd = (event) => {
    event.preventDefault();
    if (onDragContent) {
      //validate and add new coomunication ID in add array
      let addedCommunicationId = addedCommunicationIds;
      if (addedCommunicationId.indexOf(onDragContent.contentId) == -1) {
        addedCommunicationId.push(onDragContent.contentId);
        setAddedCommunicationIds(addedCommunicationId);
      }

      //validate In added communication list Ids exist or not in removeable list
      let removeableCommunicationId =
        removeableCommunicationIds && removeableCommunicationIds.length > 0
          ? removeableCommunicationIds
          : [];
      let updatedCommunicationIds;
      if (
        removeableCommunicationId.indexOf(onDragContent.contentId) > -1
      ) {
        updatedCommunicationIds = removeableCommunicationId.filter(
          (id) => id != onDragContent.contentId
        );
        setRemovableCommunicationIds(updatedCommunicationIds);
      }

      let campaignIds = campaignCommunicationIds;
      if (campaignIds.indexOf(onDragContent.contentId) == -1) {
        campaignIds.push(onDragContent.contentId);
      }
      setContentIds(campaignIds);
      UpdateContentList(campaignIds, true);
      setOnDrgContent("");
    }
  };

  // Content Ondrag function
  const contentOnDrag = (data) => {
    if (data && !onDragContent) {
      setOnDrgContent(data);
    }
    setIsContentSaveDisabled(false);
  };

  // Handle Campaign Field change
  const handleCampaignFieldChange = () => {
    setIsSaveButtonDisable(false);
  };

  //  Remove the Content
  const removeContent = (data) => {
    if (data) {
      setIsContentSaveDisabled(false);
      //update removed array
      let removeCommunicationIds = removeableCommunicationIds;
      if (removeCommunicationIds.indexOf(data.contentId) == -1) {
        removeCommunicationIds.push(data.contentId);
        setRemovableCommunicationIds(removeCommunicationIds);
      }

      //update added communication list array
      let addedCommunicationId = ArrayList.isNotEmpty(addedCommunicationIds)
        ? addedCommunicationIds
        : [];

      let updatedCommunicationIds;
      if (ArrayList.isElementExist(addedCommunicationId, data.contentId)) {
        updatedCommunicationIds = addedCommunicationId.filter(
          (id) => id != data.contentId
        );
        setAddedCommunicationIds(updatedCommunicationIds);
      }
      //comaapign Ids
      let campaignIds = campaignCommunicationIds;
      //validate selected campaign exist or not
      if (ArrayList.isElementExist(campaignIds, data.contentId)) {
        //get index
        let index = campaignIds.indexOf(data.contentId);
        //remove the value
        campaignIds.splice(index, 1);
      }
      setContentIds(campaignIds);
      UpdateContentList(campaignIds, false);
    }
  };

  // Delete the Contact
  const deleteContact = () => {
    try {
      if (
        selectedContact &&
        selectedContact.contactId &&
        programId
      ) {

        const body = {
          contactIds: [selectedContact.contactId],
          subscription: SubscriptionStatus.UNSUBSCRIBED,
        };

        CampaignAPI.assignContact(
          programId,
          body,
          (response,status) => {
            if (status == HTTP_STATUS.RESPONSE_SUCCESS) {
              getProgramDetails();
              openDeleteConfirmationModal();
              Toast.success("Contact Unassigned Successfully");
            }else if(status >= HTTP_STATUS.RESPONSE_ERROR ) {
              Toast.error(response?.message);
            }
          }
        );
      } else {
        let selectedContacts = newCampaignContact;
        let updatedContactIds = selectedContacts.filter((contactId) => contactId != selectedContact.contactId);
        setNewCampaignContacts(updatedContactIds);
        openDeleteConfirmationModal();
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Delete Confirmation Model
  const openDeleteConfirmationModal = (data) => {
    if (data) {
      setDeletedContact(data);
    }
    setOpenConfirmationModal(!openConfirmationModal);
  };

  //Breadcrumb List
  const breadcrumbArray = [
    { label: "Campaigns", link: "/campaign" },

    { label: campaignDetail && campaignDetail.name, link: "" }
  ];

  // Breadcrumb list
  const bradcrumbListNewCampaign = [
    { label: "Campaigns", link: "/campaign" },
    {
      label: "New Campaign",
      link: "",
    },
  ];

  // Function return an object to prefill the multiselect dropdown
  const tagsSelected = () => {
    let array = [];

    if (Array.isArray(campaignDetail.contactTags) || Array.isArray(campaignDetail.contentTags)) {
      array = [
        ...campaignDetail.contactTags,
        ...campaignDetail.contentTags
      ];
    }

    let tagArray = [];
    let tagsObject = {};

    array.forEach((data) => {
      tagArray.push({ value: data, label: data });
    });

    tagsObject["contactTags"] = tagArray;
    return tagsObject;
  };

  // Get data from field change

  function getContentValues(contentValue, contentDetails) {
    //create array for content
    let contentValueList = new Array();

    let isContentFound;

    let NonChangeContent;

    //check the content already exiist in update content list

    if (contentValues && contentValues.length > 0) {
      isContentFound = contentValues.find(
        (data) => data.contentId == contentValue.contentId
      );

      //get not match content with new content update
      NonChangeContent = contentValues.map(
        (data) => {
          if (data.contentId != contentValue.contentId) {
            return data;
          }
        }
      ).filter(data => data !== undefined);
    }

    //validate content exist or not
    if (isContentFound) {
      //get the  exist content key values
      const ExistContentValue = Object.keys(isContentFound);
      //get new content values
      const newContentKeyValue = Object.keys(contentValue);

      let isNewKeyCome = false;
      let keyValue;
      let existKeyValue;

      //loop the content
      for (let key of newContentKeyValue) {
        //validate key already exist in existing content
        if (ExistContentValue.indexOf(key) == -1) {
          isNewKeyCome = true;
          keyValue = key;
        } else {
          existKeyValue = key;
        }
      }

      //validate is new property come
      if (isNewKeyCome) {
        isContentFound[keyValue] = contentValue[keyValue];
      } else {
        //if new value not come check diff between old value and new value
        if (isContentFound[existKeyValue] != contentValue[existKeyValue]) {
          //update new value
          isContentFound[existKeyValue] = contentValue[existKeyValue];
        }
      }

      //update old value content details in content
      isContentFound.oldValues = contentDetails;

      if (NonChangeContent) {
        //push the content
        contentValueList.push(...NonChangeContent, isContentFound);
      } else {
        contentValueList.push(isContentFound);
      }
    } else {

      //update the content
      contentValue.oldValues = contentDetails;


      if (NonChangeContent) {
        //push the content
        contentValueList.push(...NonChangeContent, contentValue);
      } else {
        contentValueList.push(contentValue);
      }
    }
    
    setContentValues(contentValueList);

    setIsContentSaveDisabled(false);
  }

  const getTagValues = () => {
    // Communication Tag Value
    let contentTags = [];
    let contactTagsList = [];

    if (campaignDetailObject && ArrayList.isNotEmpty(campaignDetailObject.campaign.contentTags)) {
      campaignDetailObject.campaign.contentTags.forEach((communicationTag) => {
        contentTags.push({
          value: communicationTag,
          label: communicationTag,
          name: communicationTag,
        });
      });

      if (campaignDetailObject && ArrayList.isNotEmpty(campaignDetailObject.campaign.contactTags)) {
        campaignDetailObject.campaign.contactTags.forEach((contactTags) => {
          contactTagsList.push({
            value: contactTags,
            label: contactTags,
            name: contactTags,
          });
        });
      }

      setContactTags(contactTagsList);
      setContentTags(contentTags);
    }
  };

  let tags = tagsSelected();
  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={isCampaignEditPage ? breadcrumbArray : bradcrumbListNewCampaign} />

      {/* Assign Contact */}
      <AssignToContact
        isOpen={assignToCampaignModalOpen}
        toggle={AssingContactModalOpen}
        programId={programId}
        setNewCampaignContacts={setNewCampaignContacts}
        newCampaignContact= {newCampaignContact}
      />

      {/* Unassign Contact Model */}
      <ConfimationModal
        // history={props.history}
        isOpen={openConfirmationModal}
        toggle={openDeleteConfirmationModal}
        title="Unassign contact"
        primaryButtonText="Unassign contact"
        secondaryButtonText="Cancel"
        description="Would you like to unassign this contact permanently?"
        onClickNo={openDeleteConfirmationModal}
        onClickYes={deleteContact}
      />

      {isLoading ? (
        <Spinner />
      ) : (
        <Form
          enableReinitialize={true}
          initialValues={{
            name: campaignDetail && campaignDetail.name || "",
            description: (campaignDetail && campaignDetail.description) || "",
            ...tags,
            startDate: (campaignDetail && DateTime.USFormat(campaignDetail.startDate)) || "",
            contentTags: contentTags || [],
            contactTags: contactTagsList || [],
            contentTagType: campaignDetailObject?.campaign?.contentTagType ? [{ label: campaignDetailObject?.campaign?.contentTagType, value: campaignDetailObject?.campaign?.contentTagType }] : [],
            contactTagType: campaignDetailObject?.campaign?.contactTagType ? [{ label: campaignDetailObject?.campaign?.contactTagType, value: campaignDetailObject?.campaign?.contactTagType }] : [],
          }}
          onSubmit={(value) => {
            if (isCampaignEditPage) {
              updateCampaign(value);
            } else {
              newCampaign(value);
            }
            onDragSave();
          }}>
          {/* Page Header */}
          {/* Page title */}
          <div className="d-flex justify-content-start">

            <PageTitle
              icon={rightArrowIcon}
              label={isCampaignEditPage ? campaignDetail && campaignDetail.name : "New Campaign"}
              link={isCampaignEditPage ? `/campaign/${programId}` : `/campaign`}
            />
          </div>

          <div className="bg-white row">
            {!isAddNewStep ? (
              <div className="col-lg-4 border">
                {/* Page title */}
                <div className={`d-flex ${isCampaignEditPage ? 'justify-content-between' : 'justify-content-end'} `}>
                  {isCampaignEditPage ? (
                    <>
                      <PageTitle
                        label="Edit Campaign"
                        disabled={isSaveButtonDisabled}
                      />

                      <SaveButton label="Done" className="mt-3" isClicked={isDisabled} />

                    </>
                  ) : (
                    <>
                      {/* Page title */}
                      {/* <PageTitle label="About Campaign" /> */}

                      <div className="mt-2">
                        <SubmitButton
                          label="Publish Campaign"
                        />
                      </div>
                    </>
                  )}
                </div>

                {isCampaignEditPage && (
                  <>
                    {/* Page title */}
                    <PageTitle label="About Campaign" />
                  </>
                )}

                {/* Name */}
                <Text
                  label="Name"
                  name="name"
                  required
                />

                {/* Description */}
                <TextArea
                  name="description"
                  label="Description"
                  placeholder="Enter Description"
                  onChange={handleCampaignFieldChange}
                />

                {/* Communication Tags */}
                <MultiSelect
                  label="Content Tags"
                  name="contentTag"
                  placeholder="Communication Tags..."
                  closeMenuOnSelect={false}
                  options={tagList}
                />

                {/* Contact Tags */}
                <MultiSelect
                  label="Contact Tags"
                  name="contactTags"
                  placeholder="Contact Tags..."
                  closeMenuOnSelect={false}
                  options={tagList}
                />

                {/* Communication Tag Type */}
                <Select
                  label="Content TagType"
                  name="contentTagType"
                  placeholder="Content TagType"
                  options={Constants.Campaign.tagTypeOptions}
                />


                {/* Contact Tag Type */}
                <Select
                  label="Contact TagType"
                  name="contactTagType"
                  options={Constants.Campaign.tagTypeOptions}
                />

                {/* State Date */}
                <Date
                  selected={startDate}
                  label="Start Date"
                  name="startDate"
                  placeholder="MM-DD-YYYY"
                  format="MM-dd-yyyy"
                  onChange={(date) => setStartDate(date)}
                  required
                />

                {/* Assigned Contact secion */}
                <SectionTitle
                  title="Assigned Contacts"
                  description="Assign contact"
                  icon={assignContactIcon}
                  onClick={AssingContactModalOpen}
                />

                {/* Assign contact card */}
                {contactList && contactList.length > 0 ? (
                  contactList.map((contact) => (
                    <div className="mx-3 my-3">
                      {/* Contact Card */}
                      <ContactCard
                        contact={contact}
                        firstName={contact.firstName}
                        lastName={contact.lastName}
                        email={contact.email}
                        openDeleteConfirmationModal={
                          openDeleteConfirmationModal
                        }
                        cardIcon
                      />
                    </div>
                  ))
                ) : (
                  <NoRecordsFound message="No Contact Assigned Yet" hideCard />
                )}
              </div>
            ) : (
              // Drag Content
              <div className="col-lg-4 border p-0">
                <DragContent
                  removeContent={removeContent}
                  disabled={isContentSaveDisabled}
                  contentOnDrag={contentOnDrag}
                  onDragSave={onDragSave}
                  contentList={communicationDetailList}
                  iconHandler={handleAddNewStep}
                />
              </div>
            )}
            {/* Details card */}
            <div className="col-lg-8 border">
              <DetailCard
                showAddNewStep
                isAddNewStep={isAddNewStep}
                handleAddNewStep={handleAddNewStep}
                data={campaignDetail}
                communicationList={selectedCampaignContentList}
                contentOnDropEnd={contentOnDropEnd}
                getContentValues={getContentValues}
                contentData={contentData}
                addedCommunicationIds={addedCommunicationIds}
                isCadenceFieldsDisabled={false}
              />
            </div>
          </div>
        </Form>
      )}
    </>
  );
};

export default EditCampaign;
