import React from "react";

// Components
import Text from "../../../components/Text";
import Email from "../../../components/Email";
import Password from "../../../components/password";
import StatusDropdown from "./StatusDropdown";
import PermissionDropdown from "./PermissionDropdown";

const UserForm = ({ password, FieldLabelStyle, editableField, handleOnchange, editPage, addPage }) => {

  // To render status field based on condition
  function status() {
    if(editPage && editableField) {
      return (
        <StatusDropdown name="status" label="Status"  onChange={handleOnchange} />
      );
    } else if(editPage && !editableField) {
      return (
        <Text
          name="status"
          label="Status"
          placeholder="Status"
          disabled={!editableField}
          labelStyle={FieldLabelStyle}
          borderBottomColor="#CCCCCC" 
          onChange={handleOnchange}
        />
      );
    }
  }

  // To render permission level dropdown
  function permissionLevel() {
    if (addPage) {
      return (
        <PermissionDropdown name="permissionLevel" label="Permission Level" dropDownPosition="bottom" required/>
      );
    } else if(editPage && editableField) {
      return (
        <PermissionDropdown name="permissionLevel" label="Permission Level" onChange={handleOnchange} required/>
      );
    } else if(editPage && !editableField) {
      return (
        <Text
          name="permissionLevel"
          label="Permission Level"
          placeholder="Permission Level"
          disabled={!editableField}
          labelStyle={FieldLabelStyle}
          borderBottomColor="#CCCCCC" 
          onChange={handleOnchange}
          required
        />
      );
    }
  }
  return (
    <>
      <Text
        name="firstName"
        label="First Name"
        placeholder="Enter First Name"
        labelStyle={FieldLabelStyle}
        borderBottomColor="#CCCCCC" 
        disabled={!editableField}
        onChange={handleOnchange}
        required
      />

      {/* Last Name */}
      <Text
        name="lastName"
        label="Last Name"
        placeholder="Enter Last Name"
        disabled={!editableField}
        labelStyle={FieldLabelStyle}
        borderBottomColor="#CCCCCC" 
        onChange={handleOnchange}
        required
      />

      {/* Email */}
      <Email
        name="email"
        label="Email"
        placeholder="Enter Email"
        borderBottomColor="#CCCCCC" 
        disabled={!editableField}
        onChange={handleOnchange}
        required
      />

      {/* Status */}
      {status()}
      {/* PermissionLevel */} 
      {permissionLevel()}

      {/* Password Field */}

      {password && (addPage || editableField) && (
        <Password
          name="password"
          placeholder="Enter a password"
          label="Password"
          autoComplete={"new-password"}
          onInputChange={handleOnchange}
        />
      )}
    </>
  );
};

export default UserForm;