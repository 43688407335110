import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

//Lib
import * as Cookie from "../../lib/Cookie";
import api from "../../lib/Api";
import String from "../../lib/String";

//Component
import Form from "../../components/Form";
import toast from "../../components/Toast";
import PageTitle from "../../components/PageTitle";
//Constant
import { COOKIE } from "../../constants/Cookie";

import LoginForm from "./LoginForm";
import { API_URL } from "../../config";

//Login  Function
const Login = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [screenWidth, setScreenWidth] = useState("");
  const [emailData, setEmailData] = useState("");
  const navigate = useNavigate();
  const location = useLocation();

  //Submit
  const submit = async (values) => {
    const data = {
      username: String.Get(values.email),
      password: values.password,
    };
    const url = `${API_URL}/api/auth/login`;
    try {
      setIsLoading(true);
      await api.postWithoutAccessToken(url, data, (response, err ) => {
        if (err) {
          toast.error("Invalid Credentials");
          console.log(err);
          setIsLoading(false);
        }
        if (response.accessToken) {
          const customer_id = response.customerId;
          const token = response.accessToken;
          const refreshToken = response.refreshToken;
          const userEmail = values.email;

          // set cookies
          Cookie.set(COOKIE.CUSTOMER_ID, customer_id);
          Cookie.set(COOKIE.ACCESS_TOKEN, token);
          Cookie.set(COOKIE.REFRESH_TOKEN, refreshToken);
          Cookie.set(COOKIE.USER_EMAIL, userEmail);

          const accessToken = Cookie.get(COOKIE.ACCESS_TOKEN);

          setIsLoading(false);
          // if access token is set then redirect to dashboard
          if (accessToken) {
            navigate("/contacts");
          } else {
            navigate("/");
          }
        } else {
          toast.error(response.message.includes("JSON") ? "Username or Password is Incorrect" : response.message);
          setIsLoading(false);
        }

        
      });
    } catch (error) {
      return error;
    }
  };

  // session handling
  const accessToken = Cookie.get(COOKIE.ACCESS_TOKEN);

  useEffect(() => {
    setEmail();
    if (accessToken) {
      navigate("/contacts");
    } else {
      navigate("/login");
    }
  }, []);

  function setEmail() {
    let email = location?.state?.email;
    setEmailData(email);
  }

  let isMobile = () => {
    setScreenWidth(window.innerWidth);
  };

  window.addEventListener("resize", isMobile);
  let centerDiv = "vertical-center";

  if (screenWidth > 0 && screenWidth < 500) {
    centerDiv = "";
  } else {
    centerDiv = "vertical-center";
  }

  return (
    <>
      <div
        className={`form-wrapper d-flex justify-content-center ${centerDiv}`}>
        <div className="client-login-wrapper">
          <div className="mb-4">
            {/* pageTitle */}
            <PageTitle
              label="Log In"
              description="Log In by email"
              colorHeader="#707B96"
            />
          </div>
          {/* form Field */}
          <Form
            enableReinitialize={true}
            initialValues={{
              email: emailData ? emailData : "",
              password: "",
            }}
            onSubmit={(values) => {
              submit(values);
            }}>
            {/* login Form*/}
            <LoginForm
              loginButtonLabel={isLoading ? "Logging In" : "Log In"}
              isLoading={isLoading}
            />
          </Form>
        </div>
      </div>
    </>
  );
};
export default Login;
