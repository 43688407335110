import { API_URL } from "../config";

//Customer Contact Delete URL
export const customerContactDelete = (customerId, contactId) => {
  let url = `${API_URL}/api/customer/${customerId}/contact/${contactId}/delete?hardDelete=true`;
  return url;
};

//Customer Contact Update URL
export const customerContactUpdate = (customerId, contactId) => {
  let url = `${API_URL}/api/customer/${customerId}/contact/${contactId}`;
  return url;
};

//Customer Contact Get URL
export const customerContact = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/contact`;
  return url;
};

// get Contact URL

export const getContact = (customerId, contactId) => {
  let url = `${API_URL}/api/customer/${customerId}/contact/${contactId}`;
  return url;
};

//Setting Get URL
export const customerSetting = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/settings`;
  return url;
};

//Setting Update URL
export const customerSettingUpdate = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/settings`;
  return url;
};

//New communication url
export const addNewCommunication = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/content`;
  return url;
};

//Delete communication
export const deleteCommunication = (customerId, communication_id) => {
  let url = `${API_URL}/api/customer/${customerId}/content/${communication_id}/delete`;
  return url;
};

//Update communication
export const updateCommunication = (customerId, communication_id) => {
  let url = `${API_URL}/api/customer/${customerId}/content/${communication_id}`;
  return url;
};

//get communication detail with communication id
export const getCommunication = (customerId, communication_id) => {
  let url = `${API_URL}/api/customer/${customerId}/content/${communication_id}`;
  return url;
};

//get communication detail list
export const communicationDetails = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/content`;
  return url;
};

//New user url
export const addNewUser = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/user`;
  return url;
};

//Delete User list
export const deleteUser = (customerId, personId) => {
  let url = `${API_URL}/api/customer/${customerId}/user/${personId}/delete`;
  return url;
};

//get user detail list
export const userDetails = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/user`;
  return url;
};

//Update User
export const updateUser = (customerId, personId) => {
  let url = `${API_URL}/api/customer/${customerId}/user/${personId}`;
  return url;
};

//get user detail with user persinId
export const getUser = (customerId, personId) => {
  let url = `${API_URL}/api/customer/${customerId}/user/${personId}`;
  return url;
};

// Search user
export const searchUserUrl = (customerId, searchTerm) => {
  let url = `${API_URL}/api/customer/${customerId}/user?search=${searchTerm}`;
  return url;
};

// Reset password
export const resetPassword = () => {
  let url = `${API_URL}/api/public/reset-password`;
  return url;
};

//get program url
export const getProgram = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/campaign`;
  return url;
};

//New program url
export const addNewProgram = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/campaign`;
  return url;
};

//Update User
export const programUpdate = (customerId, programId) => {
  let url = `${API_URL}/api/customer/${customerId}/campaign/${programId}`;
  return url;
};

// Update content in campaign/program
export const campaignContentUpdate = (customerId, programId) => {
  let url = `${API_URL}/api/customer/${customerId}/campaign/${programId}/content`;
  return url;
};

//get program Detail
export const getProgramLists = (customerId, programId) => {
  let url = `${API_URL}/api/customer/${customerId}/campaign/${programId}`;
  return url;
};

//delete program/campaign
export const deleteProgram = (customerId, programId) => {
  let url = `${API_URL}/api/customer/${customerId}/campaign/${programId}/delete`;
  return url;
};

//search communication/content
export const searchCommunication = (customerId, searchTerm) => {
  let url = `${API_URL}/api/customer/${customerId}/content?onlyViewValid=false&search=${searchTerm}`;
  return url;
};

//assign contact to program/campaing
export const assignToContact = (customerId, programId) => {
  let url = `${API_URL}/api/customer/${customerId}/campaign/${programId}/subscriptions`;
  return url;
};

//assign campaign to contact
export const assignToCampaign = (customerId, contactId) => {
  let url = `${API_URL}/api/customer/${customerId}/contact/${contactId}/subscription`;
  return url;
};

//search campaigns
export const searchCampaigns = (customerId, searchTerm) => {
  let url = `${API_URL}/api/customer/${customerId}/campaign?search=${searchTerm}`;
  return url;
};

//country API
export const country = () => {
  let url = `${API_URL}/api/country`;
  return url;
};

//search contact
export const searchContact = (customerId, searchTerm) => {
  let url = `${API_URL}/api/customer/${customerId}/contact?search=${searchTerm}`;
  return url;
};

// Upload csv
export const uploadCSV = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/upload`;
  return url;
};

// Upload csv
export const getTag = (customerId) => {
  let url = `${API_URL}/api/customer/${customerId}/tag`;
  return url;
};