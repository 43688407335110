import React, { useEffect } from "react";

import { isLoggedIn } from "../../common/Helper";
import PageTitle from "../../components/PageTitle";

const Dashboard = () => {
  // check the user is logged in
  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      <PageTitle label="Dashboard" />
    </>
  );
};
export default Dashboard;
