import React from "react";
//components
import PageTitle from "../../components/PageTitle";
import PrivacySettings from "./PrivacySettings";
import Message from "../../components/Message";
///assets
import ExclamationIcon from "../../assets/icons/ExclamationIcon.svg";

const SystemSetting = () => {
  return (
    <>
      <div className="fw-800">
        <PageTitle colorHeader="#707b96" label="Privacy setting" />
        <div className="row mt-5  ">
          <div className="col-4"></div>
          <img src={ExclamationIcon} className="exclamation-icon" />
          <div className="col-4  text-color-secondary">Public</div>
          <div className="col-4  text-color-secondary">Private</div>
        </div>

        <PrivacySettings />
        
        <div className="d-flex mt-5 text-color-secondary">
          <img src={ExclamationIcon} className="setting-icons" />
          <Message
            label="All Bowtie users at a firm can access those contacts"
            textColor="#707b96"
          />
        </div>
      </div>
    </>
  );
};

export default SystemSetting;
