import { COOKIE_ACCESS_TOKEN } from "../constants/Cookie";
import * as Cookie from "../lib/Cookie";

/**
 * To String
 *
 * @param {*} value
 */
export function toString(value) {
  return value ? value : null;
}

/**
 * Is LoggedIn user
 *
 * @param name
 */
export function isLoggedIn(validateUserSession) {
  const currentPath = window.location.pathname + window.location.search;
  let redirectUrl = "";
  if (currentPath) {
    redirectUrl = `?redirect=${currentPath}`;
  }

  if (!Cookie.get(COOKIE_ACCESS_TOKEN) && validateUserSession) {
    // if session_token is null redirect login
    window.location.replace(`/login${redirectUrl}`);
  }
}
