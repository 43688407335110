import React, { Suspense, useEffect } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { navList } from "./NavList";
import SideBar from "../components/sidebarNavigation/SideBar";
//Common
import { isLoggedIn } from "../lib/Helper";

// default layout of the web - Component header, sidebar, and footer will be constant the component will be dynamic
let DefaultLayout = ({
  component: Component,
  showSidebar,
  showUserAvatar,
  validateUserSession,
  showMobileHamburgerMenu
}) => {
  showSidebar = typeof showSidebar != "undefined" ? showSidebar : true;
  showUserAvatar = typeof showUserAvatar != "undefined" ? showUserAvatar : true;
  validateUserSession =
    typeof validateUserSession != "undefined" ? false : true;
  showMobileHamburgerMenu = typeof showMobileHamburgerMenu != "undefined" ? showMobileHamburgerMenu : true;


  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  useEffect(() => {
    isLoggedIn(validateUserSession);
  }, []);

  return (
    <>
      <div className="app base-color">
        <div className="app-body">
          <main
            className="main drawer-container"
            style={{ position: "relative" }}>
            <div>
              {/* Top section section */}
              <Header showUserAvatar={showUserAvatar} showMobileHamburgerMenu={showMobileHamburgerMenu} />

              {/* Main content section */}
              <div className="container-fluid">
                <div className="row">
                  {/* Left sidebar nav section */}
                  {showSidebar && (
                    <SideBar
                      navList={navList}
                      leftNavigationBackgroundColor={"#707B96"}
                      leftNavigationTextColor={"#FFFFFF"}
                      leftNavigationTextHoverColor={"#282268"}
                    />
                  )}

                  {/* Right side content section */}
                  <div className="col overflow-hidden vh-80">
                    <Component />
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>

        {/* Footer */}
        <footer>
          <div>
            <Suspense fallback={loading()}>
              <Footer
                bgColor={"bg-color-primary"}
                copyRightsText={" Bowtie. All rights reserved."}
              />
            </Suspense>
          </div>
        </footer>
      </div>
    </>
  );
};
export default DefaultLayout;
