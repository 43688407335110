import React from "react";
import DefaultLayout from "./DefaultLayout";

// default layout of the web - Component header, sidebar, and footer will be constant the component will be dynamic
const PublicLayout = ({ component: component }) => {
  return (
    <DefaultLayout
      showUserAvatar={false}
      showSidebar={false}
      component={component}
      validateUserSession={true}
      showMobileHamburgerMenu={false}
    />
  );
};
export default PublicLayout;
