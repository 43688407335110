import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import Email from "../../components/Email";
import Text from "../../components/Text";
import Password from "../../components/password";
import Form from "../../components/Form";
import Checkbox from "../../components/CheckBox";
import toast from "../../components/Toast";
import Spinner from "../../components/Spinner";
import PageTitle from "../../components/PageTitle";
import Button from "../../components/Button";
import LinkTo from "../../components/LinkTo";

//Cookie
import * as Cookie from "../../lib/Cookie";

// Config
import { API_URL } from "../../config";

//Constant
import { COOKIE } from "../../constants/Cookie";

// API
import api from "../../lib/Api";

//Icons
import PencilIcon from "../../assets/icons/icon-pencil.svg";

// Common
import { validateStrongPassword } from "../../common/Helper";

const SignUp = () => {
  // states
  const [passwordMismatchError, setPasswordMismatchError] = useState("");
  const [confirmPasswordMismatchError, setConfirmPasswordMismatchError] =
    useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [checkboxValidation, setcheckboxValidation] = useState("");

  // handle form submit values
  const handleSubmit = async (values) => {
    // if the valiateFields retr
    if (validateFields(values) === false) {
      return;
    }

    const data = {};
    data.customerName = values.customerName ? values.customerName : "";
    data.email = values.email ? values.email : "";
    data.password = values.password ? values.password : "";
    try {
      setIsLoading(true);
      const url = `${API_URL}/api/public/register`;
      await api.postWithoutAccessToken(url, data, (res, err) => {
        if (err) {
          toast.error(err);
          console.log(err);
          setIsLoading(false);
        }
        if (res && res.applicationErrorCode) {
          toast.error(res.message);
          setIsLoading(false);
        } else if (res && res.customerId) {
          toast.success("Account Created Successfully");
          if (Cookie.get(COOKIE.ACCESS_TOKEN) !== ("undefined" || undefined)) {
            navigate("/sign-up-done", { state: { email: res?.email }});
          }
          setIsLoading(false);
        }
      });
    } catch (err) {
      throw new Error(err);
    }
  };

  // Validate submitted values from the from
  const validateFields = (values) => {
    try {
      let success = true;
      const currentStep = 1;
      const name = values.customerName;
      const email = values.email;
      const password = values.password;
      const confirmPassword = values.confirmPassword;
      const acceptTerms = values.acceptTerms;

      const passwordError = validateStrongPassword(password);

      switch (currentStep) {
        case 1:
          if (name === (null || "") || email === (null || "")) {
            success = false;
          } else if (passwordError) {
            setPasswordMismatchError(passwordError);
            success = false;
          } else if (
            password &&
            confirmPassword &&
            password !== confirmPassword
          ) {
            setPasswordMismatchError("");
            setConfirmPasswordMismatchError("Confirm password did not match");
            success = false;
          } else if (!acceptTerms) {
            success = false;
            setcheckboxValidation("You must accept the terms and conditions");
            setConfirmPasswordMismatchError("");
          } else {
            setConfirmPasswordMismatchError("");
            setPasswordMismatchError("");
          }
          break;
        default:
          break;
      }

      return success;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="vertical-center">
        <div className="client-login-wrapper">
          <div className="mb-4">
            {/* PageTitle */}
            <PageTitle
              label="Sign Up"
              description=" Enter your information to Sign Up With Bowtie "
              colorHeader="#707B96"
            />
          </div>

          {/* Form validtion*/}
          <Form
            onSubmit={(values) => {
              handleSubmit(values);
            }}
            enableReinitialize={true}
            initialValues={{
              customerName: "",
              email: "",
              password: "",
              confirmPassword: "",
            }}>
            {/* Customer Name  Field */}
            <div className="mb-4" >
              <Text
                name="customerName"
                placeholder="Enter your full name"
                label="Full Name"
                required
              />
            </div>
           

            {/* Email */}
            <div className="mb-4"> 
              <Email
                name="email"
                placeholder="Enter your email"
                label="Email Address"
                required
              />
            </div>
            

            {/* Password Field */}
            <Password
              name="password"
              placeholder="Enter a password"
              label="Password"
              error={passwordMismatchError || ""}
              required
            />

            {/* Confirm Password Field */}
            <Password
              name="confirmPassword"
              placeholder="Re-enter the password"
              label="Confirm Password"
              error={confirmPasswordMismatchError || ""}
              required
            />

            {/* Checkbox for the accepting the terms and conditions*/}
            <Checkbox
              name="acceptTerms"
              className={"validation accepted-terms"}
              label={
                <span>
                  {"I accept the "}
                  <a href="https://www.trybowtie.com">
                  terms and conditions
                  </a>
                </span>
              }
              error={checkboxValidation}
            />

            {/* Submit button */}
            <Button
              className="w-100 mt-4"
              type="submit"
              label="Sign Up"
              progressLabel={
                <Spinner
                  size={12}
                  spinnerStyle={{
                    position: "relative",
                  }}
                />
              }
              disabled={isLoading ? true : false}
              isProgress={isLoading}
              icon={isLoading ? "" : PencilIcon}
            />
            <div className="mt-2 text-color-secondary">
              <span>Have a Bowtie Account? </span>

              {/*   Login redirect link  */}
              <LinkTo url="/" className="link mx-1 hover-link"
                text = "Log In here"
              />
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default SignUp;
