import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown,
} from "reactstrap";


//Styles
import "./Style.scss";

const SelectDropdown = (props) => {
  // To set the values in state
  const [selectedValue, setSelectedValue] = useState({
    selectedValue: props.defaultValue,
  });

  // To set the dropdown status in state
  const [dropdownOpen, setOpen] = useState(false);

  // To set the didMount value in state
  const [didMount, setDidMount] = useState(false);

  // Toggle for dropdown
  const toggle = () => setOpen(!dropdownOpen);
  // Select dropdown props
  const {
    dropdownLabel,
    dropdownLinks,
    menuPosition,
    color,
    textColor,
    width,
    hideCaret,
    selectName,
    getSelectedFilters,
    liveFilters,
    nlFormFilter,
    handleChange,
    id,
    disabled,
    background,
    bgColor,
    size,
    className,
    key,
    center
  } = props;

  // UseEffect
  useEffect(() => setDidMount(true), []);
  useEffect(() => {
    if (didMount && nlFormFilter) {
      getSelectedFilters(selectedValue, selectName);
    }
  }, [selectedValue]);
  useEffect(() => {
    if (didMount && nlFormFilter) {
      if (liveFilters[selectName] !== undefined) {
        setSelectedValue({
          selectedValue: [...liveFilters[selectName].selectedValue],
        });
      }
    }
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      handleChange(props.defaultValue);
    }
  }, []);

  const updateLabel = (e) => {
    setSelectedValue({ selectedValue: [e.target.dataset.label] });
  };

  // Defining the style
  const style = {};
  if (textColor) {
    style.color = `${textColor}`;
  }
  if (width) {
    style.width = `${width}`;
  }
  if (background) {
    style.background = bgColor ? bgColor : "#d7dbe1";
  }
  return (
    <div
      className={`dropdown-wrapper select-dropdown border rounded align-items-center justify-content-end ${className}`}
      style={{ background: bgColor ? bgColor : "#F9F9F9" }}>
      {dropdownLabel && <p className="fw-bold mb-0 mr-2">{dropdownLabel}</p>}
      <ButtonDropdown id={id} isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          key={key}
          id={id}
          color={color}
          size={size}
          className={`dropdown-toggle ${disabled ? "disabled" : ""} ${
            hideCaret ? "hide-caret" : ""
          }`}>
          <span
            className={`d-flex ${center && "justify-content-center"} align-items-center drop-down text-truncate`}
            style={style}>
            {Object.values(selectedValue)[0] !== undefined &&
            Object.values(selectedValue)[0].length > 0
              ? Object.values(selectedValue)[0]
              : props.buttonLabel}

            {/* {!hideChevron && <ChevronDown />} */}
          </span>
        </DropdownToggle>
        <DropdownMenu right={menuPosition} className="select-drop-down">
          {dropdownLinks
            .filter((listItem) => listItem.placeholder === undefined)
            .map((listItem) => (
              <DropdownItem
                active={selectName}
                className="overflow-hidden"
                onClick={(e) => {
                  updateLabel(e);

                  handleChange && handleChange(e.target.dataset.value);
                }}
                data-value={listItem.value}
                data-label={listItem.label}
                data-name={selectName}
                key={listItem.value}
                id={listItem.value}>
                {listItem.label}
              </DropdownItem>
            ))}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

// Default props for select dropdowns
SelectDropdown.defaultProps = {
  buttonLabel: "Button Label",
  color: "",
  hideCaret: false,
  dropdownLinks: [
    { label: "Link 1", value: "link1" },
    { label: "Link 2", value: "link2" },
    { label: "Link 3", value: "link3" },
  ],
  menuPosition: true,
};

SelectDropdown.propTypes = {
  buttonLabel: PropTypes.string,
  color: PropTypes.string,
  hideCaret: PropTypes.bool,
  dropdownLinks: PropTypes.array,
  menuPosition: PropTypes.bool,
  onClick: PropTypes.func,
  selectName: PropTypes.string.isRequired,
  getSelectedFilters: PropTypes.func,
};

export default SelectDropdown;
