import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import {
  FormGroup,
  Input,
  FormFeedback,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Label from "../Label";
import RenderInputGroup from "../RenderInputGroup";
import Hint from "../Hint";

// Lib
import ArrayList from "../../lib/ArrayList";

// Style
import "./Style.scss";

class Text extends React.Component {
  constructor(props) {
    super(props);
    // To set values in state
    this.state = {
      inputValue: "",
    };
  }

  // Validate Text field
  validate(value) {
    // Text field props
    const { label, placeholder, required, error } = this.props;

    // Defining error message
    let errorMessage;
    // Defining input label
    const inputLabel = label || placeholder;
    // Assigning the error value to error message label
    const errorMessageLabel = error;

    // Defining the error validation message when field is required
    if (required && (!value || !value.trim())) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, setFieldValue } }) {
    // Text field props
    const {
      name,
      id,
      label,
      placeholder,
      required,
      onChange,
      type,
      maxLength,
      showCharCount,
      addonText,
      addontype,
      onKeyDown,
      onClick,
      disabled,
      autoFocus,
      autoComplete,
      className,
      ref,
      onKeyPress,
      hintText,
      value,
      labelStyle,
    } = this.props;

    // Defining the error message
    let errorMessage = touched[name] && errors[name] ? errors[name] : null;

    // Defining the input Id
    const inputId = id || name;

    // Length of input characters
    const countInputChars = () => {
      if (this.state.inputValue !== undefined) {
        return this.state.inputValue.length;
      }
      return 0;
    };

    // To set the input value
    const setInputValue = (e) => {
      const { value } = e.target;
      this.setState({
        inputValue: value,
      });
    };

    let fieldValue = ArrayList.isArray(field.value) ? field.value.toString() : field.value;
    if(field?.value?.label) {
      fieldValue = field?.value?.label;
    }

    return (
      <FormGroup 
        style={{ position: "relative" }}
        className={`${className} ${(!!errorMessage && "is-invalid") || ""}`}>
        {label && (
          <Label
            className="fw-700"
            id={inputId}
            required={required}
            style={labelStyle}>
            {label}
          </Label>
        )}
        {disabled ? (
          <div className={`outline-field-style px-1 py-2 ${!fieldValue && "text-secondary"}`}>
            {fieldValue ? fieldValue : <span className="invisible">{(placeholder || `Enter ${label}`)}</span>}
          </div>
        ) : (
          <RenderInputGroup
            condition={addonText !== null && addonText !== undefined}
            wrapper={(children) => <InputGroup>{children}</InputGroup>}>
            {addonText && (
              <InputGroup addontype={addontype}>
                <InputGroupText>{addonText}</InputGroupText>
              </InputGroup>
            )}
            <Input
              id={inputId}
              {...field}
              type={type || "text"}
              placeholder={placeholder || `Enter ${label}`}
              invalid={!!errorMessage}
              defaultValue={value}
              onKeyPress={onKeyPress}
              onKeyUp={(e) => {
                setInputValue(e);
                onChange && onChange(e);
                setFieldValue(name, e.target.value);
              }}
              onKeyDown={onKeyDown}
              maxLength={maxLength && maxLength ? maxLength : "255"}
              onClick={onClick && onClick}
              disabled={disabled}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              ref={ref}
            />
          </RenderInputGroup>
        )}
        {/* Showing the character count message */}
        {showCharCount && (
          <span className="char-count d-block text-inline-grayed h7">
            {`${countInputChars()}/${maxLength || "255"} Characters`}
          </span>
        )}

        {/* Displaying the hint message */}
        {hintText && (
          <div className="hint" >
            <Hint hintText={hintText} />
          </div>
        )}

        {/* Displaying the error message */}
        {errorMessage && (
          <FormFeedback
            id={inputId + "Error"}
            className = "form-feedback">
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    // Text props
    const { name } = this.props;

    return (
      <Field
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

Text.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.any,
  addonText: PropTypes.string,
  addontype: PropTypes.oneOf(["prepend", "append"]),
};

export default Text;
