import React from "react";

//Helper
import { getCurrentYear } from "../../lib/DateTime";

//Styles
import "./Style.scss";

const Footer = (props) => {
  const { bgColor, textColor, copyRightsText } = props;

  return (
    <div className="container-fluid">
      <div className="row">
        <div
          className={`footer-text p-3 ${bgColor ? bgColor : "bg-color-primary"} ${textColor ? textColor : "text-white"}`}
        >
          <div>
            <span>
              &copy; {getCurrentYear()}
              {copyRightsText ? copyRightsText : ""}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
