import React from "react";
import closeIcon from "../../../assets/icons/closeIcon.png";
import Avatar from "../../../components/Avatar";
//helpers
import { GetDisplayName } from "../../../helpers/Contact";

function ContactCard(props) {
  // Props
  const {
    firstName,
    lastName,
    email,
    cardIcon,
    openDeleteConfirmationModal,
    contact,
  } = props;

  return (
    <div className="d-flex align-items-center p-2 bg-white-smoke contact-card">
      {/* Close Icon section */}
      {cardIcon && (
        <div
          onClick={() =>
            openDeleteConfirmationModal && openDeleteConfirmationModal(contact)
          }>
          <img src={closeIcon} alt="closeIcon" className="img-15" />
        </div>
      )}

      {/* Avatar section */}
      <div className="px-2">
        <Avatar
          firstName={firstName}
          lastName={lastName}
          email={email}
          bgSecondary
          square
          size="sm"
        />
      </div>

      {/* Name and Email display section*/}
      <div className="d-flex flex-column">
        <div className="fw-700 mx-1 my-0 assign-contact-card-name">
          {GetDisplayName(firstName + lastName)}
        </div>
        <p className="text-color-secondary m-0 p-0 assign-contact-card-name">{email}</p>
      </div>
    </div>
  );
}

export default ContactCard;
