import React from "react";

// Components
import Select from "../../../components/Select";

// Constants
import { UserStatusOptions } from "../../../constants/User";

const StatusDropdown = ({ name, label, onChange }) => {

  return (
    <>
      <Select
        name={name}
        label={label}
        options={UserStatusOptions}
        onInputChange={onChange}
        required
      />
    </>
  );
};

export default StatusDropdown;