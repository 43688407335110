
export const PhoneType = [

  {
    value: "HOME",
    label: "Home",
  },
  {
    value: "WORK",
    label: "Work",
  },
  {
    value: "MOBILE",
    label: "Mobile",
  },
];
