import React, { useState } from "react";
import { Button } from "reactstrap";

//Icons
import AssignCampaignIcon from "../../../assets/img/assign_icon.png";
import DeleteModal from "../../../components/ConfirmationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";

const BottomButton = ({ handleDelete, toggleCampaignModel, EditContact, disabled }) => {
  // To set value in state
  const [isOpen, setIsOpen] = useState(false);

  // Toggle for modal
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // Handle function for toggling
  const handleFunction = () => {
    toggle();
  };

  return (
    <div className="d-flex col-4">
      {/* Assign to Campaign */}
      <Button
        className="col-12 col-sm-12 col-lg-12 col-md-12 action-button-section"
        onClick={() => toggleCampaignModel()}>
        <div className="actionButton-section d-flex align-items-center gap-2">
          <img src={AssignCampaignIcon} className="actionButton-img" />
          <span className="mt-1 actionButton-Assign">Assign to Campaign</span>
        </div>
      </Button>

      {/* Edit Contact */}
      <Button
        className="col-12 col-sm-12 col-lg-12 col-md-12 action-button-section"
        onClick={() => EditContact && EditContact()}>
        <div className="actionButton-section d-flex align-items-center gap-2">
          <FontAwesomeIcon icon={faPencil} />
          <span className="actionButton-Edit">Edit Contact</span>
        </div>
      </Button>

      {/* Delete Contact */}
      <Button
        className="col-12 col-sm-12 col-lg-12 col-md-12 action-button-section"
        onClick={(e) => {
          e.preventDefault();
          handleFunction();
        }}>
        <div className="actionButton-section d-flex align-items-center gap-2">
          <FontAwesomeIcon icon={faTrash} />
          <span className=" actionButton-Edit">Delete Contact</span>
        </div>
      </Button>

      {/* Delete Modal */}
      <DeleteModal
        isOpen={isOpen}
        toggle={handleFunction}
        title="Delete contact"
        primaryButtonText="Delete contact"
        secondaryButtonText="Cancel"
        description="Would you like to delete this contact permanently?"
        onClickNo={handleFunction}
        onClickYes={handleDelete}
        disabled={disabled}
      />
    </div>
  );
};

export default BottomButton;
