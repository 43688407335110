import React, { useEffect, useState } from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

//components
import Avatar from "../../../components/Avatar";
import NoRecordsFound from "../../../components/NoRecordsFound";
import Search from "../../../components/Search";
import AssignButton from "../../../components/AssignButton";

//constant
import DiscardButton from "../../../components/DiscardButton";

// ContactAPI
import ContactAPI from "../../../api/ContactAPI";
import CampaignAPI from "../../../api/Campaign";

import ConfimationModal from "../../../components/ConfirmationModal";

import { SubscriptionStatus } from "../../../constants/SubscriptionStatus";

//helpers
import { GetDisplayName } from "../../../helpers/Contact";

const AssignToContact = (props) => {
  // Set values in state
  const [contactList, setContactList] = useState([]);
  const [filteredContactList, setFilteredContactList] = useState([]);
  const [selectedContactIds, setSelectedContactIds] = useState([]);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [assignedContactLenth, setAssingedContactLength] = useState(0);
  //props
  const { id, isOpen, toggle, programId, setNewCampaignContacts, newCampaignContact } = props;

  // UseEffect
  useEffect(() => {
    getContactList();
  }, [isOpen]);

  useEffect(() => {
    getFilteredContactList();
  }, [contactList]);

  // TO get the filtered contact list
  const getFilteredContactList = () => {
    //create new filtered contact list array
    let filteredContactList = new Array();

    //validate the contact list lenth
    if (contactList && contactList.length > 0) {
      //loop the contact list
      for (let i = 0; i < contactList.length; i++) {

        //destructure the suubscription data
        let { campaignSubscriptions, contact } = contactList[i];

        if (programId) {
          //create variable is subscribed false
          let isSubscribed = false;
          //get subscription object
          let subscriptionObject = campaignSubscriptions?.subscriptionMap;
          //loop the objct
          for (let key in subscriptionObject) {
            //compare current campaing ID and subscription campaign Id
            if (key == programId) {
              //get subscription object
              let subscriptionData = subscriptionObject[key];
              //valudate this contact suubscribed by the campaign or not
              if (
                subscriptionData?.subscription == SubscriptionStatus.SUBSCRIBED
              ) {
                isSubscribed = true;
              }
            }
          }
          //validate this campaign not subscribed or not
          if (!isSubscribed) {
            filteredContactList.push(contact);
          }
        } else if (newCampaignContact) {
          if (newCampaignContact.indexOf(contact.contactId) == -1) {
            filteredContactList.push(contact);
          }

        }
      }
    }
    //set the filtered contact list
    setFilteredContactList(filteredContactList);
  };

  //get contact list
  const getContactList = () => {
    ContactAPI.getContactList((response) => {
      setContactList(response);
    });
  };

  //get contact search results
  const searchContact = (event) => {
    //get search term
    let searchTerm = event?.target?.value.trim();
    //get search list
    ContactAPI.searchContactList(searchTerm, (response) => {
      //set search list
      setContactList(response);
    });
  };

  //to handle check box change
  const selectContact = (e, contactId) => {
    //validate check box checked or not
    if (e.target.checked) {
      setSelectedContactIds((selectedContactIds) => [
        ...selectedContactIds,
        contactId && contactId,
      ]);
    } else {
      // remove the values form the array after uncheck
      let updatedContactIds =
        selectedContactIds.length > 0 &&
        selectedContactIds.filter((id) => id !== contactId);
      setSelectedContactIds(updatedContactIds);
    }
  };

  //assign contact function
  const assignContactToCampaign = async () => {
    if (programId && selectedContactIds.length > 0) {
      //append assigned contact length
      setAssingedContactLength(selectedContactIds.length);
      let body = {
        contactIds: selectedContactIds,
        subscription: "SUBSCRIBED",
      };

      //assign contact to campaign
      CampaignAPI.assignContact(programId, body, async () => {
        setSelectedContactIds([]);
        getContactList();
        toggle(true);
        openContactAssignSuccessModal();
      });
    } else {
      setNewCampaignContacts && setNewCampaignContacts((oldArray) => [
        ...oldArray,
        ...selectedContactIds
      ]);
      setSelectedContactIds([]);
      getContactList();
      toggle(true);
    }
  };

  // To Open the contact assign success Modal
  const openContactAssignSuccessModal = () => {
    setOpenSuccessModal(!openSuccessModal);
  };

  // To Close the contact assign success Modal
  const closeAssignContactSuccessModal = () => {
    setAssingedContactLength(0);
    setOpenSuccessModal(!openSuccessModal);
  };

  // Discard/close model
  const discardModel = () => {
    toggle();
    setSelectedContactIds([]);
  };

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [isOpen]);

  return (
    <>
      {/* confirmation Modal */}
      <ConfimationModal
        isOpen={openSuccessModal}
        toggle={openContactAssignSuccessModal}
        title={`${assignedContactLenth} Contact Assigned`}
        primaryButtonText="Done"
        description={`You assigned ${assignedContactLenth} ${assignedContactLenth == 1 ? `contact` : `contacts`} successfully!`}
        onClickYes={closeAssignContactSuccessModal}
      />

      {/* Modal for Assign Contact */}
      <Modal
        id={id}
        isOpen={isOpen}
        toggle={toggle}
        centered
        className="position-relative"
        size="md"
        backdrop="static"
        scrollable={true}>
        {/* Header */}
        <ModalHeader className="bg-color-primary">
          <span className="fw-800 text-white">Select a Contact</span>
        </ModalHeader>

        {/* Body */}
        <ModalBody className="px-0">
          <p className="px-2 fw-400 color-dark-gray">
            Select a contact of more form the list below
          </p>
          {/* Search programs */}
          <Search
            classnames="page-search gx-0"
            placeholder="Try to find contacts"
            onChange={searchContact}
          />

          {/* contact List */}
          {filteredContactList && filteredContactList.length > 0 ? (
            filteredContactList?.map((data) => (
              <div className="assignContact border">
                <div className="assignContact-checkbox mx-3 d-flex align-items-center gap-1">
                  <label className="checkbox">
                    <input
                      id={data?.contactId}
                      name={data?.firstName + " " + data?.lastName}
                      type="checkbox"
                      value={data?.firstName + " " + data?.lastName}
                      onClick={(e) => selectContact(e, data?.contactId)}
                      checked={selectedContactIds.includes(data?.contactId)}
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div className="assignContact-avatar">
                    {/* Showing contact avatar */}
                    <Avatar
                      firstName={data.firstName}
                      lastName={data.lastName}
                      borderRadius={3}
                      email={data.email}
                    />
                  </div>
                  <div className="row assigncontact-name">
                    {/* Displaying Name/Email in the assign contact moadl */}
                    <span className="fw-700">

                      {GetDisplayName(data?.firstName, data?.lastName)}
                    </span>

                    {(data.firstName || data.lastName || data.email) && (
                      <span>
                        {data?.email ? data.email : ""}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <NoRecordsFound message="No Contacts Found" hideCard />
          )}
        </ModalBody>

        <ModalFooter className="row gx-0 p-0">
          <div className="row gx-0 m-0">
            <div className="col-6">
              <AssignButton
                label="Assign"
                fullWidth
                noRadius
                onClick={assignContactToCampaign}
                isChecked={selectedContactIds.length > 0}
              />
            </div>

            <div className="col-6">
              <DiscardButton noRadius onClick={discardModel} />
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AssignToContact;
