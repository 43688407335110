import React, { Component } from "react";
import { Modal } from "reactstrap";
import Button from "../Button";
import CancelButton from "../CancelButton";

//Styles
import "./Style.scss";

export default class DiscardModal extends Component {
  constructor(props) {
    super(props);
  }
  render() {
    // Defining props
    const {
      title,
      description,
      isOpen,
      toggle,
      onClickYes,
      onClickNo,
      primaryButtonText,
      secondaryButtonText,
      disabled
    } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        centered
        style={{ maxWidth: "350px" }}>
        <div className="mb-3 py-2 px-3 bg-color-primary">
          <div className="font-weight-bold text-white mt-2 fw-700">
            {title}
          </div>
        </div>
        <div style={{ minHeight: "124px" }} className="px-3">
          <p className="pt-1">{description}</p>
          <div className="d-flex pt-1">
            <Button
              onClick={() => {
                onClickYes && onClickYes();
              }}
              label={primaryButtonText}
              disabled={disabled}
            />

            {secondaryButtonText && (
              <CancelButton
                onClick={() => {
                  onClickNo && onClickNo();
                }}
                label={secondaryButtonText}
              />
            )}
          </div>
        </div>
      </Modal>
    );
  }
}
