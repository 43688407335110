import React from 'react';

//lib
import BlobObj from '../../lib/Blob';

//Styles
import "./Style.scss";

const HtmlPreview = ({ content }) => {

  function objUrl() {
    let blob = new Blob([content], {type: "text/html"});
    let url = BlobObj.isBlob(blob) && URL.createObjectURL(blob);
    return url;
  }
  

  function renderContent() {
    if (content.includes("</")) {
      return <iframe src={objUrl()} className="iframe"></iframe>;
    } else {
      return <pre className="position-relative" dangerouslySetInnerHTML={{__html: content}}></pre>;
    }
  }
  return (
    renderContent()
  );
};

export default HtmlPreview;