import React from "react";
import Avatar from "../../../components/Avatar";
import * as DateTime from "../../../lib/DateTime";
import contentListIcon from "../../../assets/img/content_icon.png";
import ContentList from "./ContentList";

const DetailCard = ({ 
  data, 
  getContentValues, 
  communicationList, 
  handleAddNewStep, 
  isAddNewStep, 
  showAddNewStep, 
  contentOnDropEnd, 
  contentData,
  addedCommunicationIds ,
  isCadenceFieldsDisabled
}) => {
  return (
    <div className="card mt-2 m-3">
      {/* Heading */}
      <div className="d-flex align-items-center mt-3">
        <div className="col-lg-1 px-2">
          <Avatar firstName={data?.name} bgColor={"#1b3165"} />
        </div>

        <div className="col-lg-3 mx-2 assigncontact-name">
          <span className="fw-700">{data?.name}</span>
          <div>
            <img src={contentListIcon} className="detailCard-img mb-1" />
            <span className="mx-1 fw-500 text-color-secondary">{`${communicationList?.length} ${communicationList?.length > 1 ? "Contents" : "Content"}`}</span>
          </div>
        </div>

        <div className="col-8">
          <span className="fw-700 text-color-secondary">Created in</span>
          <span className="mx-1 fw-400 text-color-secondary">{DateTime.formatNormalDate(data?.dateAdded)}</span>
        </div>
      </div>

      {/* Content list */}
      <ContentList 
        showAddNewStep = {showAddNewStep} 
        handleAddNewStep = {handleAddNewStep} 
        isAddNewStep={isAddNewStep} 
        communicationList={communicationList} 
        contentOnDropEnd={contentOnDropEnd} 
        campaignId={data?.programId} 
        getContentValues={getContentValues}
        contentData={contentData}
        addedCommunicationIds={addedCommunicationIds}
        isCadenceFieldsDisabled= {isCadenceFieldsDisabled}
      />

    </div>
  );
};

export default DetailCard;