import React from "react";
//components
import Button from "../../components/Button";
import PageTitle from "../../components/PageTitle";
import Message from "../../components/Message";

//assets
import CheckMark from "../../assets/icons/checkMarkIcon.svg";

const PasswordChangeDone = () => {
  return (
    <div className="vertical-center">
      <div className="client-login-wrapper">
        <PageTitle label="Reset Password is Created!" colorHeader=" #707B96" />

        <Message
          className="fw-400 mt-2"
          label="A new passowrd is created."
          textColor="#707B96"
        />
        <Button
          className="w-100 mt-3"
          label="Done"
          type="submit"
          icon={CheckMark}
        />
      </div>
    </div>
  );
};

export default PasswordChangeDone;
