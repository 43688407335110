/* eslint-disable */

import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";

//Components
import PageTitle from "../../components/PageTitle";
import Breadcrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/ConfirmationModal";
import AssignToCampaign from "./components/AssignToCampaign";
import ContactList from "./components/ContactList";
import Search from "../../components/Search";
import toast from "../../components/Toast";
import FileSelectButton from "../../components/FileSelectButton";
//constants
import { UNAUTHORIZED_ERROR } from "../../constants/Common";

// API
import campaignAPI from "../../api/Campaign";
import ContactAPI from "../../api/ContactAPI";

// Assets
import { CampaignIcon1 } from "../../assets/icons";
import { CampaignIconRed } from "../../assets/icons";
import { DeleteIcon } from "../../assets/icons";
import { DeleteIconRed } from "../../assets/icons";

// Common
import { isLoggedIn } from "../../common/Helper";
import { VectorFileUpload } from "../../assets/icons";

// Helper
import String from "../../lib/String";
import Toast from "../../components/Toast";

//lib
import ArrayList from "../../lib/ArrayList";
import { HTTP_STATUS } from "../../lib/http";
import LinkTo from "../../components/LinkTo";
import AddButton from "../../components/AddButton";

const Contact = () => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedContactList, setSelectedContactList] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAssignCampaignModalOpen, setIsAssignCampaignModalOpen] =
    useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignIds, setCampaignIds] = useState([]);
  const [isChecked, setIsChecked] = useState(false);
  const [contactId, setContactId] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchItem, setSearchItem] = useState(false);
  const [search, setSearch] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [selectedContactDetail, setSelectedContactDetail] = useState("");
  const [searchName, setSearchName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    isLoggedIn();
    getContactList();
  }, []);

  useEffect(() => {
    getCampaignList();
  }, [isAssignCampaignModalOpen]);

  const getContactList = async () => {
    try {
      setIsLoading(true);
      //get contact list
      await ContactAPI.getContactList((response) => {
        setContactList(response);
        setIsLoading(false);
      });
    } catch (err) {
      console.log(err);
    }
  };
  const getCampaignList = async () => {
    await campaignAPI.getProgramList("", (res) => {
      setCampaignList(res);
      setTempList(res);
    });
  };

  //Delete Contact
  const handleDelete = () => {
    setIsDisabled(true);
    //get customerIId
    if (selectedContactList && selectedContactList.length > 0) {
      for (let i = 0; i < selectedContactList.length; i++) {
        ContactAPI.deleteContact(selectedContactList[i], "", (res) => {
          let { response } = res;
          if (i == selectedContactList.length - 1) {
            if (res && res.status == HTTP_STATUS.RESPONSE_SUCCESS) {
              handleDeleteModal();
              setSelectedContactList([]);
              getContactList();
              setDisabled(true);
              toast.success("Contact Deleted Successfully");
            } else if (
              parseInt(response.status) >= HTTP_STATUS.RESPONSE_ERROR
            ) {
              toast.error(response?.data?.message);
            }

            //  Getting contact list after delete
            ContactAPI.searchContactList(searchName, (res) => {
              setContactList(res);
              if (searchName) {
                setIsSearching(true);
              } else {
                setIsSearching(false);
              }
            });
          }
        });
      }
    }
  };

  const assignCampaign = () => {
    handleAssignTocampaignModal();
  };

  // to handle on click of checkbox
  let SelectContact = (details) => {
    let contactObject = details?.contact;
    try {
      if (details) {
        let selectedContact = selectedContactList;
        const cotactFound = selectedContact.find(
          (data) => data == contactObject?.contactId
        );
        if (!cotactFound) {
          setSelectedContactDetail(details);
          selectedContact.push(contactObject?.contactId);
          setDisabled(true);
          if (selectedContact) {
            setSelectedContactList(selectedContact);
            setContactId(selectedContact);
            setDisabled(false);
          }
        } else {
          const result = selectedContact.filter(
            (data) => data != contactObject?.contactId
          );
          if (result) {
            setSelectedContactList(result);
            setDisabled(true);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const handleAssignTocampaignModal = () => {
    setIsAssignCampaignModalOpen(!isAssignCampaignModalOpen);
    setCampaignIds([]);
  };

  //to handle check box change
  const handleChange = (e) => {
    let selectedValue = e.target.id;
    if (e.target.checked) {
      let id = e.target.id;
      setCampaignIds((data) => [...data, id && id]);
      setIsChecked(true);
    } else {
      setCampaignIds(() => {
        // remove the values form the array after uncheck
        let remainingValues =
          campaignIds.length > 0 &&
          campaignIds.filter((id) => id !== selectedValue);
        setCampaignIds(remainingValues ? remainingValues : []);
        ArrayList.isEmpty(remainingValues) && setIsChecked(false);
      });
    }
  };

  //handle Search
  const handleSearch = async function (e) {
    let searchTerm = String.Get(e.target.value);
    setSearchItem(searchTerm);
    setSearch(true);
    campaignAPI.searchProgram(searchTerm, (res) => {
      if (res) {
        setCampaignList(res);
      }
      if (searchTerm) {
        setSearch(true);
      } else {
        setSearch(false);
      }
    });
  };

  //Breadcumb list
  const breadcrumbList = [{ label: "Contacts" }];

  // Discard Campaign
  const discardCampaign = () => {
    setCampaignList(tempList);
    setIsAssignCampaignModalOpen(false);
  };

  // Handle search
  const handleContactSearch = (searchTerm) => {
    setSearchName(searchTerm);
    try {
      ContactAPI.searchContactList(searchTerm, (res) => {
        setContactList(res);
        if (searchTerm) {
          setIsSearching(true);
        } else {
          setIsSearching(false);
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const debounceFunction = useCallback(
    _.debounce(handleContactSearch, 500),
    []
  );

  const searchContact = (e) => {
    debounceFunction(String.Get(e.target.value));
  };

  // Handle File Choosen
  const handleFileChosen = (e) => {
    let files = e.target && e.target.files[0];
    handleCSV(files);
    e.target.value = null;
  };

  // Send csv to api
  const handleCSV = (csvFile) => {
    // Forming data
    const data = new FormData();
    data.append("name", csvFile?.name);
    data.append("file", csvFile);

    // Api
    ContactAPI.uploadCSV(data, (res) => {
      if (res) {
        getContactList();
        Toast.success("Contacts uploaded successfully");
      }
    });
  };

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={breadcrumbList} />

      {/* Assigning Campaign to the contact/contacts */}
      <AssignToCampaign
        campaignList={campaignList}
        selectedContactList={selectedContactList}
        handleChange={handleChange}
        handleSearch={handleSearch}
        campaignIds={campaignIds}
        isOpen={isAssignCampaignModalOpen}
        toggle={handleAssignTocampaignModal}
        isChecked={isChecked}
        discardCampaign={discardCampaign}
        page="contactList"
        selectedContactDetail={selectedContactDetail}
        getContactDetails={getContactList}
        setSelectedContactList={setSelectedContactList}
        search={search}
      />

      {/* A component to add title of the page with a button included */}

      <div className="d-flex justify-content-between">
        <PageTitle
          label={`All ${
            contactList.length > 0 ? contactList.length : ""
          } Contacts`}
        />
        <div className="mt-2 d-flex flex-wrap">
          <div className="d-none d-sm-block d-md-block d-lg-block">
            <AddButton
              label={"Add New Contact"}
              targetUrl={"/contact/add"}
              uploadButton={true}
              handleFileChosen={handleFileChosen}
              disabled={disabled}
              className={`float-lg-right page-title-button border border-primary btn h6-5-important fw-bold text-nowrap`}
            />
          </div>
          <div className="d-block d-sm-none d-md-none d-lg-none">
            <AddButton
              targetUrl={"/contact/add"}
              uploadButton={true}
              handleFileChosen={handleFileChosen}
              disabled={disabled}
              className={`float-lg-right page-title-button border border-primary btn h6-5-important fw-bold text-nowrap`}
            />
          </div>
          <div className="d-none d-sm-block d-md-block d-lg-block">
            <FileSelectButton
              onChange={handleFileChosen}
              accept=".csv"
              label="Import"
            />
          </div>
          <div className="d-block d-sm-none d-md-none d-lg-none">
            <FileSelectButton
              onChange={handleFileChosen}
              accept=".csv"
              className="file-upload"
            />
          </div>

          {/* Assign campaign Modal */}
          <div className="d-none d-sm-block d-flex mx-1">
            <div className="m-1">
              <LinkTo
                icon={
                  selectedContactList?.length > 0 ? (
                    <CampaignIconRed />
                  ) : (
                    <CampaignIcon1 />
                  )
                }
                style={{
                  cursor: ArrayList.isNotEmpty(selectedContactList)
                    ? "pointer"
                    : "not-allowed",
                }}
                onClick={(e) => {
                  selectedContactList && selectedContactList?.length > 0
                    ? assignCampaign(e)
                    : null;
                }}
              />
              <small
                className={`section-button m-1 ${
                  ArrayList.isNotEmpty(selectedContactList)
                    ? "text-true-blue"
                    : "text-dark-gray"
                }`}
                style={{
                  cursor: ArrayList.isNotEmpty(selectedContactList)
                    ? "pointer"
                    : "not-allowed",
                }}
                onClick={(e) => {
                  selectedContactList && selectedContactList?.length > 0
                    ? assignCampaign(e)
                    : null;
                }}>
                Assign to Campaign
              </small>
            </div>
          </div>

          {/* Assign Campaign in Mobile view */}

          <div className="d-block d-sm-none d-flex mx-1">
            <div className="m-1">
              <LinkTo
                icon={
                  selectedContactList?.length > 0 ? (
                    <CampaignIconRed />
                  ) : (
                    <CampaignIcon1 />
                  )
                }
                style={{
                  cursor: ArrayList.isNotEmpty(selectedContactList)
                    ? "pointer"
                    : "not-allowed",
                }}
                onClick={(e) => {
                  selectedContactList && selectedContactList?.length > 0
                    ? assignCampaign(e)
                    : null;
                }}
              />
            </div>
          </div>

          {/* Delete Contact */}
          <div className="d-none  d-sm-block d-flex mx-1">
            <div className="mt-1">
              <LinkTo
                icon={
                  selectedContactList?.length > 0 ? (
                    <DeleteIconRed />
                  ) : (
                    <DeleteIcon />
                  )
                }
                style={{
                  cursor: ArrayList.isNotEmpty(selectedContactList)
                    ? "pointer"
                    : "not-allowed",
                }}
                onClick={(e) =>
                  selectedContactList && selectedContactList?.length > 0
                    ? handleDeleteModal(e)
                    : null
                }
              />
              <small
                className={`section-button m-1 ${
                  ArrayList.isNotEmpty(selectedContactList)
                    ? "text-true-blue"
                    : "text-dark-gray"
                }`}
                style={{
                  cursor: ArrayList.isNotEmpty(selectedContactList)
                    ? "pointer"
                    : "not-allowed",
                }}
                onClick={(e) =>
                  selectedContactList && selectedContactList?.length > 0
                    ? handleDeleteModal(e)
                    : null
                }>
                Delete Contact
              </small>
            </div>
          </div>

          {/* Delete Contact in Mobile view */}

          <div className="d-block d-sm-none d-flex mx-1">
            <div className="mt-1">
              <LinkTo
                icon={
                  selectedContactList?.length > 0 ? (
                    <DeleteIconRed />
                  ) : (
                    <DeleteIcon />
                  )
                }
                style={{
                  cursor: ArrayList.isNotEmpty(selectedContactList)
                    ? "pointer"
                    : "not-allowed",
                }}
                onClick={(e) =>
                  selectedContactList && selectedContactList?.length > 0
                    ? handleDeleteModal(e)
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>

      <div className="d-flex  align-items-center col-12">
        <div className="col-12 pb-3">
          <Search
            classnames="page-search"
            placeholder="Search Contacts..."
            borderClassName={"setting-page"}
            pageName={"setting-page"}
            onChange={searchContact}
          />
        </div>
      </div>
      <DeleteModal
        title="Delete contact"
        primaryButtonText="Delete contact"
        secondaryButtonText="Cancel"
        description={
          selectedContactList && selectedContactList.length > 1
            ? "Would you like to delete these contacts permanently?"
            : "Would you like to delete this contact permanently?"
        }
        isOpen={deleteModal}
        toggle={handleDeleteModal}
        onClickNo={handleDeleteModal}
        onClickYes={handleDelete}
        disabled={isDisabled}
      />

      {/* Contact List  */}
      <ContactList
        contactList={contactList}
        isLoading={isLoading}
        SelectContact={SelectContact}
        checkedValue={selectedContactList}
        isSearching={isSearching}
      />
    </>
  );
};

function mapStateToProps(state) {
  return state.table;
}

export default connect(mapStateToProps)(Contact);
