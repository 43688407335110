import React from "react";

//assets
import messageIcon from "../../../assets/img/message.png";
import PlusIcon from "../../../assets/img/plus-icon.png";

// Components
import Button from "../../../components/Button";
import SelectDropdown from "../../../components/SelectDropdown";
import NumberField from "../../../components/NumberField";

// Lib
import ArrayList from "../../../lib/ArrayList";
import String from "../../../lib/String";
import Number from "../../../lib/Number";

const ContentCard = ({ 
  getContentValues, 
  communicationList, 
  handleAddNewStep, 
  isAddNewStep, 
  showAddNewStep, 
  contentOnDropEnd, 
  contentData,
  addedCommunicationIds,
  isCadenceFieldsDisabled 
}) => {

  const triggerOptions = [
    {
      label: "From Beginning",
      value: "FROM_BEGINNING"
    },
    {
      label: "From Previous",
      value: "FROM_PREVIOUS"
    }
  ];

  const FirstContentTriggerOptions = [
    {
      label: "From Beginning",
      value: "FROM_BEGINNING"
    },
  ];

  const unitOptions = [
    {
      label: "Days",
      value: "DAYS"
    },
    {
      label: "Months",
      value: "MONTHS"
    }
  ];

  function getDetail(value, communicationId) {
    let data = ArrayList.isNotEmpty(contentData) && contentData?.filter(content => content.communicationId == communicationId).find(data => Object.keys(data).includes(value));
    switch(value) {
      case "time":
        return data?.time;
      case "unit":
        return String.Capitalise(data?.unit);
      case "trigger":
        return String.CapitalizeWords(data?.trigger);
      default:
        return "";
    }
  }

  // Handle trigger field
  const handleTrigger = (e, communicationId) => {
    let data = {
      communicationId: communicationId,
      trigger: e
    };
    let selectedContentData = ArrayList.isNotEmpty(contentData) && contentData?.find(content => content.communicationId == communicationId);

    // Update trigger
    getContentValues && getContentValues(data, selectedContentData);
  };

  // Handle unit field
  const handleUnit = (e, communicationId) => {
    let data = {
      communicationId: communicationId,
      unit: e
    };
    let selectedContentData = ArrayList.isArray(contentData) && contentData?.find(content => content.communicationId == communicationId);

    getContentValues && getContentValues(data, selectedContentData);
  };

  // Handle time field
  const handleTime = (e, communicationId) => {
    let data = {
      communicationId: communicationId,
      time: parseInt(e.target.value)
    };
    let selectedContentData = ArrayList.isArray(contentData) && contentData?.find(content => content.communicationId == communicationId);

    e.target.value && getContentValues && getContentValues(data, selectedContentData);
  };  

  const handleSort = (a, b) => {
    if(a.unit) {
      return a?.unit?.position - b.unit.position;
    }
  };
  
  return (
    <>
      {communicationList && communicationList.length > 0 ? (
        communicationList.sort((a, b) => handleSort(a, b)).map((data) => (
          <div
            className="contentCard d-flex container-fluid align-items-center"
          >
            <div className="d-flex align-items-center contentCard-img-section border rounded w-100 mb-2 justify-content-between">
              <div className="d-flex align-items-center gap-1 mobile-avatar-view ml-2 content-title-text">
                <div
                  className="contentCard-img px-2"
                >
                  <img src={messageIcon} className="contentCard-img-img" />
                </div>
                {(data.subject || data.title || data.email) && (
                  <div className="content-title-text contentCard-data">
                    {data.subject && (
                      <span
                        className="fw-bold text-color-secondary fw-600 contentCard-data-subject"
                      >
                        {data.subject}
                      </span>
                    )}
                  </div>
                )}
              </div>

              <div className="d-flex align-items-center">
                <NumberField 
                  id={data.communicationId}
                  placeHolder={"Time"} 
                  className="mx-2" 
                  maxLength={2}
                  onChange={(e) => handleTime(e, data.communicationId, data)} 
                  isDisabled={isCadenceFieldsDisabled}
                  defaultValue={Number.isNumber(data?.unit?.time) ?  data?.unit?.time : ArrayList.isArray(addedCommunicationIds) && !addedCommunicationIds.includes(data.communicationId) ?  getDetail("time", data.communicationId) : ""}
                />

                <SelectDropdown
                  key={"unit"}
                  buttonLabel={String.CapitalizeWords(data?.unit?.unit) || getDetail("unit", data.communicationId) || "Select"}
                  hideChevron 
                  dropdownLinks={unitOptions}
                  handleChange={(e) => handleUnit(e, data.communicationId,data)}
                  size={"sm"}  
                  disabled={isCadenceFieldsDisabled}
                  center
                  hideCaret={isCadenceFieldsDisabled}
                />
                
                <SelectDropdown 
                  key={"trigger"}
                  hideChevron 
                  buttonLabel={String.CapitalizeWords(data?.unit?.trigger) || getDetail("trigger", data.communicationId) || "Select"}
                  className="mx-2"
                  size={"sm"}  
                  dropdownLinks={ data?.unit?.position == 1 ?  FirstContentTriggerOptions : triggerOptions }
                  handleChange={(e) => handleTrigger(e, data.communicationId, data)}
                  disabled={isCadenceFieldsDisabled}
                  hideCaret={isCadenceFieldsDisabled}
                />

                {/* <img style={{width: "12px", height: '7px'}} src={DownArrow} /> */}
              </div>
            </div>

          </div>
        ))
      ) : (
        ""
      )}
      
      {showAddNewStep && !isAddNewStep && (
        <Button
          className="w-100 bg-white border-success"
          label="Add new step"
          backgroundColor ={"#FFFFFF"}
          labelColor = {"#1AA774"}
          color = {"#1AA774"}
          icon = {PlusIcon}
          onClick = {handleAddNewStep}
        />
      )}

      {showAddNewStep && isAddNewStep && (
        <div
          className="d-flex justify-content-center p-2 contentCard-drop"
          onDragOver={(e)=> contentOnDropEnd && contentOnDropEnd(e)}  
        >
          <span className="text-color-secondary fw-400 contentCard-drop-content">
               Drop content here
          </span>
        </div>
      )}
    </>
  );
};

export default ContentCard;