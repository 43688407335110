import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";
import Spinner from "../Spinner";

//Styles
import "./Style.scss";

class SaveButton extends React.Component {
  render() {
    const { onClick, loading, className, align, isClicked, id, type, label } =
      this.props;

    let spinnerStyle = `width:20px; height: 5px;`;

    return (
      <Button
        id={id}
        onClick={onClick}
        type={type || "submit"}
        className={`m-1 save float-${align} ${className}`}
        
        disabled={isClicked}>
        {label ? (
          <span className="label">{label}</span>
        ) : (
          <span className="label">
            {loading ? <Spinner spinnerStyle={spinnerStyle} size={10} />  : "Save"}
          </span>
        )}
      </Button>
    );
  }
}

SaveButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default SaveButton;
