import React from "react";

//Styles
import "./Style.scss";

const SelectedTags = ({ list }) => {
  return (
    <>
      <div className="d-flex overflow-hidden flex-wrap gap-1">
        {list && list.length > 0
          ? list.map((tag, i) => (
            <div className= "selected-tags" >           
              <span
                id={tag}
                className="tag"
                onClick={(e) => {
                  handleTags(e.target, i);
                }}>
                {tag}
              </span>
            </div>
          ))
          : ""}
      </div>
    </>
  );
};

export default SelectedTags;
