import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import Label from "../Label";
import eye from "../../assets/icons/eye.svg";
import eyeSlash from "../../assets/icons/eye-slash.svg";

// Style
import "./Style.scss";

let propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onKeyDown: PropTypes.func,
  onClick: PropTypes.func,
};

let defaultProps = {
  placeholder: "Enter Password",
  name: "password"
};

class Password extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false,
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.setState((prevState) => ({ showPassword: !prevState.showPassword }));
  }

  validate(value) {
    const { label, placeholder, required } = this.props;

    let errorMessage;
    const inputLabel = label || placeholder;

    if (!value && required) {
      errorMessage = inputLabel ? `${inputLabel} is required` : "Required";
    }
    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, setFieldValue, values } }) {
    const {
      id,
      name,
      label,
      placeholder,
      error,
      onKeyDown,
      onClick,
      required,
      strongPassword,
      onInputChange,
      autoComplete
    } = this.props;

    let errorMessage = touched[name] && errors[name] ? errors[name] : null;
    if (error) {
      errorMessage = error;
    }
    const inputId = id || name;

    return (
      <FormGroup
        style={{ position: "relative" }}
        className={(!!errorMessage && "is-invalid") || ""}>
        {label && (
          <Label id={inputId} required={required}>
            {label}
          </Label>
        )}

        <Input
          autoComplete={autoComplete}
          id={inputId}
          {...field}
          type={this.state.showPassword ? "text" : "password"}
          placeholder={placeholder}
          className="password"
          invalid={!!errorMessage}
          onChange={(e) => {
            setFieldValue(name, e.target.value);
            if (onInputChange) {
              values[name] = e.target.value;
              onInputChange({ setFieldValue, values });
            }
          }}
          onKeyDown={onKeyDown}
          onClick={onClick}
          maxLength="25"
        />
        {!errorMessage && (
          <img
            src={this.state.showPassword ? eye : eyeSlash}
            alt="eye icon"
            className="eye-icon"
            onClick={this.handleChange}
          />
        )}
        {errorMessage && (
          <FormFeedback
            id={inputId + "Error"}
            style={{
              position: "relative",
              marginTop: 1,
              fontSize: 10,
              marginLeft: 3,
            }}
            className={[`${strongPassword && "strong-password"}`].join(" ")}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    const { name } = this.props;
    return (
      <Field
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}
Password.defaultProps = defaultProps;
Password.propTypes = propTypes;
export default Password;
