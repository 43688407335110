import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, FormFeedback } from "reactstrap";
import Label from "../Label";
import NumberFormat from "react-number-format";

class Phone extends React.Component {
  // Validate the phone field
  validate(value) {
    // Phone Props
    const { label, placeholder, required, error } = this.props;
    // defining the error message
    let errorMessage;
    // defining the input label
    const inputLabel = label || placeholder;
    // Assigning the error value to errorMessageLabel
    const errorMessageLabel = error;

    // Assigning the error message to display when field is empty.
    if ((!value || !value.trim()) && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    // To validate the phone number
    if (value && value.replace(/[^0-9]/g, "").length < 10) {
      errorMessage = "Invalid phone number";
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors } }) {
    // phone field props
    const {
      name,
      id,
      label,
      placeholder,
      error,
      onKeyDown,
      required,
      outline,
      disabled,
      className,
      phoneStyle
    } = this.props;

    // defining the error message
    let errorMessage = touched[name] && errors[name] ? errors[name] : null;
    // assigning the error value to errorMessage if it exists
    if (error) {
      errorMessage = error;
    }
    // defining the input Id
    const inputId = id || name;

    // declaring the field style values
    let fieldStyle;
    if (outline) {
      fieldStyle = {
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        backgroundColor: "transparent",
        borderBottom: "1px solid #CCCCCC",
        borderRadius: "0",
      };
    } else {
      fieldStyle = {
        background: "#fff",
        borderRadius: "5px",
        height: "40px",
      };
    }

    let fieldValue = field.value;

    return (
      <FormGroup style={{ position: "relative" }} className={className || ""}>
        {label && (
          <Label id={inputId} required={required} className="fw-700" style = {phoneStyle}>
            {label}
          </Label>
        )}
        {disabled ? (
          <div className={`outline-field-style px-1 py-2 ${!fieldValue && "text-secondary"}`}>
            {fieldValue ? fieldValue : <span className="invisible">{(placeholder || `Enter ${label}`)}</span>}
          </div>
        ) : (
          <NumberFormat
            format="(###) ###-####"
            mask="_"
            defaultValue={field.value}
            value={field.value}
            id={inputId}
            {...field}
            placeholder={placeholder || label}
            className={`form-control ${errorMessage ? "is-invalid" : ""}`}
            style={fieldStyle}
            onKeyDown={onKeyDown}
            disabled={disabled}
          />
        )}

        {/* error message display */}   
        {errorMessage && (
          <FormFeedback style={{ position: "absolute", marginTop: 1 }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    // phone props
    const { name } = this.props;

    return (
      <Field
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

Phone.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  onKeyDown: PropTypes.func,
};

export default Phone;
