import React, { useEffect, useState } from "react";
import {  useNavigate } from "react-router-dom";


//Components
import PageTitle from "../../components/PageTitle";
import ContactCard from "../contact/components/ContactCard";
import Breadcrumb from "../../components/Breadcrumb";
import NoRecordsFound from "../../components/NoRecordsFound";
import DetailCard from "./components/DetailCard";
import AssignToContact from "./components/AssignContact";
import ConfimationModal from "../../components/ConfirmationModal";
import SectionTitle from "./components/SectionTitle";
import Spinner from "../../components/Spinner";
import Form from "../../components/Form";
import Date from "../../components/Date";
import Text from "../../components/Text";
import Toast from "../../components/Toast";
import DeleteModal from "../../components/ConfirmationModal";

// Assets
import rightArrowIcon from "../../assets/icons/leftArrow.png";
import assignContactImg from "../../assets/icons/assign-contact.svg";

// Constants
import { SubscriptionStatus } from "../../constants/SubscriptionStatus";
import { Content } from "../../constants/Campaign";

// API
import campaignAPI from "../../api/Campaign";
import ContactAPI from "../../api/ContactAPI";
import ContentAPI from "../../api/Content";

// Lib
import * as DateTime from "../../lib/DateTime";
import ArrayList from "../../lib/ArrayList";

const campaignDetails = () => {
  const [campaignDetailObject, setCampaignDetailObject] = useState();
  const [contactLists, setContactlist] = useState("");
  const [assignContactStatus] = useState(false);
  const [campaignCommunicationList, setCampaignCommunicationList] = useState(
    []
  );
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedContact, setDeletedContact] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  //render assign contact page based on viewPage state
  const [viewPage, setViewPageStatus] = useState(false);

  const [assignToCampaignModalOpen, setAssignToCampaignModalOpen] =
    useState(false);

  const [communicationTags , setCommunicationTags] = useState([]);
  const [contactTagsList , setContactTags] = useState([]);
  const [disabled, setDisabled] = useState(false);
  //delete modal function
  const [modalState, setModalState] = useState(false);

  // Campaign id
  const isNewUser = window.location.pathname.split("/")[2] == "new";
  const programId = !isNewUser && window.location.pathname.split("/")[2];
  
  // Navigate
  const navigate = useNavigate();


  useEffect(() => {
    if (programId) {
      setIsLoading(true);
      getCampaignDetails();
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    if (campaignDetailObject) {
      getContactList();
    }
    getCommunicationList();
    getTagValues();
  }, [campaignDetailObject]);

  // Get the Campaign Details
  const getCommunicationList = async () => {
    try {
      //get communication ids list from campaign details

      let contentList = campaignDetailObject && campaignDetailObject.contentList;

      let campaignContentIds = ArrayList.isNotEmpty(contentList) ? contentList.map((data)=>{
        return data.contentId;
      }) : [];

      //create new communication content list array
      let communicationContentList = new Array();
      //get communication list
      await ContentAPI.getContentList((response) => {
        //get response
        let communicationList = response;
        //validate communication list
        if (ArrayList.isNotEmpty(campaignContentIds) &&  ArrayList.isNotEmpty(communicationList)) {
          //loop the communciation list
          for (let i = 0; i < communicationList.length; i++) {
            if (communicationList[i].status != Content.DELETED) {
              //validate campaign Id exist or not
              if (ArrayList.isElementExist(campaignContentIds , communicationList[i].contentId )) {
                //push the campaign
                let result = contentList.find(({ contentId }) => contentId == communicationList[i].contentId);
                communicationList[i].unit = { 
                  time: result?.time,
                  position: result?.position,
                  unit: result?.unit,
                  trigger: result?.trigger 
                };
                communicationContentList.push(communicationList[i]);
              }

              
            }
          }
          //set the campaing
          setCampaignCommunicationList(communicationContentList);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  //delete campaign 
  const handleDelete =(programId) => {
    campaignAPI.deleteProgram(programId,(res) => {
      if (programId == res?.campaignId) {
        if (res?.deleted == true) {
          Toast.success("Campaign Deleted Successfully");
          navigate("/campaign");
        } else{
          Toast.error(res?.message);
        }
      }
      toggle();
    });
  };

  //Toggle function for delete modal
  const toggle = () => {
    setModalState(!modalState);
  };
  
  const getTagValues = () => {
  // Communication Tag Value
    let communicationTags = [];
    let contactTagsList = [];

    if ( campaignDetailObject && ArrayList.isNotEmpty(campaignDetailObject.campaign.contentTags)) {
      campaignDetailObject.campaign.contentTags.forEach((communicationTag) => {
        communicationTags.push(communicationTag);
      });
      if ( campaignDetailObject && ArrayList.isNotEmpty(campaignDetailObject.campaign.contactTags)) {
        campaignDetailObject.campaign.contactTags.forEach((contactTags) => {
          contactTagsList.push(contactTags);
        });
      }
      setContactTags(contactTagsList);

      setCommunicationTags(communicationTags);
    }
  };

  // Contact Tag Value
  let contactTags = [];
  if (
    campaignDetailObject &&
    campaignDetailObject.contactTags &&
    campaignDetailObject.contactTags.length > 0
  ) {
    campaignDetailObject.contactTags.forEach((contactTag) => {
      contactTags.push({
        value: contactTag,
        label: contactTag,
        name: contactTag,
      });
    });
  }

  // Get Campaign List
  const getCampaignDetails = async () => {
    await campaignAPI.getProgramsList(programId, "", (res) => {
      if (res) {
        setCampaignDetailObject(res);
      }
    });
  };

  // Get the Contact list
  const getContactList = async () => {
    try {
      let contactList = new Array();
      //get contact list

      await ContactAPI.getContactList((response) => {
        //validate contact list
        if (response && Array.isArray(response) && response.length > 0) {
          //validate campaign detail object
          if (
            campaignDetailObject &&
            campaignDetailObject.contactList &&
            campaignDetailObject.contactList.length > 0
          ) {
            //loop the contact list
            for (let i = 0; i < response.length; i++) {
              //validate contact exist or not in campain
              if (
                campaignDetailObject.contactList.indexOf(response[i]?.contact?.contactId) >
                -1
              ) {
                contactList.push(response[i]);
              }
            }
            setIsLoading(false);
          }
          //set contact list
          setContactlist(contactList);
          setIsLoading(false);
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const SubscriptionData = {
    subscription: SubscriptionStatus.SUBSCRIBED,
  };

  // Assign Contact Function
  const assignContact = async () => {
    //Removing duplicates
    let selectedIds = [...new Set(checkedValue)];
    SubscriptionData.contactIds = selectedIds;

    campaignAPI.assignContact(programId, SubscriptionData, async (res) => {
      if (selectedIds?.length === Object.keys(res).length) {
        setAssignedContactIds(Object.keys(res));
        getCampaignDetails();
        setViewPageStatus(false);
      }
    });
  };

  // Delete the Contact
  const deleteContact = () => {
    try {
      setDisabled(true);
      if (
        selectedContact &&
        selectedContact.contactId &&
        programId
      ) {

        const body = {
          contactIds: [selectedContact.contactId],
          subscription: SubscriptionStatus.UNSUBSCRIBED,
        };
        campaignAPI.assignContact( programId, body, (res, status) => {

          if(status && status == 200) {
            Toast.success("Contact Unassigned Successfully");
            getCampaignDetails();
            openDeleteConfirmationModal();
            setDisabled(false);
          }else if (Object.keys(res).includes("code") ||  Object.keys(res).includes("applicationErrorCode")) {
            Toast.error(res?.message);
            openDeleteConfirmationModal();
            setDisabled(false);
          }
          
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Delete Confirmation Modal
  const openDeleteConfirmationModal = (data) => {
    if (data) {
      setDeletedContact(data);
    }
    setOpenConfirmationModal(!openConfirmationModal);
  };

  // Assign Contact Modal
  const AssingContactModalOpen = (isContactAssigned) => {
    setAssignToCampaignModalOpen(!assignToCampaignModalOpen);
    if (isContactAssigned) {
      getCampaignDetails();
    }
  };

  //Breadcrumb List
  const breadcrumbArray = [
    { label: "Campaigns" ,link :"/campaign" },
    {label : campaignDetailObject?.campaign?.name , link : " " }
  ];

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={breadcrumbArray} />

      {/* Confirmation Modal */}
      <ConfimationModal
        // history={props.history}
        isOpen={openConfirmationModal}
        toggle={openDeleteConfirmationModal}
        title="Unassign contact"
        primaryButtonText="Unassign contact"
        secondaryButtonText="Cancel"
        description="Would you like to unassign this contact permanently?"
        onClickNo={openDeleteConfirmationModal}
        onClickYes={deleteContact}
        disabled={disabled}
      />

      {/* Assign to Contact */}
      <AssignToContact
        isOpen={assignToCampaignModalOpen}
        toggle={AssingContactModalOpen}
        programId={programId}
      />
      {/* Campaign delete model  */}
      <DeleteModal
        isOpen={modalState}
        toggle={toggle}
        title="Delete Campaign"
        primaryButtonText="Yes"
        secondaryButtonText="No"
        description={`${contactLists.length == 0 ? `Are you sure you would like to delete this campaign?`
          : `This campaign is assigned to ${contactLists?.length} contacts.  Are you sure you would like to delete this campaign?` }`}
        onClickNo={toggle}
        onClickYes={() => handleDelete(campaignDetailObject?.campaign?.campaignId)}
      />

      {/* Page Header */}
      <PageTitle
        icon={rightArrowIcon}
        label={campaignDetailObject?.campaign.name}
        link={`/campaign`}
        deleteButton
        deleteButtonHandler={toggle}
        buttonLabel={"Delete Campaign"}
      />

      <div className="row g-0">
        <div className="col-lg-4 border px-1">
          
          {/* Page title */}
          <div>
            {viewPage ? (
              <PageTitle
                label="Select Contact"
                buttonLabel="Assign Contact"
                buttonHandler={assignContact}
                disabled={checkedValue?.length < 1 ? true : false}
              />
            ) : !assignContactStatus ? (
              <PageTitle
                label="About Campaign"
                buttonLabel="Edit campaign"
                targetUrl={`/campaign/edit/${programId}`}
                editIcon
              />
            ) : (
              ""
            )}
          </div>

          {!viewPage && !assignContactStatus && (
            <>
              {/* Description Section */}
              <div className="mx-1">
                <p className="text-secondary">
                  {campaignDetailObject?.campaign?.description}
                </p>
              </div>

              <Form  
                enableReinitialize={true}
                initialValues={{
                  startDate: campaignDetailObject && DateTime.USFormat(campaignDetailObject?.campaign?.startDate),
                  communicationTags: communicationTags,
                  contactTags : contactTagsList,
                  communicationTagType: campaignDetailObject?.campaign.contentTagType,
                  contactTagType: campaignDetailObject?.campaign?.contactTagType,
                }} >

                {/* Communication Tags */}
                <Text
                  label="Communication Tags"
                  name="communicationTags"
                  disabled
                />

                {/* Communication Tags */}
                <Text
                  label="Contact Tags"
                  name="contactTags"
                  disabled
                />

                {/* Contact Tag Type */}
                <Text
                  label="Communication TagType"
                  name="communicationTagType"
                  disabled
                />

                {/* Contact Tag Type */}
                <Text
                  label="Contact TagType"
                  name="contactTagType"
                  disabled
                />

                {/* State Date */}
                <Date
                  className ="date-picker"
                  label="Start Date"
                  name="startDate"
                  placeholder="MM-DD-YYYY"
                  format="MM-dd-yyyy"
                  disabled
                />

      

              </Form>

              {/* Assigned Contact section */}
              <SectionTitle
                title="Assigned Contacts"
                description="Assign contact"
                icon={assignContactImg}
                onClick={AssingContactModalOpen}
              />
            </>
          )}

          {/* Assign contact card */}
          {!viewPage && !assignContactStatus ? (
            <div className="campaignDetail">
              <div>
                <div>
                  {isLoading && contactLists.length > 0 ? (
                    <Spinner
                      spinnerStyle={{ position: "relative", marginLeft: "45%" }}
                    />
                  ) : contactLists && contactLists.length > 0 ? (
                    contactLists.map((contactData) => (
                      // Contact Card
                      <ContactCard
                        contact={contactData?.contact}
                        firstName={contactData?.contact?.firstName}
                        openDeleteConfirmationModal={
                          openDeleteConfirmationModal
                        }
                        lastName={contactData?.contact?.lastName}
                        email={contactData?.contact?.email}
                        cardIcon
                      />
                    ))
                  ) : (
                    contactLists.length == 0 && (
                      <NoRecordsFound
                        message="No contacts assigned yet"
                        hideCard
                      />
                    )
                  )}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
        {/* Details Card */}
        <div className="col-lg-8 border bg-white-smoke">
          <DetailCard
            data={campaignDetailObject?.campaign}
            communicationList={campaignCommunicationList}
            isCadenceFieldsDisabled={true}
          />
        </div>
      </div>
    </>
  );
};

export default campaignDetails;
