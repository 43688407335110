export const APP_URL = process.env.REACT_APP_URL;

export const APP_PORT = process.env.REACT_APP_PORT;

export const API_URL = process.env.REACT_APP_API_URL;

export const API_KEY = process.env.REACT_APP_API_KEY;

export const MICROSOFT_CLIENT_ID = process.env.REACT_APP_MICROSOFT_CLIENT_ID;

export const MICROSOFT_REDIRCT_URL = process.env.REACT_APP_MICROSOFT_REDIRCT_URL;
