import React from "react";
import LabelComponent from "../../../components/Label";

const SectionTitle = (props) => {
  const { description, icon, style, title, onClick } = props;

  return (
    <div className="mx-1">
      <LabelComponent
        className="mx-0 fw-800 text-color-secondary">
        {title}
      </LabelComponent>

      <LabelComponent
        className={" sectionTitle-Label d-flex justify-content-end"}>
        <span onClick={() => onClick && onClick()}>
          <img
            src={icon}
            alt="assign"
            style={style ? style : { height: "12px", width: "12px"}}
          />
        </span>

        <div className="p-1 link" onClick={() => onClick && onClick()}>
          {description}
        </div>
      </LabelComponent>
    </div>
  );
};

export default SectionTitle;
