import React, { useState, useEffect } from "react";

// Components
import Form from "../../components/Form";
import ContentForm from "../content/components/ContentForm";

// API
import API from "../../api/Content";
import Tag from "../../api/TagAPI";

const EditContent = ({
  contentDetail,
  setShowContentView,
  setViewContent,
  getDetails,
  setEditContent,
  setContentDetail,
  setIsOpen,
  setViewSubject,
}) => {
  const [fileName, setFileName] = useState(
    contentDetail?.messageContent ? contentDetail?.subject : null
  );
  const [title, setTitle] = useState();
  const [content, setContent] = useState(contentDetail?.messageContent);
  const [isSaveButtonEnable, setIsSaveButtonEnable] = useState(true);
  const [tagList, setTagList] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    getTag();
  }, []);

  const getTag = () => {
    Tag.get((tags) => {
      if (tags && tags.length > 0) {
        setTagList(tags);
      }
    });
  };

  // Update the Content
  const updateContent = (contentData) => {
    try {
      let data = {};
      setIsDisabled(true);
      //validate data exsit or not
      if (contentData) {
        data.description = contentData.description;
        data.subject = title ?? contentData.subject;
        data.messageContent = content ?? contentData.content;
        data.contentFormat = "HTML";

        //get communication Ids from content details
        const communication_id = contentDetail?.contentId;

        //validate communication Id exist or not
        if (communication_id) {
          API.updateCommunication(communication_id, data, (res) => {
            setContentDetail(res);
            setIsSaveButtonEnable(false);
            getDetails();
            setViewSubject(res.subject);
            setEditContent(false);
            setIsOpen(true);
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Handle the File Read
  let fileReader;
  const handleFileRead = () => {
    const content = fileReader.result;
    setContent(content);
    setShowContentView(true);
    setViewContent(content);
  };

  // Handle the File Onchange
  const fieldOnChange = () => {
    setIsSaveButtonEnable(false);
  };

  // Tags
  let tags = [];
  if (contentDetail && contentDetail.tags && contentDetail.tags.length > 0) {
    contentDetail.tags.forEach((tag) => {
      tags.push({ value: tag, label: tag, name: tag });
    });
  }

  // Handle the Title
  const handleTitle = (e) => {
    let value = e?.target?.value;
    setTitle(value);
    fieldOnChange();
  };

  // Handle the File Choose
  const handleFileChosen = (e) => {
    fieldOnChange();
    e.preventDefault();
    let files = e.target && e.target.files[0];
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(files);
    setFileName(files.name);
    e.target.value = null;
  };

  // Handle Remove
  const handleFileRemove = () => {
    setContent("");
    setFileName("");
    setViewContent("");
    fieldOnChange();
  };

  // To handle text area content
  const handleEditor = (e) => {
    if (e) {
      setContent(e);
      setViewContent(e);
      setIsSaveButtonEnable(false);
    } else {
      setContent("");
      setViewContent("");
    }
  };

  return (
    <>
      <Form
        initialValues={{
          description: contentDetail && contentDetail.description,
          subject: contentDetail && contentDetail.subject,
          content: content,
          tags: tags,
          contentFormat: "HTML",
        }}
        onSubmit={(values) => {
          setIsSaveButtonEnable(true);
          updateContent(values);
        }}>
        {/* Content Form */}
        <ContentForm
          handleTitle={handleTitle}
          fieldOnChange={fieldOnChange}
          handleFileRemove={handleFileRemove}
          pageTitleLabel={"Edit content"}
          content={content}
          fileName={fileName}
          handleFileChosen={handleFileChosen}
          showButton={true}
          enablePublishButton={isSaveButtonEnable}
          buttonLabel={"Save"}
          handleEditor={handleEditor}
          tagList={tagList}
          setEditContent={setEditContent}
          setIsOpen={setIsOpen}
          isEditPage={true}
          isTagRequired
          label="Update Content"
          buttonDisable={isDisabled}
        />
      </Form>
    </>
  );
};

export default EditContent;
