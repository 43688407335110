import { Field } from "formik";
import PropTypes from "prop-types";
import React from "react";
import NumberFormat from "react-number-format";
import { FormFeedback, FormGroup } from "reactstrap";
import Label from "../Label";

//Styles
import "./Style.scss";

// Helper

class Zipcode extends React.Component {
  validate(value) {
    const { label, placeholder, required } = this.props;
    let errorMessage;
    let inputLabel = label || placeholder;

    if ((!value || !value.trim()) && required) {
      errorMessage = inputLabel ? `${inputLabel} is required` : "Required";
    }
    if (value && value.replace(/[^0-9]/g, "").length < 5) {
      errorMessage = "Invalid  Zip Code";
    }
    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, setFieldValue } }) {
    const {
      name,
      id,
      label,
      placeholder,
      error,
      required,
      onKeyDown,
      outline,
      fontBolded,
      labelStyle,
      maxLength,
      onChange,
      disabled,
      height,
      borderBottomColor
    } = this.props;

    const inputId = id || name;


    let errorMessage = touched[name] && errors[name] ? errors[name] : null;
    if (error) {
      errorMessage = error;
    }

    const setInputValue = (e) => {
      const { value } = e.target;
      this.setState({
        inputValue: value,
      });
    };

    let fieldStyle;
    if (outline) {
      fieldStyle = {
        borderTop: "none",
        borderRight: "none",
        borderLeft: "none",
        backgroundColor: "transparent",
        borderBottom: `1px solid ${ borderBottomColor ? borderBottomColor : "#02235c"}`,
        borderRadius: "0",
      };
    } else {
      fieldStyle = {
        background: "##FFF",
        border: "1px solid #CCCCCC",
        height: height ? height : "37px",
      };
    }

    let fieldValue = field.value;

    return (
      <FormGroup
        style={{ position: "relative" }}
        className={`${errorMessage ? "is-invalid" : ""}`}
      >
        {label && (
          <Label 
            className={`${fontBolded ? "fw-bold" : ""}`}
            id={inputId}
            required={required}
            style={labelStyle}
          >
            {label}
          </Label>
        )}
        {disabled ? (
          <div className={`outline-field-style px-1 py-2 ${!fieldValue && "text-secondary"}`}>
            {fieldValue ? fieldValue : <span className="invisible">{(placeholder || `Enter ${label}`)}</span>}
          </div>        
        ) : (
          <NumberFormat
            maxLength={maxLength && maxLength ? maxLength : "05"}
            defaultValue={field.value}
            decimalSeparator={false}
            allowNegative={false}
            value={field.value}
            id={id || name}
            name={name || id}
            {...field}
            placeholder={placeholder || label}
            className={`form-control ${errorMessage ? "is-invalid" : ""}`}
            style={fieldStyle}
            onKeyDown={onKeyDown}
            onKeyUp={(e) => {
              setInputValue(e);
              onChange && onChange(e);
              setFieldValue(name, e.target.value);
            }}
            disabled={disabled}
          />
        )}

        {errorMessage && (
          <FormFeedback style={{ position: "absolute", marginTop: 1 }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    const { name, id } = this.props;

    return (
      <Field
        id={id || name}
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

Zipcode.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  locale: PropTypes.string
};

export default Zipcode;
