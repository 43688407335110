import React from 'react';

// Components
import ContentCard from './ContentCard';

const ContentList = ({ 
  getContentValues, 
  communicationList, 
  handleAddNewStep, 
  isAddNewStep, 
  showAddNewStep, 
  contentOnDropEnd, 
  campaignId, 
  contentData,
  addedCommunicationIds,
  isCadenceFieldsDisabled
}) => {
  return (
    <>
      <div className="row gx-0 mt-3 mx-5">
        <div className="col-lg-2"></div>
        <div className="col-lg-2">
          {/* <Dropdown
            hideChevron
            size={"sm"} 
            fontSize={"10px"}
            imgWidth={"11px"}
            imgHeight={"7px"}
          >
            <div
              className={"d-flex flex-column justify-content-between align-items-center px-2"}>
              <span className="d-flex align-items-center gap-2">
                <FontAwesomeIcon icon={faArrowRight}/> closure date
              </span>

              <span className="d-flex align-items-center justify-content-between gap-2">
                <FontAwesomeIcon icon={faCalendar}/> 01/22/2020
              </span>
            </div>
          </Dropdown> */}
        </div>

        {/* ContentCard */}
        <div className="col-lg-8">
          <ContentCard 
            getContentValues={getContentValues} 
            campaignId={campaignId} 
            showAddNewStep = {showAddNewStep} 
            isAddNewStep = {isAddNewStep} 
            handleAddNewStep = {handleAddNewStep} 
            communicationList={communicationList} 
            contentOnDropEnd= {contentOnDropEnd} 
            contentData={contentData} 
            addedCommunicationIds={addedCommunicationIds}
            isCadenceFieldsDisabled={isCadenceFieldsDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default ContentList;