import React from "react";
import SVG from "react-inlinesvg";

import chevronDownIcon from "./icon-chevron-down.svg";
import chevronUpIcon from "./icon-chevron-up.svg";

import infoTextIcon from "./icon-info.svg";
import moreIconVertical from "./icon-more-vertical.svg";
import editIconAlt from "./icon-edit-alt.svg";
import chevronRightIcon from "./icon-chevron-right.svg";
import contactIcon from "./icon-contact.svg";
import campaignIcon from "./icon-campaign.svg";
import contentIcon from "./icon-content.svg";
import overviewIcon from "./icon-overview.svg";
import engagementIcon from "../icons/icon-engagement.svg";
import assignCampaignIcon from "../icons/icon-assign-campaign.svg";
import trashIcon from "../icons/icon-trash.svg";
import pencilIcon from "../icons/icon-pencil.svg";
import menuIcon from "./icon-menu.svg";
import crossIcon from "./icon-cross.svg";
import plus from "./icon-plus.svg";
import vector from "./icon-vector.svg";
import vectorFileUpload from "./icon-vector-file-upload.svg";
import eye from "./icon-eye.svg";
import xcross from "./icon-xcross.svg";
import setting from "./icon-setting.svg";
import searchIcon from "../img/search.png";
import overview from "./icon-over-view.svg";
import engagement from "./icon-engagement.svg";
import contactCampaign from "./campaign.svg";
import documentIcon from "./document.svg";

import facebookIcon from "../img/facebook.png";
import instagramIcon from "../img/instagram.png";
import linkedinIcon from "../img/linkedin.png";
import youtubeIcon from "../img/youtube.png";
import twitterIcon from "../img/twitter.png";
import defaultAvatar from "../img/default-avatar.png";
import arrowRightWhite from "../img/arrowRightWhite.png";
import arrowRightBlue from "../img/arrowRightBlue.png";
import minusIcon from "../icons/minus-icon.svg";
import contentExistIcon from "../icons/content-exist-icon.svg";
import addSuccessIcon from "../icons/add-success-icon.svg";
import delete_red from "../icons/delete_red.svg";
import delete_icon from "../icons/delete_icon.svg";
import campaign_red from "../icons/campaign_red.svg";
import campaign_icon from "../icons/campaign-icon.svg";
import leftArrowIcon from "../icons/leftArrowIcon.svg";

const ChevronDown = () => <SVG src={chevronDownIcon} alt="chevron down" />;
const ChevronUp = () => <SVG src={chevronUpIcon} alt="chevron up" />;
const SearchIcon = () => (
  <SVG
    src={searchIcon}
    alt="search"
    style={{ height: "14px", width: "14px" }}
  />
);
const InlineInfoIcon = () => <SVG src={infoTextIcon} />;
const MoreIconVertical = () => <SVG src={moreIconVertical} alt="more" />;
const EditIconAlt = () => <SVG src={editIconAlt} />;
const ChevronRight = () => (
  <SVG src={chevronRightIcon} width="15px" alt="chevron right" />
);

const ContactIcon = () => <SVG src={contactIcon} alt="contact" />;
const CampaignIcon = () => <SVG src={campaignIcon} alt="campaign" />;
const ContentIcon = () => <SVG src={contentIcon} alt="content" />;
const OverviewIcon = () => <SVG src={overviewIcon} alt="overview" />;
const EngagementIcon = () => <SVG src={engagementIcon} alt="engagementIcon" />;
const AssignCampaignIcon = () => (
  <SVG src={assignCampaignIcon} alt="assignCampaign" />
);
const TrashIcon = () => <SVG src={trashIcon} alt="trashIcon" />;
const PencilIcon = () => <SVG src={pencilIcon} alt="pencil" />;
const CrossIcon = () => <SVG src={crossIcon} alt="pencil" />;
const HamburgerMenu = () => <SVG src={menuIcon} alt="pencil" />;
const Plus = () => <SVG src={plus} alt="plus" />;
const Vector = () => <SVG src={vector} alt="vector" />;
const VectorFileUpload = () => (
  <SVG src={vectorFileUpload} alt="vectorFileUpload" />
);
const Eye = () => <SVG src={eye} alt="eye" />;
const Xcross = () => <SVG src={xcross} alt="xcross" />;
const settingIcon = () => <SVG src={setting} alt="setting" />;
const MinusIcon = () => <SVG src={minusIcon} alt="minusIcon" />;
const ContentExistIcon = () => (
  <SVG src={contentExistIcon} alt="contentExistIcon" />
);
const AddSucessIcon = () => <SVG src={addSuccessIcon} alt="contentExistIcon" />;
const overView = () => <SVG src={overview} alt="overview" />;
const Engagement = () => <SVG src={engagement} alt="engagement" />;
const ContactCampaign = () => <SVG src={contactCampaign} alt="engagement" />;
const CampaignIcon1 = () => <SVG src={campaign_icon} alt="campaign" />;
const CampaignIconRed = () => <SVG src={campaign_red} alt="campaign_red" />;
const DeleteIcon = () => <SVG src={delete_icon} alt="delete" />;
const DeleteIconRed = () => <SVG src={delete_red} alt="delete_red" />;
const DocumentIcon = () => <SVG src={documentIcon} alt="documentIcon" />;
const LeftArrowIcon = () => <SVG src={leftArrowIcon} alt="leftArrowIcon" />;

export {
  ChevronDown,
  CrossIcon,
  HamburgerMenu,
  ChevronUp,
  ContactIcon,
  PencilIcon,
  CampaignIcon,
  ContentIcon,
  OverviewIcon,
  EngagementIcon,
  AssignCampaignIcon,
  TrashIcon,
  SearchIcon,
  InlineInfoIcon,
  MoreIconVertical,
  EditIconAlt,
  defaultAvatar,
  ChevronRight,
  facebookIcon,
  instagramIcon,
  linkedinIcon,
  youtubeIcon,
  twitterIcon,
  Plus,
  Vector,
  VectorFileUpload,
  Eye,
  Xcross,
  settingIcon,
  arrowRightWhite,
  arrowRightBlue,
  MinusIcon,
  ContentExistIcon,
  AddSucessIcon,
  overView,
  Engagement,
  ContactCampaign,
  CampaignIcon1,
  CampaignIconRed,
  DeleteIcon,
  DeleteIconRed,
  DocumentIcon,
  LeftArrowIcon,
};
