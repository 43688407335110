import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import Form from "../../components/Form";
import Breadcrumb from "../../components/Breadcrumb";
import SaveButton from "../../components/SaveButton";
import UserForm from "./components/UserForm";
import PageTitle from "../../components/PageTitle";
import Toast from "../../components/Toast";

// API
import API from "../../api/User";

// Assets
import leftArrowIcon from "../../assets/icons/leftArrow.png";

// Constants
import { UserStatus, PermissinonLevelOptions, UserStatusOptions } from "../../constants/User";


const NewUser = () => {
  const [enableButton ,setEnableButton ] = useState(false); 
  const navigate = useNavigate();

  // Breadcrumb list
  const breadcrumbList = [ 
    { label: "Settings", link: "/setting" },
    {
      label: "User Management",
      link: "/setting",
      activeTab: "User Management"
    },
    {
      label: "New User",
      link: "",
    },
  ];
   
  // Forming data
  const toArray = value => {
    let data = {};
    data.firstName = value.firstName;
    data.lastName = value.lastName;
    data.email = value.email;
    data.password = value.password;
    data.status = UserStatus.Active;
    data.permissionLevel = value.permissionLevel.value;
    return data;
  };

  // Function to create new user
  const addNewUser = (data) => {
    API.addUser(toArray(data), (res) => {
      //Checking if the response has email, if has redirecting to setting page
      if(Object.keys(res).includes("email")) {
        navigate("/setting", {state: {activeTab: "User Management"}});
        Toast.success("User Added Successfully");      
      } else if(Object.keys(res).includes("applicationErrorCode")) {
        Toast.error(res?.message);
        setEnableButton(false);
      }
    });
  };
 
  //function to  redirect userlist page
  const LinkHandler = () => {
    navigate("/setting", {state: {activeTab: "User Management"}});
  };

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={breadcrumbList} />

      {/* Form */}

      <Form
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          password: "",
          status: UserStatusOptions.find(data => data.value == UserStatus.Active),
          permissionLevel: PermissinonLevelOptions.find(data => data.label == PermissinonLevelOptions[1].label)
        }}
        onSubmit={value => {
          setEnableButton(true);
          addNewUser(value);
        }}
      >
        <div className="g-0 row">
          <div className="col-lg-4">
            {/* Tittle */}
            <div className="d-flex justify-content-between align-items-center">
              <PageTitle 
                detail={"New User"}
                label={"Add New User"}
                icon={leftArrowIcon}
                link={LinkHandler()} 
                activeTab="User Management" 
              />

              <SaveButton className="login-btn h-50 border-0" isClicked={enableButton} />
            </div>

            <div className="card border container-fluid pt-3 overflow-auto px-2">
              <UserForm password={true} addPage={true} editableField={true} />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default NewUser;