import React from 'react';
//components
import Message from '../../components/Message';
import PageTitle from '../../components/PageTitle';
import SubmitButton from '../../components/SubmitButton';
///asset
import CheckMark from "../../assets/icons/checkMarkIcon.svg";
import { useNavigate, useLocation } from 'react-router-dom';


const SignUpDone = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const buttonHandle= () => {
    navigate("/", {state: {email: location?.state?.email }});
  };

  return (
    <>
      <div className='container-fluid d-flex flex-column justify-content-center align-items-center vh-100' >
        <div className="basic-login-form mb-5">  
          <div>
            <div className="text-color-secondary fw-800">
              <PageTitle  label="You Siged Up with Bowtie!" />
            </div>
          </div>
          <div className="text-color-secondary mt-2" >
            <Message label="Successfully signed up with Bowtie" />
          </div>
          <div className="signUpDone">
            <SubmitButton className="w-100" label="Done"  icon={CheckMark}  onClick={() => buttonHandle()}/>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUpDone;