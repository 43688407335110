import React from "react";
import { Button } from "reactstrap";
import CrossIcon from "../../assets/img/cross-circle.png";
import PropTypes from "prop-types";

//Styles
import "./Style.scss";

const propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  bottom : PropTypes.string,
  noRadius: PropTypes.bool,
};
class DiscardButton extends React.Component {
  render(){
  // Discard Button props
    const { label, onClick, bottom, noRadius, className  } = this.props;
  
    // Defining Discard Button style
    let style = {
      background: "#DEDEDE",
      outline: 0,
      border: "none",
      color: "#1b3165",
      height: "50px",
      width: "100%",
    };

    // applying style if bottom props is exists
    if (bottom) {
      style.position = "absolute";
      style.bottom = 0;
    }

    // applying style if noRadius props is exists
    if (noRadius) {
      style.borderRadius = 0;
    }
    return (
      <>
        <Button onClick={() => onClick()} className={className ? className : "discard-button-focus"} style={style}>
          <div className={`d-flex flex-column align-items-center`}>
            <img
              className="img"
              src={CrossIcon}
              alt="Cancel"
            />
            <span>{label ? label : "Discard"}</span>
          </div>
        </Button>
      </>
    );
  }
}

DiscardButton.propTypes = propTypes;

export default DiscardButton;
