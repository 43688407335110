import React from "react";
import PropTypes from "prop-types";
import AddButton from "../../components/AddButton";
import DeleteButton from "../DeleteButton";
import Paragraph from "../Paragraph";
import LinkTo from "../LinkTo";
import FileSelectButton from "../FileSelectButton";

// Styles
import "./Style.scss";

class PageTitle extends React.Component {
  render() {
    const {
      label,
      id,
      buttonHandler,
      buttonLabel,
      settings,
      targetUrl,
      className,
      deleteButton,
      deleteButtonHandler,
      isMobile,
      buttonStyle,
      color,
      buttonIcon,
      showButton,
      icon,
      description,
      deleteIcon,
      tags,
      link,
      buttonWidth,
      textTruncate,
      iconHandler,
      isDisabled,
      editIcon,
      uploadButton,
      handleFileChosen,
    } = this.props;
    return (
      <div className={`${className || ""} pt-3`}>
        <div
          className={`${
            settings
              ? "d-flex"
              : "d-flex  justify-content-between align-items-center"
          }`}>
          {/* Title */}
          <div
            id={id || label}
            className={`page-title mb-3 fw-bold ${textTruncate}`}
            style={{
              fontWeight: "600",
            }}>
            <LinkTo
              className="text-underline"
              url={link ? link : "#"}
              onClick={() => iconHandler && iconHandler()}
              icon={
                icon && (
                  <img
                    src={icon}
                    width={"14px"}
                    height={"14px"}
                    className="mb-1"
                  />
                )
              }
            />

            <span className={`${icon ? "mx-2" : ""} text-nowrap fs-6`}>
              {label}
            </span>
          </div>

          {/* Contact Links */}
          <div className="d-flex flex-wrap mb-3">
           
            {/* Add button */}
            {(buttonHandler || targetUrl || showButton) && (
              <div className="d-none d-sm-block d-md-block d-lg-block">
                <AddButton
                  label={!isMobile ? buttonLabel : ""}
                  className={`float-lg-right page-title-button border border-primary btn btn-${
                    color ? color : ""
                  } h6-5-important fw-bold text-nowrap`}
                  onClick={buttonHandler}
                  buttonWidth={buttonWidth}
                  targetUrl={targetUrl}
                  isDisabled={isDisabled}
                  buttonStyle={buttonStyle}
                  buttonIcon={buttonIcon}
                  tags={tags}
                  editIcon={editIcon}
                />
              </div>
            )}

            {/* Mobile view */}
            {(buttonHandler || targetUrl || showButton) && (
              <div className="d-block d-sm-none d-md-none d-lg-none">
                <AddButton
                  className={`float-lg-right page-title-button border border-primary btn btn-${
                    color ? color : ""
                  } h6-5-important text-nowrap`}
                  onClick={buttonHandler}
                  buttonWidth={buttonWidth}
                  targetUrl={targetUrl}
                  isDisabled={isDisabled}
                  buttonStyle={buttonStyle}
                  buttonIcon={buttonIcon}
                  tags={tags}
                  editIcon={editIcon}
                />
              </div>
            )}

            {uploadButton && (
              <div className="d-none d-sm-block d-md-block d-lg-block">
                <FileSelectButton onChange={handleFileChosen} accept=".csv" label="Import" />
              </div>
            )}

            {/* Mobile view */}
            {uploadButton && (
              <div className="d-block d-sm-none d-md-none d-lg-none">
                <FileSelectButton onChange={handleFileChosen} accept=".csv" className="file-upload" />
              </div>
            )}

            {/* delete button */}
            {deleteButton && (
              <div className="d-none d-sm-block d-md-block d-lg-block">
                <DeleteButton
                  label={buttonLabel ? buttonLabel : "Delete"}
                  onClick={deleteButtonHandler}
                  deleteIcon={deleteIcon ? deleteIcon : ""}
                />
              </div>
            )}

            {/* mobile view */}
            {deleteButton && (
              <div className="d-block d-sm-none d-md-none d-lg-none">
                <DeleteButton
                  onClick={deleteButtonHandler}
                  deleteIcon={deleteIcon ? deleteIcon : ""}
                />
              </div>
            )}
          </div>
        </div>
        <Paragraph text={description} />
      </div>
    );
  }
}

PageTitle.propTypes = {
  label: PropTypes.string,
};

export default PageTitle;
