import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faPlus } from "@fortawesome/free-solid-svg-icons";
import LinkTo from "../LinkTo";

// Style
import "./Style.scss";

class AddButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleMouseHover = this.handleMouseHover.bind(this);
    this.state = {
      isHovering: false,
    };
  }
  // Defined a function in state when hovers on the button
  handleMouseHover() {
    this.setState(this.toggleHoverState);
  }
  // To set value for the function when hovers on the button
  toggleHoverState(state) {
    return {
      isHovering: !state.isHovering,
    };
  }

  render() {
    const {
      label,
      onClick,
      className,
      targetUrl,
      isDisabled,
      buttonStyle,
      buttonIcon,
      tags,
      buttonWidth,
      editIcon,
    } = this.props;

    let style;
    if (this.state.isHovering) {
      style = {
        backgroundColor: "#fff",
        color: "#0078D4",
        border: "1px solid #0078D4",
        borderRadius: "2px",
        boxShadow: "none",
        cursor: isDisabled ? "not-allowed" : "pointer",
        width: buttonWidth ? buttonWidth : "",
      };
    } else if (tags) {
      style = {
        backgroundColor: "#fff",
        color: "#0078D4",
        border: `1px solid "#0078D4"`,
        borderRadius: "2px",
        boxShadow: "none",
        cursor: isDisabled ? "not-allowed" : "pointer",
      };
    } else {
      style = {
        backgroundColor: "#0078D4",
        color: "#FFFFFF",
        border: "1px solid #0078D4",
        borderRadius: "2px",
        boxShadow: "none",
        cursor: isDisabled ? "not-allowed" : "pointer",
        width: buttonWidth ? buttonWidth : "",
      };
    }

    return targetUrl ? (
      <LinkTo
        id={label}
        url={targetUrl}
        className={`${className}h6-5-important`}
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
        style={style}
        onClick={onClick}
        icon={editIcon ? (
          <FontAwesomeIcon icon={faPencil} />
        ) : (
          <FontAwesomeIcon icon={faPlus} />
        )}
        label={label}
        spanClassName="fw-bold icon"
      />
    ) : (
      <Button
        id={label}
        type={`${buttonStyle ? buttonStyle.type : "button"}`}
        className={` add-button ${className} ${isDisabled ? "disabled" : ""}`}
        onMouseEnter={this.handleMouseHover}
        onMouseLeave={this.handleMouseHover}
        style={buttonStyle ? buttonStyle : style}
        disabled={isDisabled}
        onClick={onClick}
        buttonIcon={
          buttonIcon ? buttonIcon : <FontAwesomeIcon id={label} icon={faPlus} />
        }>
        <span>{label}</span>
      </Button>
    );
  }
}

AddButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  targetUrl: PropTypes.string,
};

export default AddButton;
