import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Avatar from "../../components/Avatar";
import ChevronRight from "../../assets/icons/rightArrow.png";
import DeleteLink from "../../components/DeleteLink";

//Components
import LinkTo from "../../components/LinkTo";

const Sidebar = (props) => {
  const {
    onChange,
    list,
    deleteCampaign,
    checkedValue,
    disabled,
    selectAllCampaign,
    isDeleteModalOpen,
    handleDeleteFunction,
    isSelectAllChecked,
  } = props;
  const [checked, setChecked] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="bg-white mt-3">
      {/* Action */}
      {/* showing the section only when the campaign data is there */}
      {list && list.length > 0 ? (
        <section className="d-flex justify-content-between align-items-center mt-2 p-2 ">
          <div className="d-flex mx-3 mt-1">
            <div style={{marginTop:"1px"}}>
              <input
                type="checkbox"
                checked={isSelectAllChecked}
                onClick={(e) => selectAllCampaign(e)}
              />
            </div>
            <label className="text-dark-gray px-2 ">Select all</label>
          </div>
          <DeleteLink
            label="Delete campaign"
            primaryButtonText="Yes"
            secondaryButtonText="No"
            description={"Are you sure you would like to delete this campaign?"}
            onClick={deleteCampaign}
            disabled = {disabled}
            handleFunction={handleDeleteFunction}
            isDeleteModalOpen={isDeleteModalOpen}
            checkedValue={checkedValue}
          />
        </section>
      ) : (
        ""
      )}

      {/* List */}
      <section className="mx-3">
        {list &&
          list.length > 0 &&
          list.map((data) => (
            <div
              className="p-2 d-flex justify-content-between align-items-center bg-white-smoke contact-card cursor-pointer row"
              onClick={() =>
                navigate(`/campaign/${data.campaign.campaignId}`)
              }>
              <div className="d-flex align-items-center col-10">
                <div className="mt-2">
                  <>
                    <label
                      className="checkbox"
                      key={data.campaign.campaignId}
                      onClick={(event) => {
                        event.stopPropagation();
                      }}>
                      <input
                        id={data.campaign.campaignId}
                        name={data?.name}
                        type="checkbox"
                        value={data.name}
                        onChange={() => onChange(data?.campaign?.campaignId)}
                        checked={checkedValue.includes(
                          data.campaign.campaignId
                        )}
                        onClick={(e, i) => setChecked(i, !checked)}
                      />
                    </label>
                  </>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mx-2">
                    <Avatar
                      firstName={data.campaign.name}
                      bgColor="#1B3165"
                      className="mr-2"
                    />
                  </div>
                  <div className="flex-wrap align-items-center">
                    {data.campaign.name && (
                      <div className="d-flex flex-column align-items-star">
                        {data.campaign.name && (
                          <div className="campaign-list-page fw-700 mx-1 my-2">
                            {data.campaign.name}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-2 text-end">
                <LinkTo
                  url={`/campaign/${data.campaign.campaignId}`}  
                  src={ChevronRight}
                  alt="arrow-right"
                  iconClassName="sidebar-img"
                />
              </div>
            </div>
          ))}
      </section>
    </div>
  );
};

export default Sidebar;
