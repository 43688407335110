import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

// Components
import Form from "../../components/Form";
import Breadcrumb from "../../components/Breadcrumb";
import ContentPreview from "./components/ContentPreview";
import LabelComponent from "../../components/Label";
import ContentForm from "./components/ContentForm";
import LinkTo from "../../components/LinkTo";

// Assets
import { CrossIcon } from "../../assets/icons";

// Common
import { isLoggedIn } from "../../common/Helper";

// API
import API from "../../api/Content";
import Tag from "../../api/TagAPI";
import ContentStatus from "../../helpers/Content";

const AddContent = () => {
  const navigate = useNavigate();

  //states
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [fileName, setFileName] = useState("");
  const [tagList, setTagList] = useState([]);
  const [buttonDisable, setButtonDisable] = useState(false);

  //communication id
  const isNewCommunication = window.location.pathname.split("/")[2] == "add";

  useEffect(() => {
    //user verification
    isLoggedIn();
    getTag();
  }, []);

  const getTag = () => {
    Tag.get((tags) => {
      if (tags && tags.length > 0) {
        setTagList(tags);
      }
    });
  };

  // handle the Title
  const handleTitle = (e) => {
    let contentTitle = e.target.value;
    setTitle(contentTitle);
  };

  // Handle File Read
  let fileReader;
  const handleFileRead = () => {
    const content = fileReader.result;
    setContent(content);
  };

  // Handle File Choose
  const handleFileChosen = (e) => {
    e.preventDefault();
    let files = e.target && e.target.files[0];
    fileReader = new FileReader();
    fileReader.onloadend = handleFileRead;
    fileReader.readAsText(files);
    setFileName(files.name);
    e.target.value = null;
  };

  //Forming data
  const toArray = (value) => {
    let data = {};
    let tags = [];

    //forming tags array
    if (value.tags && value.tags.length > 0) {
      value.tags.forEach((data) => {
        tags.push(data.value);
      });
    }

    data.name = value.title;
    data.description = value.description;
    data.subject = value.subject;
    data.messageContent = content;
    data.contentFormat = value.contentFormat;
    data.status = ContentStatus.PUBLISHED;

    if (isNewCommunication) {
      data.tags = tags;
    }
    return data;
  };

  // Create Content
  const newCommunication = (data) => {
    API.addCommunication(toArray(data), (res) => {
      console.log("data--------->",res);
      if (res) {
        navigate("/contents");
        setButtonDisable(false);
      }
    });
  };

  // handle remove
  const handleFileRemove = () => {
    setContent("");
    setFileName("");
  };

  // Breadcrumb list
  const bradcrumbList = [
    { label: "Contents", link: "/contents" },
    {
      label: "Add new content",
      link: "",
    },
  ];

  // To handle text area content
  const handleEditor = (e) => {
    if (e) {
      setContent(e);
    } else {
      setContent("");
    }
  };

  return (
    <>
      {/* Breadcrumb */}
      <Breadcrumb list={bradcrumbList} className="mx-1" />

      <Form
        initialValues={{
          name: "",
          tags: "",
          contentFormat: "HTML",
          description: "",
          content: content || "",
          subject: "",
          effectiveOn: "",
          effectiveUntil: "",
        }}
        onSubmit={(value) => {
          if (value.tags.length > 0) {
            newCommunication(value);
            setButtonDisable(true);
          }
        }}>
        {/* Header */}
        <div className="row bg-white-smoke">
          <div className="p-4 addContent">
            {/* Title */}
            <LabelComponent className="d-flex flex-row gap-2">
              <LinkTo url="/contents" icon={<CrossIcon />} className="mt-1" />
              <span className="fs-5">Add new content</span>
            </LabelComponent>
          </div>
        </div>

        <div className="row">
          {/* Content Form */}
          <div className="col-lg-4 border">
            <div className="vh-80">
              <ContentForm
                fileName={fileName}
                handleTitle={handleTitle}
                handleFileChosen={handleFileChosen}
                handleFileRemove={handleFileRemove}
                content={content}
                pageTitleLabel={"About content"}
                isTagRequired={true}
                buttonLabel={"Publish Content"}
                handleEditor={handleEditor}
                tagList={tagList}
                buttonDisable={buttonDisable}
                label="Add Content"
              />
            </div>
          </div>

          {/* Content Preview section */}
          <div className="col-lg-8">
            <ContentPreview
              fileName={fileName}
              subject={title}
              content={content}
              showEmptyContent
            />
          </div>
        </div>
      </Form>
    </>
  );
};

function mapStateToProps(state) {
  return state.table;
}

export default connect(mapStateToProps)(AddContent);
