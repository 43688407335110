import { COOKIE } from "../constants/Cookie";
import * as Cookie from "../lib/Cookie";

// check if the user is logged in
export function isLoggedIn() {
  const accessToken = Cookie.get(COOKIE.ACCESS_TOKEN);

  if (!accessToken) {
    return window.location.replace("/");
  }
}

// Get param value from  the url search bar

export function getParamsByName(e) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  return params.get(e);
}

// to check the password is valid
export function getpassword(password) {
  const strongRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
  let data;
  if (password.match(strongRegex)) {
    data = true;
  } else {
    data = false;
  }
  return data;
}

/**
 * Strong Password validation
 *
 * @param password
 * @returns error
 */
export function validateStrongPassword(password) {
  let error;

  if (password && password.length < 8) {
    error = "Must be at least 8 characters";
  } else if (
    password &&
    password.length >= 8 &&
    getpassword(password) !== true
  ) {
    error = "Please choose stronger password";
  }

  return error;
}
