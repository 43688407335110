import React from "react";
import { Button } from "reactstrap";
import PlusIcon from "../../assets/img/plus_icon.png";
import PropTypes from "prop-types";

// Style
import "./Style.scss";


const propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  bottom: PropTypes.string,
  fullWidth: PropTypes.string,
  className: PropTypes.string,
  noRadius: PropTypes.string,
  isChecked:  PropTypes.string

};

// Default Props
const defaultProps = {
  type: "assignButton",
};


class AssignButton extends React.Component {
 

  render() {
    const {
      label,
      onClick,
      icon,
      bottom,
      fullWidth,
      className,
      noRadius,
      isChecked,
    } = this.props;

    //Style
    let style = {
      background: "#0078D4",
      outline: 0,
      border: "none",
      color: "#FFF",
      height: "50px",
    };
    
    if (bottom) {
      style.position = "absolute";
      style.bottom = 0;
      style.width = "100%";
    }
    
    if (fullWidth) {
      style.width = "100%";
    }
    
    if (noRadius) {
      style.borderRadius = 0;
    }
 
    return (
      <>
        <Button
          onClick={() => onClick()}
          className = "assign-button-focus"
          style={style}
          disabled={isChecked ? false : true}>
          <div className={`d-flex flex-column align-items-center ${className}`}>
            <img
              className="plus-icon"
              src={icon ? icon : PlusIcon}
              alt="Cancel"
            />
            <span>{label ? label : "Assign"}</span>
          </div>
        </Button>
      </>
    );
  }
}

AssignButton.propTypes = propTypes;
AssignButton.defaultProps = defaultProps;

export default AssignButton;
