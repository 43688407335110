import React, { useEffect, useState } from "react";
//assets
import contactHoverIcon from "../../assets/icons/settingContactIcon.svg";
import campaignHoverIcon from "../../assets/icons/settingCampaignIcon.svg";
import contentHoverIcon from "../../assets/icons/settingContentIcon.svg";

//componets
import RadioButton from "../../components/RadioButton";

// API
import setting from "../../api/Setting";

const PrivacySettings = () => {
  //state
  const [list, setList] = useState("");
  const [isContactPublic, setIsContactPublic] = useState();
  const [isCommunicationPublic, setIsCommunicationPublic] = useState();
  const [IsContentPublic, setIsContentPublic] = useState();

  //get setiing
  const getSettingList = () => {
    try {
      setting.getSetting((res) => {
        if (res) {
          setList(res);
          changeCheckValue(res);
        }
      });
    } catch (error) {
      console.log("err-", error);
    }
  };

  // set customer settings
  const saveSettingList = (data) => {
    try {
      setting.saveSetting("", data, () => {});
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSettingList();
  }, []);

  //change button
  const changeCheckValue = (data) => {
    if (data.contactOwnership == "PUBLIC") {
      setIsContactPublic(true);
    } else {
      setIsContactPublic(false);
    }
    if (data.communicationOwnership == "PUBLIC") {
      setIsCommunicationPublic(true);
    } else {
      setIsCommunicationPublic(false);
    }
    if (data.programOwnership == "PUBLIC") {
      setIsContentPublic(true);
    } else {
      setIsContentPublic(false);
    }
  };

  //change radio button
  const handleChange = (e) => {
    let selectedName = e.target.name;
    let data = {};

    if (selectedName == "contact") {
      data.contactOwnership = e.target.value;
    } else {
      data.contactOwnership = list.contactOwnership;
    }
    if (selectedName == "communication") {
      data.communicationOwnership = e.target.value;
    } else {
      data.communicationOwnership = list.communicationOwnership;
    }
    if (selectedName == "content") {
      data.programOwnership = e.target.value;
    } else {
      data.programOwnership = list.programOwnership;
    }

    changeCheckValue(data);
    saveSettingList(data);
    setList(data);
  };

  return (
    <div>
      <div className="row mt-3">
        <div className="col-4">
          <img src={contactHoverIcon} className="setting-icons pr-2" />
          <label>Contacts</label>
        </div>
        <div className="col-4">
          <RadioButton
            name="contact"
            handleChange={handleChange}
            value="PUBLIC"
            checked={isContactPublic}
          />
        </div>
        <div className="col-4">
          <RadioButton
            name="contact"
            value="PRIVATE"
            handleChange={handleChange}
            checked={!isContactPublic}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-4">
          <img src={campaignHoverIcon} className="setting-icons pr-2" />
          <label>Campaigns</label>
        </div>
        <div className="col-4">
          <RadioButton
            name="communication"
            value="PUBLIC"
            handleChange={handleChange}
            checked={isCommunicationPublic}
          />
        </div>
        <div className="col-4">
          <RadioButton
            name="communication"
            value="PRIVATE"
            handleChange={handleChange}
            checked={!isCommunicationPublic}
          />
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-4">
          <img src={contentHoverIcon} className="setting-icons pr-2" />
          <label>Content</label>
        </div>
        <div className="col-4">
          <RadioButton
            name="content"
            value="PUBLIC"
            handleChange={handleChange}
            checked={IsContentPublic}
          />
        </div>
        <div className="col-4">
          <RadioButton
            name="content"
            value="PRIVATE"
            handleChange={handleChange}
            checked={!IsContentPublic}
          />
        </div>
      </div>
    </div>
  );
};

export default PrivacySettings;
