import React from "react";
import { useNavigate } from "react-router-dom";

//components
import PageTitle from "../../components/PageTitle";
import SubmitButton from "../../components/SubmitButton";

//assets
import CheckMark from "../../assets/icons/checkMarkIcon.svg";

const ResetPasswordDone = () => {
  const navigate = useNavigate();

  const buttonHandle = () => {
    navigate("/");
  };
  return (
    <>
      <div className="container-fluid d-flex flex-column justify-content-center align-items-center vh-100">
        <div className="resetPassword">
          <div>
            <PageTitle
              label="Check Your Inbox!"
              description="An email has been sent to you with a secure link, open the email, and follow the instructions"
              colorHeader="#707B96"
            />
          </div>
          <div className="mt-2">
            <SubmitButton
              className="w-100"
              label="Done"
              icon={CheckMark}
              onClick={() => buttonHandle()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPasswordDone;
